import { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { SnackContext } from '../../../../context/SnackProvider';
import LoadingScreen from '../../../reusable-components/LoadingScreen';
import { Organization, Team } from '../../../../types';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';

const defaultTeam: Team = {
	id: '',
	name: '',
	organizationId: '',
	projectIds: [],
	userIds: [],
};

export default function AddTeamDialog({
	open,
	organizationId,
	organizations,
	setOpen,
	onSave,
}: {
	open: boolean;
	organizationId?: string;
	organizations?: Organization[];
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSave?: () => void;
}) {
	const { addingTeam, addTeam } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [form, setForm] = useState(defaultTeam);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<
		string | undefined
	>();

	useEffect(() => {
		if (organizations && organizations.length > 0) {
			setSelectedOrganizationId(organizations[0].id);
		}
	}, [organizations]);

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (!form.name) return;

		const org = organizationId || selectedOrganizationId;
		if (!org) {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to submit new team!`,
			});
			return;
		}

		try {
			const success = await addTeam({
				id: '',
				name: form.name,
				organizationId: org,
			});
			handleClose();
			setForm(defaultTeam);

			if (success) {
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Successfully submitted new team!`,
				});
				onSave?.();
			} else {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Failed to submit new team!`,
				});
			}
		} catch (err) {
			console.error(err);
			handleClose();
			setForm(defaultTeam);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Create A New Team</DialogTitle>
			{!addingTeam ? (
				<DialogContent>
					{organizations && organizations.length > 0 && (
						<>
							{organizations.length > 1 ? (
								<>
									<InputLabel>Organization</InputLabel>
									<Select
										value={selectedOrganizationId}
										onChange={e =>
											setSelectedOrganizationId(
												organizations.find(org => org.id === e.target.value)?.id
											)
										}>
										{organizations.map((org, i) => (
											<MenuItem key={i} value={org.id}>
												{org.name}
											</MenuItem>
										))}
									</Select>
								</>
							) : (
								<>
									<InputLabel>Organization</InputLabel>
									<br />
									{organizations[0].name}
								</>
							)}
						</>
					)}
					<TextField
						variant="standard"
						label="Team Name"
						name="name"
						value={form.name}
						onChange={onChange}
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Creating team..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingTeam ? (
					<>
						<Button onClick={handleClose} sx={{ textTransform: 'none' }}>
							Cancel
						</Button>
						<Button onClick={handleSubmit} sx={{ textTransform: 'none' }}>
							Create Team
						</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
