import { useState } from 'react';
import { Row } from 'react-table';
import { isRowComplete } from '../utils';
import { ProjectData } from '../../../../../../types';
import { BodyCell, BodyRow } from '../../../../../styled-components/styledReactTable';
import { useNavigate } from 'react-router-dom';
import { SortBy } from '..';

export default function StatusTableRow({
	row,
	sortBy,
}: {
	row: Row<ProjectData>;
	sortBy: SortBy | null;
}) {
	const [editing, setEditing] = useState(false);
	const navigate = useNavigate();

	return (
		<BodyRow
			{...row.getRowProps()}
			key={row.original.id}
			completed={isRowComplete(row)}
			onClick={() => !editing && navigate(`/projects/${row.original.id}`)}>
			{row.cells.map((cell, i) => {
				return (
					<BodyCell
						{...cell.getCellProps()}
						style={{ width: cell.column.width }}
						key={`${i} ${cell.value}`}>
						{cell.render('Cell', {
							rowEditing: editing,
							setRowEditing: setEditing,
							sortBy,
						})}
					</BodyCell>
				);
			})}
		</BodyRow>
	);
}
