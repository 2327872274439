import { Box, IconButton } from '@mui/material';
import Layout from '../../components/reusable-components/Layout';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
	ListButtonsContainer,
	ListContainer,
	ListContent,
	ListTitle,
	ListTitleContainer,
} from '../../components/reusable-components/List';
import TemplateListItem from '../../components/screen-components/TemplateList/TemplateListItem';
import { FormTemplate } from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { TemplatesContext } from '../../context/TemplatesProvider';
import Searchbar from '../../components/screen-components/TemplateList/Searchbar';
import { addTemplate } from '../../firebase';
import InputDialog from '../../components/reusable-components/InputDialog';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';

const TemplatesList = () => {
	const {
		clearQuery,
		loadingForms,
		nextPage,
		onChangeQuery,
		page,
		prevPage,
		refreshData,
		searchQuery,
		totalPages,
		templates,
	} = useContext(TemplatesContext);
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();

	const [addTemplateDialog, setAddTemplateDialog] = useState(false);
	const [addingTemplate, setAddingTemplate] = useState(false);

	const handleAddTemplate = () => setAddTemplateDialog(true);

	const onAddTemplate = async (name: string) => {
		setAddTemplateDialog(false);
		setAddingTemplate(true);
		const result = await addTemplate({ name });
		const { templateId } = result.data as any;
		setAddingTemplate(false);
		refreshData();
		navigate(`/templates/${templateId}`);
	};

	return (
		<Layout LeftNavComponent={<h1>Templates</h1>}>
			<Box
				paddingTop={4}
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center">
				<Searchbar
					query={searchQuery}
					handleInputChange={onChangeQuery}
					clearQuery={clearQuery}
					onAdd={handleAddTemplate}
					name={'Template'}
					showButton={user?.isAdmin}
					style={{ maxWidth: 650, width: '100%', marginBottom: 20 }}
				/>
				<ListButtonsContainer>
					<IconButton
						onClick={prevPage}
						disabled={loadingForms || addingTemplate || page === 1}>
						<ChevronLeft style={{ fontSize: 28 }} color="primary" />
					</IconButton>
					<TextContainer>
						{page} / {totalPages}
					</TextContainer>
					<IconButton
						onClick={nextPage}
						disabled={loadingForms || addingTemplate || page === totalPages}>
						<ChevronRight style={{ fontSize: 28 }} color="primary" />
					</IconButton>
				</ListButtonsContainer>
				<ListContainer>
					<ListTitleContainer>
						<ListTitle>Templates</ListTitle>
					</ListTitleContainer>
					<ListContent
						list={templates}
						loading={loadingForms || addingTemplate}
						renderItem={(template: FormTemplate) => (
							<TemplateListItem key={template.id} template={template} />
						)}
						emptyMessage="No templates to display..."
						loadingMessage="Loading templates..."
					/>
				</ListContainer>
			</Box>
			<InputDialog
				handleClose={() => setAddTemplateDialog(false)}
				handleSave={onAddTemplate}
				open={addTemplateDialog}
				label="New template name"
				title="Add template"
			/>
		</Layout>
	);
};

const TextContainer = styled.div`
	color: #ffb310;
	font-size: 16px;
	font-weight: 600;
`;

export default TemplatesList;
