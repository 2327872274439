import { Button, IconButton, InputBase } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { CSSProperties, FC, useRef } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

type SearchbarProps = {
	query: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearQuery: () => void;
	style?: CSSProperties;
	onAdd?: () => void;
	name?: string;
	showButton?: boolean;
};

const Searchbar: FC<React.PropsWithChildren<SearchbarProps>> = ({
	query,
	handleInputChange,
	clearQuery,
	style,
	onAdd,
	name,
	showButton,
}) => {
	const inputRef = useRef<HTMLInputElement>();

	return (
		<Container>
			<StyledSearch style={style}>
				<IconButton
					sx={{
						color: '#ffb310',
					}}>
					<SearchIcon />
				</IconButton>
				<StyledInput
					placeholder="Search..."
					onChange={handleInputChange}
					value={query}
					inputRef={inputRef}
				/>
				{query ? (
					<StyledButton
						onClick={() => {
							clearQuery();
							inputRef.current?.focus();
						}}>
						<ClearIcon />
					</StyledButton>
				) : null}
			</StyledSearch>
			{showButton && name && onAdd ? (
				<YellowButton onClick={onAdd}>
					<AddIcon />
					Add {name}
				</YellowButton>
			) : null}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 5px;
	place-content: center;
	align-self: stretch;
`;

const StyledSearch = styled.div`
	/* margin-top: 20px; */
	display: flex;
	gap: 5px;
	width: 95%;
	min-width: 230px;
	border-radius: 50px;
	border: 1px solid #ffb310;
	padding-left: 10px;
	padding-right: 2px;
	height: 50px;
	align-items: center;
	justify-content: space-between;
	background: linear-gradient(
		180deg,
		rgb(16.52, 4.6, 4.6) 0%,
		rgb(10.53, 10.15, 10.15) 0.01%,
		rgb(11.39, 10.99, 10.99) 0.02%,
		rgb(38.71, 36.47, 36.47) 100%
	);
`;

const YellowButton = styled(Button)`
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 50px;
	align-self: right;
	align-items: center;
	height: 46px;
	text-transform: none;

	&:hover {
		background-color: #ffb310;
	}
`;

const StyledInput = styled(InputBase)`
	color: #ffb310;
	flex-grow: 1;
`;

const StyledButton = styled(IconButton)`
	color: #ffb310;
	transition: 0.2s;

	&:hover {
		color: white;
	}
`;

export default Searchbar;
