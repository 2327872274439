import { FormControlLabel, Tab, TableCell, styled } from '@mui/material';

export const LoadingContainer = styled('div')`
	display: flex;
	justify-content: center;
	margin-top: 19px;
`;

export const TeamPageContainer = styled('div')`
	display: flex;
	flex-direction: row;
	height: 92vh;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const MobileLogoContainer = styled('div')`
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
`;

export const MainContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 20px;
	background-color: #111114;
	width: 100%;
`;

export const Container = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 10px;
	align-items: center;
	overflow: hidden;
	width: 100%;

	@media (max-width: 768px) {
		overflow-y: auto;
	}
`;

export const SwitchContainer = styled(FormControlLabel)`
	flex-grow: 0;
	padding-left: 12px;
	color: white;

	.MuiSwitch-track {
		background-color: rgba(255, 255, 255, 0.5);
	}
`;

export const ReportItem = styled('div')<{ selected: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;
	width: 100%;
	height: 40px;
	align-items: center;
	border-top: 1px solid ${props => props.theme.palette.borderColor};
	color: white;
	cursor: pointer;
	background-color: ${props => (props.selected ? '#1e1e23' : '#111114')};
	&:hover {
		background-color: #1e1e23;
	}
`;

export const Scroller = styled('div')`
	overflow-y: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid rgba(89, 89, 89, 0.4);
`;

export const QuestionsScroller = styled('div')`
	overflow-y: auto;
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	border: 1px solid ${props => props.theme.palette.borderColor};
	border-radius: 5px;
	padding: 30px 20px;
	background-color: ${props => props.theme.palette.common.black};
`;

export const ReportRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ProjectRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const OptionsRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

export const QuestionParent = styled('div')`
	color: #666666;
	font-size: 0.8rem;
`;

export const QuestionText = styled('div')`
	color: 'white';
	margin-bottom: 10px;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const OptionText = styled('div')<{ clickable: boolean }>`
	color: #ffb310;
	font-weight: ${props => (props.clickable ? 'bold' : 'normal')};
	text-decoration: ${props => (props.clickable ? 'underline' : 'none')};
	cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`;

export const OptionValue = styled('div')`
	color: white;
`;

export const FormQuestion = styled('div')`
	color: white;
	margin-bottom: 20px;
`;

export const QuestionOption = styled('div')`
	margin-right: 30px;
`;

export const ProjectTableCell = styled(TableCell)`
	white-space: nowrap;
	margin-right: 16px;
	vertical-align: top;
	border-right: 1px solid #e0e0e0;
`;

export const FormTableCell = styled(TableCell)`
	vertical-align: top;
	text-align: center;
`;

export const VerticalDivider = styled('div')(
	({ theme }) => `
	background-color: ${theme.palette.primary.main};
	width: 1px;
	height: 38px;
	margin-right: 30px;
`
);

export const ReportContainer = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 100%;
	height: 81vh;
`;

export const TeamTab = styled(Tab)`
	color: white;
	text-transform: capitalize;
`;

export const XLSXContainer = styled('div')`
	display: flex;
	gap: 20px;
	align-items: center;
	padding-bottom: 20px;
	width: 100%;
`;

export const ReportScrollerWrapper = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 35px 0;
	align-items: center;
	overflow: hidden;
	height: 100%;
	width: 100%;
	background-color: ${props => props.theme.palette.common.black};
	border: 1px solid ${props => props.theme.palette.borderColor};
	border-radius: 5px;

	@media (max-width: 768px) {
		overflow-y: auto;
	}
`;
