import { FC, useContext } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from '../../context/AuthProvider';
import AppText from '../../components/reusable-components/AppText';
import FormikTextField from '../../components/reusable-components/FormikTextField';
import { Button } from '@mui/material';
import AppLink from '../../components/reusable-components/AppLink';
import styled from 'styled-components';

const validationsForm = {
	email: yup.string().email('Enter a valid email').required('Email is required'),
	password: yup
		.string()
		// .min(6, 'Password must contain at least 6 characters')
		.required('Password is required'),
};

type Props = {
	setShowingAddFolder: (showingAddFolder: boolean) => void;
};

const LoginEmailPassword: FC<Props> = ({ setShowingAddFolder }: Props) => {
	const { handleOnLogin, loginError } = useContext(AuthContext);

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validationSchema={yup.object().shape(validationsForm)}
			onSubmit={async values => {
				await handleOnLogin(values);
			}}>
			{({ errors, touched, handleSubmit, isSubmitting }) => (
				<AuthForm onSubmit={handleSubmit}>
					<FormikTextField
						label="Email"
						name="email"
						type="email"
						helperText={touched.email ? errors.email : ''}
						error={touched.email && Boolean(errors.email)}
						autoComplete="username"
					/>

					<FormikTextField
						label="Password"
						name="password"
						type="password"
						helperText={touched.password ? errors.password : ''}
						error={touched.password && Boolean(errors.password)}
						autoComplete="current-password"
					/>

					{loginError && <AppText text={loginError} error />}

					<AuthActions>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={isSubmitting}>
							Login
						</Button>
						<ClickableText onClick={() => setShowingAddFolder(true)}>
							Forgot Password?
						</ClickableText>
						<AppLink to="/register" text="Don't Have an Account?" />
					</AuthActions>
				</AuthForm>
			)}
		</Formik>
	);
};

export default LoginEmailPassword;

const ClickableText = styled.span`
	margin-top: 15px;
	cursor: pointer;
	color: #ffb310;
	&:hover {
		text-decoration: underline;
	}
`;

const AuthActions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	margin-top: 10px;
`;

const AuthForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;

	min-width: 200px;
	overflow: visible;
`;
