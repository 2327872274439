import { FC, useState } from 'react';
import { ReportItem, ReportRow } from './StyledComponents';
import { ArrowDropDownCircle, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import Loading from '../ProjectUtilityFormV2/components/Loading/Loading';
import { ReportData } from '../../../types/reports';
import EditReportNameDialog from './EditReportNameDialog';
import theme from '../../../styles/theme';

type RepostListItemProps = {
	onClick: () => void;
	report: ReportData;
	selectedReport?: ReportData;
};

const ReportListItem: FC<RepostListItemProps> = ({ onClick, report, selectedReport }) => {
	const [dialogOpen, setDialogOpen] = useState(false);

	return (
		<ReportItem
			style={{
				background: `${
					report.id === selectedReport?.id
						? theme.palette.divider
						: theme.palette.common.black
				}`,
			}}
			key={report.id}
			onClick={onClick}
			selected={report.id === selectedReport?.id}>
			<ReportRow>
				<Tooltip title={report.type}>
					<ArrowDropDownCircle
						style={{ fontSize: 16, marginRight: 10 }}
						color="primary"
					/>
				</Tooltip>
				{report.name || report.createdAt?.toDate().toLocaleString()}
			</ReportRow>
			{report.status === 'processing' ? (
				<Box style={{ marginLeft: 'auto' }}>
					<Loading size={20} />
				</Box>
			) : null}
			<IconButton
				onClick={e => {
					e.stopPropagation();
					setDialogOpen(true);
				}}
				style={{ marginLeft: 'auto' }}>
				<Edit color="primary" />
			</IconButton>
			<EditReportNameDialog open={dialogOpen} report={report} setOpen={setDialogOpen} />
		</ReportItem>
	);
};

export default ReportListItem;
