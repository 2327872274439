import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import ForgotPasswordDialog from '../../components/screen-components/Login/ForgotPasswordDialog';
import BackgroundVideo from '../../components/reusable-components/BackgroundVideo';
import logoSrc from '../../assets/circleLogo.png';
import LoadingScreen from '../../components/reusable-components/LoadingScreen';
import { TermsAndPrivacy } from '../../components/screen-components/Login/TermsAndPrivacy';
import theme from '../../styles/theme';
import LoginEmailPassword from './LoginEmailPassword';
import '../../styles/Login.css';
import LoginSocial from './LoginSocial';
import AppText from '../../components/reusable-components/AppText';
import {
	AppButton,
	AppButtonContainer,
	AppDownloads,
	AuthContainerContent,
	AuthContainerContentGrid,
	AuthContainerContentGridContent,
	DownloadToday,
	Nav,
	RealityBackground,
} from './style';
import { DividerLine } from './DividerLine';
import Apple from '../../assets/Apple.png';
import Google from '../../assets/Google.png';
import Footer from './Footer';

export default function Login() {
	const { loadingUser, authLoadingText } = useContext(AuthContext);
	const [showingAddFolder, setShowingAddFolder] = useState(false);

	useEffect(() => {
		document.title = 'Login | Robotic Imaging';
	}, []);

	return (
		<>
			<Nav>
				<a href="https://www.roboticimaging.com/">
					<img
						src={logoSrc}
						alt=""
						style={{
							height: '40px',
							marginRight: '15px',
						}}
					/>
				</a>
			</Nav>
			<BackgroundVideo />
			<ForgotPasswordDialog
				showingDialog={showingAddFolder}
				setShowingDialog={setShowingAddFolder}
			/>
			<AuthContainerContent $isRegister={false}>
				{!loadingUser ? (
					<>
						<RealityBackground>
							<div className="reality">Reality Capture At Scale</div>
							<span style={{ color: theme.palette.primary.main }}>
								<b>Robotic Imaging</b> simplifies the asset documentation experience by
								harnessing the power of AI, OCR and 3D LiDAR Scanning.
							</span>
						</RealityBackground>

						<AuthContainerContentGrid>
							<AppText variant={'h5'} text="Login" />
							<AuthContainerContentGridContent>
								<LoginEmailPassword setShowingAddFolder={setShowingAddFolder} />
								<DividerLine />
								<LoginSocial />
							</AuthContainerContentGridContent>
						</AuthContainerContentGrid>

						<TermsAndPrivacy />

						<AppDownloads>
							<DownloadToday>Download Today</DownloadToday>
							<AppButtonContainer>
								<a
									href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
									id="AppleAppStore"
									target="_blank"
									rel="noreferrer">
									<AppButton src={Apple} alt="Download on Apple App store" />
								</a>

								<a
									href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
									id="GooglePlayStore"
									target="_blank"
									rel="noreferrer">
									<AppButton src={Google} alt="Download on Google Play store" />
								</a>
							</AppButtonContainer>
						</AppDownloads>
					</>
				) : (
					<LoadingScreen message={authLoadingText} />
				)}
			</AuthContainerContent>
			<Footer />
		</>
	);
}
