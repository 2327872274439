import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { ProjectStatus } from '../../types';

import EventBusyIcon from '@mui/icons-material/EventBusy';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import ArchiveIcon from '@mui/icons-material/Archive';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TopicIcon from '@mui/icons-material/Topic';

export const fontSize = '15px';

export const statusColor = (status: ProjectStatus) => {
	switch (status) {
		case 'Proposal Pending':
			return '#ffb310';
		case 'Proposal Sent':
			return 'white';
		case 'To Be Scheduled':
			return '#ED5454';
		case 'On-Site Capture Complete':
			return '#EFE751';
		case 'Registration':
			return '#4c90ef';
		case 'Ready For Modeling':
			return '#f58cff';
		case 'Modeling In Progress':
			return '#4BCCC1';
		case 'QA/QC':
			return '#7c26ff';
		case 'Pending Payment / Delivered':
			return '#ddff00';
		case 'Delivered':
			return '#AFEF7E';
		case 'Pending Payment':
			return '#7c25ad';
		case 'Sample':
			return '#4c4c4c';
		case 'Template':
			return '#1E70FF';
		case 'Archive':
			return 'grey';
		default:
			return '#ffb310';
	}
};

export function StatusIcon({ status }: { status: ProjectStatus }) {
	switch (status) {
		case 'Proposal Pending':
			return <PendingActionsIcon />;
		case 'Proposal Sent':
			return <ForwardToInboxIcon />;
		case 'To Be Scheduled':
			return <EventBusyIcon />;
		case 'On-Site Capture Complete':
			return <CameraAltIcon />;
		case 'Ready For Modeling':
			return <CheckIcon />;
		case 'Registration':
			return <BlurOnIcon />;
		case 'Modeling In Progress':
			return <ViewInArIcon />;
		case 'QA/QC':
			return <YoutubeSearchedForIcon />;
		case 'Pending Payment':
			return <CurrencyExchangeIcon />;
		case 'Pending Payment / Delivered':
			return <PublishedWithChangesIcon />;
		case 'Delivered':
			return <DoneAllIcon />;
		case 'Sample':
			return <LocationCityIcon />;
		case 'Template':
			return <TopicIcon />;
		case 'Archive':
			return <ArchiveIcon />;
		default:
			return <HelpIcon />;
	}
}

export const StatusWrapper = styled.div<{ $projectStatus: ProjectStatus }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transition: 0.2s;

	background-color: ${({ $projectStatus }) => statusColor($projectStatus)};
	color: ${({ $projectStatus }) => ($projectStatus === 'Sample' ? 'white' : 'black')};

	border-radius: 30px;
	div fieldset {
		border-radius: 30px;
	}
	font-size: ${fontSize};

	&:hover,
	:focus-within {
		background-color: ${({ $projectStatus }) => statusColor($projectStatus)};
		filter: brightness(70%);
	}

	div.MuiInputBase-root {
		color: inherit;
	}

	div.MuiSelect-select {
		padding: 8px;
		padding-right: 30px;

		color: inherit;
	}

	svg.MuiSvgIcon-root {
		fill: ${({ $projectStatus }) => ($projectStatus === 'Sample' ? 'white' : 'black')};
	}
`;

export const AddressLink = styled(Link)<{ $color: 'white' | '#ffb310' | 'red' }>`
	text-decoration: none;
	color: ${({ $color }) => $color};
	max-height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	transition: 0.2s;

	&:hover {
		text-decoration: underline;
		color: ${({ $color }) => ($color === 'white' ? '#ffb310' : 'white')};
	}
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;
	max-height: 100%;

	background-color: #0e0e0f;
	border: 2px solid #4e4e4e;
	border-radius: 20px;
`;

export const Table = styled.div`
	width: 100%;
	border-spacing: 0;
	overflow: auto;
`;

const headerFooterColor = 'black';
export const HeaderRow = styled.div`
	display: flex;
	background-color: #17181b;
	border-bottom: 1px solid #323639;
	gap: 10px;
	background-color: ${headerFooterColor};
	width: fit-content;
	max-width: 100%;
	padding: 0 20px;

	overflow: hidden;
	> * {
		flex-shrink: 0;
	}
`;

export const HeaderCell = styled.div`
	border-radius: 0;
	font-size: ${fontSize};
	padding: 10px 0 5px 0;
`;

export const StatusHeaderCellInner = styled.div<{
	isSorted?: boolean;
	canSort?: boolean;
}>`
	color: ${({ isSorted }) => (isSorted ? '#ffb310' : '#f6f6f6')};
	cursor: ${({ canSort }) => (canSort ? 'pointer' : 'text')};

	font-weight: bold;
	display: flex;
	align-items: center;
	height: 40px;
	transition: 0.2s;

	&:hover {
		color: ${({ canSort }) => (canSort ? '#ffb310' : '')};
		text-shadow: ${({ canSort }) => (canSort ? '0 0 2px #ffb310' : '')};
		text-decoration: ${({ canSort }) => (canSort ? 'underline' : '')};
	}
`;

export const BodyRow = styled.div<{ completed?: boolean }>`
	display: flex;
	gap: 10px;

	border: 0;
	border-bottom: 1px solid #323639;
	transition: background-color 0.2s ease-in-out;
	color: #f6f6f6;
	background-color: ${({ completed }) =>
		completed || completed === undefined ? 'transparent' : '#380000'};

	width: 100%;
	min-width: fit-content !important;
	height: 60px;
	padding: 0 20px;
	cursor: pointer;

	&:hover {
		background-color: ${({ completed }) =>
			completed || completed === undefined ? '#0A0A0D' : '#200000'};
	}
`;

export const BodyCell = styled.div`
	display: flex;
	font-size: ${fontSize};
	color: #f6f6f6;
	height: 60px;
	overflow: hidden;
	justify-content: space-between;
	align-items: center;

	& > span {
		max-height: 100%;
		max-width: 100%;
		overflow: auto;
	}
`;

export const BottomRow = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	color: #f6f6f6;
	background-color: ${headerFooterColor};
	line-height: normal;

	@media screen and (max-width: 400px) {
		flex-direction: column;
		align-items: end;
	}
`;

export const Pagination = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-left: auto;
`;

export const PaginationButton = styled.button`
	background: none;
	border: none;
	transition: opacity 0.2s;
	cursor: pointer;
	padding: 0;

	&:hover {
		opacity: 0.7;
	}

	svg {
		color: white;
	}

	&:disabled {
		cursor: default;
		&:hover {
			opacity: 1;
		}
		svg {
			color: #676767;
		}
	}
`;

export const AvailableButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 80px;
	background-color: lightgreen;
	color: darkgreen;
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 15px;
	padding: 5px;
	border-radius: 20px;
	border: 1px solid lightgreen;
`;

export const BusyButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 80px;

	background-color: lightcoral;
	color: darkred;
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 15px;
	padding: 5px;
	border-radius: 20px;
	border: 1px solid coral;
`;

export const Total = styled.strong`
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 0px 5px;
`;

export const TotalTitle = styled.span`
	/* @media screen and (max-width: 1656px) {
		display: block;
	} */
`;

export const ClickableLink = styled.a`
	color: #ffb310;
	text-decoration: none;
	font-weight: 500;

	&:hover {
		text-decoration: underline;
	}
`;

export const TableCellLink = styled(Link)`
	text-decoration: none;
	color: #f6f6f6;
	&:hover {
		color: #ffb310;
		text-decoration: underline;
	}
`;

export const YellowTextField = styled(TextField)<{ $hasLineBreaks?: boolean }>`
	> .MuiInputBase-root {
		color: #ffb310;
		padding: 0px;

		> input {
			cursor: text;
			line-height: normal;
			padding: 10px;

			&::-webkit-calendar-picker-indicator {
				cursor: pointer;
				filter: invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg) brightness(104%)
					contrast(101%);

				&:hover,
				:focus-visible {
					filter: invert(1);
				}
			}
		}

		> textarea {
			padding: ${({ $hasLineBreaks }) => (!$hasLineBreaks ? '10px' : '5px')};
		}

		> fieldset {
			transition: 0.2s;
			border-color: #ffb310;
		}

		&:hover,
		:focus-within {
			> fieldset {
				border-color: white;
			}
		}

		> .MuiSvgIcon-root {
			color: #ffb310;
		}
	}
`;

export const WhiteTextField = styled(TextField)`
	.MuiInput-root {
		color: white;

		&:before {
			border-bottom: 1px solid white;
		}

		&:hover {
			&:after {
				transform: scaleX(1) translateX(0);
			}
		}
	}

	.MuiSelect-icon {
		color: #ffb310;
	}
`;

export const EditableSpan = styled.span`
	display: block;
	flex-grow: 1;
	transition: 0.2s;
	cursor: pointer;
	line-height: normal;

	&:hover,
	&:focus-visible {
		color: #ffb310;
	}
`;
export const YellowButton = styled(Button)`
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;

	&:hover {
		background-color: #ffb310;
	}
`;
