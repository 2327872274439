import { Close, Refresh, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import logoSrc from '../../../../../../assets/circleLogo.png';
import { CSSProperties } from 'styled-components';
import ExpandMore from '@mui/icons-material/ExpandMore';

type SectionHeaderProps = {
	children: React.ReactNode;
	onRefresh?: (query: string) => void;
	onSearch?: (query: string) => void;
	showSearch?: boolean;
	showLogo?: boolean;
	propQuery?: string;
	setPropQuery?: React.Dispatch<React.SetStateAction<string>>;
	headerStyle?: CSSProperties;
	headerComponents?: React.ReactNode;
	showDropdown?: boolean;
	showDropdownOpenByDefault?: boolean;
	dropdownValue?: (value: boolean) => void;
	isSearching?: (value: boolean) => void;
};

const SectionHeader: FC<SectionHeaderProps> = ({
	children,
	onRefresh,
	onSearch,
	showSearch = true,
	showLogo = true,
	propQuery,
	setPropQuery,
	headerStyle,
	headerComponents,
	showDropdown = false,
	showDropdownOpenByDefault = true,
	dropdownValue,
	isSearching,
}) => {
	const [searchVisible, setSearchVisible] = useState(
		typeof propQuery === 'string' ? propQuery : false
	);
	const [searchQuery, setSearchQuery] = useState(
		typeof propQuery === 'string' ? propQuery : ''
	);
	const [isOpen, setIsOpen] = useState(showDropdownOpenByDefault);

	useEffect(() => {
		if (!searchVisible && !setPropQuery) {
			setSearchQuery('');
			onSearch?.('');
		}
	}, [onSearch, searchVisible, setPropQuery]);

	const debounce = useRef<number | null>(null);
	useEffect(() => {
		if (setPropQuery) {
			if (typeof debounce.current === 'number') {
				window.clearTimeout(debounce.current);
			}
			debounce.current = window.setTimeout(() => {
				debounce.current = null;
				setPropQuery(searchQuery);
			}, 500);
		}
	}, [searchQuery, setPropQuery]);

	const inputRef = useRef<HTMLInputElement | undefined>();

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		dropdownValue?.(!isOpen);
	};
	return (
		<Header style={headerStyle}>
			{showLogo && <img src={logoSrc} alt="" style={{ height: 18, marginRight: 10 }} />}
			<Title>{children}</Title>
			{onSearch ? (
				<SearchContainer>
					<SearchInput
						size="small"
						inputRef={inputRef}
						onChange={e => {
							onSearch(e.target.value);
							setSearchQuery(e.target.value);
							isSearching && isSearching(e.target.value.length > 0);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search color="primary" />
								</InputAdornment>
							),
						}}
						sx={{
							color: 'white',
							'& .MuiInputBase-input': {
								color: 'white',
							},
						}}
						style={{ display: searchVisible ? 'flex' : 'none' }}
					/>

					{showSearch && (
						<IconButton
							size="small"
							onClick={() =>
								setSearchVisible(prev => {
									if (!prev) window.setTimeout(() => inputRef.current?.focus(), 100);
									if (prev) setSearchQuery('');
									if (prev) isSearching && isSearching(false);
									return !prev;
								})
							}>
							{searchVisible ? <Close color="primary" /> : <Search color="primary" />}
						</IconButton>
					)}

					{onRefresh ? (
						<IconButton size="small" onClick={() => onRefresh(searchQuery)}>
							<Refresh color="primary" />
						</IconButton>
					) : null}

					{headerComponents}

					{showDropdown && (
						<div>
							<DropdownButton onClick={toggleDropdown}>
								<CaretIcon open={isOpen} />
							</DropdownButton>
						</div>
					)}
				</SearchContainer>
			) : null}
		</Header>
	);
};

export default SectionHeader;

const SearchContainer = styled('div')`
	display: flex;
`;

const SearchInput = styled(TextField)`
	background-color: #1e1e23;
	width: 250px;
	border-radius: 5px;
`;

const Title = styled('div')`
	font-size: 1.2rem;
	line-height: 1.2rem;
	color: white;
	margin-top: 4px;
	font-weight: bold;
	width: 100%;
`;

const Header = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 14px;
	margin: 0;
	background-color: #000000;
	position: relative;
`;

const DropdownButton = styled('button')`
	background: none;
	border: none;
	color: white;
	padding: 10px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	cursor: pointer;

	&:hover {
		background-color: #202225;
	}
	> *:not(:last-child) {
		margin-right: 10px;
	}
`;

type DropdownContentProps = {
	open: boolean;
};

const CaretIcon = styled(ExpandMore)<DropdownContentProps>`
	color: #fff;
	transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
	transition: transform 0.3s;
`;
