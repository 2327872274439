import { useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import StringInput from './StringInput';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { ProjectData } from '../../../../../types';
import { reorderDate } from '../../../../../utils';
import GoogleMapsButton from '../GoogleMapsButton';
import { Box } from '@mui/material';

type SingleLineStringFieldProps = {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	field: 'address' | 'name' | 'buildingSize' | 'lastContacted' | 'storeNumber';
	showMap?: boolean;
};

export default function SingleLineStringField({
	isActive,
	handleChange,
	field,
	showMap,
}: SingleLineStringFieldProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const address = project?.address.replace(/\s+/g, '+');

	return (
		<div className="infoRow">
			<FieldLabel label={fieldLabels[field]} />

			<StringInput
				field={field}
				onBlur={handleChange}
				isActive={Boolean(user?.isAdmin && isActive)}
				inactiveDisplay={getInactiveDisplay(project, field)}
				type={getType(field)}
			/>
			{!!showMap && !!address && (
				<Box sx={{ ml: 'auto', mt: '-8px', mb: '-8px', mr: '-5px' }}>
					<GoogleMapsButton concatAddress={address} />
				</Box>
			)}
		</div>
	);
}

const fieldLabels = {
	address: 'Address',
	name: 'Project ID',
	buildingSize: 'SQFT',
	lastContacted: 'Last Contacted',
	storeNumber: 'Site Number',
};

const getInactiveDisplay = (project: ProjectData | null, field: keyof ProjectData) => {
	switch (field) {
		case 'buildingSize':
			return project?.buildingSize ? project.buildingSize + ' sqft' : '';
		case 'lastContacted':
			return project?.lastContacted ? reorderDate(project.lastContacted) : '';
		default:
			return undefined;
	}
};

const getType = (field: keyof ProjectData) => {
	switch (field) {
		case 'lastContacted':
			return 'date';
		default:
			return undefined;
	}
};
