import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { uploadLeads } from '../../../../../firebase';
import { DuplicatedLead, Lead } from '../../../types';
import { SnackContext } from '../../../../../context/SnackProvider';
import { leadStatuses } from '../../../utils';
import { LeadsContext } from '../../LeadsProvider';
import LoadingScreen from '../../../../../components/reusable-components/LoadingScreen';
import { AuthContext } from '../../../../../context/AuthProvider';
// import { UserObj } from '../../../../../../../types';

const defaultLead: Lead = {
	company: '',
	firstName: '',
	lastName: '',
	contactTitle: '',
	mobileNumber: '',
	hqNumber: '',
	email: '',
	status: 'New',
	owner: '',
	accountManager: { name: '', email: '', id: '' },
	state: '',
};

export default function AddLeadDialog({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { projectRoleOptions } = useContext(AuthContext);
	const { fetchSetLeads } = useContext(LeadsContext);

	const [form, setForm] = useState(defaultLead);
	const [creatingLead, setCreatingLead] = useState(false);

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		setCreatingLead(true);
		const res = await uploadLeads({
			leads: [form],
		});
		setCreatingLead(false);

		const badLeads = (res as any).data.duplicateLeads as DuplicatedLead[];

		if (!badLeads.length) {
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully submitted new lead!`,
			});
			handleClose();
			await fetchSetLeads();
			setForm(defaultLead);
		} else {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Duplicate lead detected. ${badLeads[0].matchedField} matches an existing lead.`,
			});
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Create A New Lead</DialogTitle>

			{!creatingLead ? (
				<DialogContent>
					<TextField
						variant="standard"
						label="Company"
						name="company"
						value={form.company}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="First Name"
						name="firstName"
						value={form.firstName}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Last Name"
						name="lastName"
						value={form.lastName}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Title"
						name="contactTitle"
						value={form.contactTitle}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Mobile Number"
						name="mobileNumber"
						value={form.mobileNumber}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Direct Number"
						name="hqNumber"
						value={form.hqNumber}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Email"
						name="email"
						value={form.email}
						onChange={onChange}
					/>
					<TextField
						variant="standard"
						label="Lead Status"
						name="status"
						value={form.status}
						onChange={onChange}
						select>
						{leadStatuses.map((status, i) => (
							<MenuItem value={status} key={i}>
								{status}
							</MenuItem>
						))}
					</TextField>
					<TextField
						variant="standard"
						label="Account Manager"
						name="accountManager"
						value={form.accountManager.id}
						onChange={e => {
							const selectedId = e.target.value as string;
							if (selectedId) {
								const selectedManager = projectRoleOptions['accountManager'].find(
									userWithData => userWithData.id === selectedId
								);
								if (selectedManager) {
									setForm(prev => ({
										...prev,
										accountManager: {
											email: selectedManager.email,
											name: selectedManager.fullName,
											id: selectedManager.id,
										},
									}));
								}
							} else {
								setForm(prev => ({
									...prev,
									accountManager: { name: '', email: '', id: '' },
								}));
							}
						}}
						select>
						<MenuItem value={''}>None</MenuItem>
						{projectRoleOptions['accountManager'].map(userWithData => (
							<MenuItem value={userWithData.id} key={userWithData.id}>
								{userWithData.fullName}
							</MenuItem>
						))}
					</TextField>
					<TextField
						variant="standard"
						label="State"
						name="state"
						value={form.state}
						onChange={onChange}
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Creating lead..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!creatingLead ? (
					<>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSubmit}>Create Lead</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
