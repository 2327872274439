import { useState } from 'react';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import { ContentList, Scroller } from '../TeamTables';
import { ContainerBorder } from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { MapProvider } from '../../../context/MapProvider';
import ProjectMap from '../../screen-components/ProjectInfo/components/ProjectInfoMap/ProjectMap';

const UserMap = () => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	return (
		<ContainerBorder style={{ flexGrow: isOpen ? 1 : 'initial' }}>
			<ContentList>
				<SectionHeader
					headerStyle={{ padding: '0 20px' }}
					showDropdown={true}
					showSearch={false}
					onSearch={() => {}}
					dropdownValue={setIsOpen}>
					Map
				</SectionHeader>
				<Scroller>
					{isOpen && (
						<MapProvider>
							<ProjectMap
								initialAddress={'Missouri, United States'}
								initialZoom={3}
								initialHeight="100%"
							/>
						</MapProvider>
					)}
				</Scroller>
			</ContentList>
		</ContainerBorder>
	);
};

export default UserMap;
