import { useContext, useEffect, useState } from 'react';
import { Button, IconButton, Switch, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppLink from '../../components/reusable-components/AppLink';
import AppText from '../../components/reusable-components/AppText';
import FormikTextField from '../../components/reusable-components/FormikTextField';
import { AuthContext } from '../../context/AuthProvider';
import BackgroundVideo from '../../components/reusable-components/BackgroundVideo';
import { ContractorRegisterValues } from '../../types/auth';
import styled from 'styled-components';
import logoSrc from '../../assets/circleLogo.png';
import LoadingScreen from '../../components/reusable-components/LoadingScreen';
import { FirebaseError } from 'firebase/app';
import {
	AuthContainerContent,
	AuthContainerContentGrid,
	AuthContainerContentGridContent,
	RealityBackground,
} from './style';
import LoginSocial from './LoginSocial';
import { Info } from '@mui/icons-material';
import { TermsAndPrivacy } from '../../components/screen-components/Login/TermsAndPrivacy';
import { DividerLine } from './DividerLine';
import Footer from './Footer';
import theme from '../../styles/theme';

const phoneRegExp =
	/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const validationsForm = {
	fullName: yup.string().required('Name is Required'),
	email: yup.string().email('Enter a Valid Email').required('Email is Required'),
	password: yup
		.string()
		.min(6, 'Password Must Contain At Least 6 Characters')
		.required('Password is Required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Password Does Not Match')
		.required('Confirm Your password'),
};
const checkedValidationsForm = {
	fullName: yup.string().required('Name is Required'),
	email: yup.string().email('Enter a Valid email').required('Email is required'),
	address: yup.string().required('Address is required'),
	phone: yup
		.string()
		.matches(phoneRegExp, 'Enter a Valid Phone Number')
		.required('Phone Number is Required'),
	password: yup
		.string()
		.min(6, 'Password Must Contain At Least 6 Characters')
		.required('Password is Required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Password Does Not Match')
		.required('Confirm Your Password'),
};

export default function Register() {
	const { handleOnRegister, loadingUser, authLoadingText } = useContext(AuthContext);

	const [checked, toggleChecked] = useState(false);
	const [errMessage, setErrMessage] = useState('');

	const handleRegister = ({
		fullName,
		email,
		password,
		passwordRepeat,
		address,
		phone,
	}: ContractorRegisterValues) => {
		const obj = {
			fullName,
			email,
			password,
			passwordRepeat,
		};
		return checked ? { ...obj, address, phone } : obj;
	};

	useEffect(() => {
		document.title = 'Register | Robotic Imaging';
	}, []);

	return (
		<>
			<Nav>
				<a href="https://www.roboticimaging.com/">
					<img
						src={logoSrc}
						alt=""
						style={{
							height: '40px',
							marginTop: '15px',
						}}
					/>
				</a>
			</Nav>

			<BackgroundVideo />

			{!loadingUser ? (
				<>
					<AuthContainerContent $isRegister={true}>
						<RealityBackground>
							<div className="reality">Reality Capture At Scale</div>
							<span style={{ color: theme.palette.primary.main }}>
								<b>Robotic Imaging</b> simplifies the asset documentation experience by
								harnessing the power of AI, OCR and 3D LiDAR Scanning.
							</span>
						</RealityBackground>

						<AuthContainerContentGrid>
							<div>
								<AppText variant={'h5'} text="Create an Account" />
								<div
									style={{
										display: 'flex',
										placeContent: 'center',
										alignItems: 'center',
									}}>
									<SwitchContainer>
										<StyledSwitch
											color="primary"
											checked={checked}
											onChange={() => toggleChecked(prev => !prev)}
											name="checkedA"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
										<span
											style={{
												color: checked ? '#ffb130' : 'white',
											}}>
											Contractor Account
										</span>
									</SwitchContainer>
									<Tooltip title="Create a Contractor Account to start collecting data and making money on your own time in your area.">
										<IconButton size="small">
											<Info style={{ color: '#ffb130' }} />
										</IconButton>
									</Tooltip>
								</div>
							</div>
							<AuthContainerContentGridContent>
								<Formik
									validateOnBlur={true}
									validateOnChange={false}
									initialValues={{
										fullName: '',
										email: '',
										password: '',
										passwordRepeat: '',
										address: '',
										phone: '',
									}}
									validationSchema={yup
										.object()
										.shape(checked ? checkedValidationsForm : validationsForm)}
									onSubmit={async (values, { setFieldError, setSubmitting }) => {
										setErrMessage('');
										const obj = handleRegister(values);

										handleOnRegister(obj, checked).catch((err: FirebaseError) => {
											setSubmitting(false);
											if (err.code === 'functions/already-exists') {
												console.log(err.message);
												setErrMessage(err.message);
											}
										});
									}}>
									{({ errors, touched, handleSubmit, isSubmitting }) => (
										<AuthForm $isChecked={checked} onSubmit={handleSubmit}>
											<FormikTextField
												label="Full Name"
												name="fullName"
												type="text"
												helperText={touched.fullName ? errors.fullName : null}
												error={touched.fullName && Boolean(errors.fullName)}
											/>
											<FormikTextField
												label="Email"
												name="email"
												type="email"
												helperText={touched.email ? errors.email : null}
												error={touched.email && Boolean(errors.email)}
												autoComplete="username"
											/>
											{checked ? (
												<FormikTextField
													label="Address"
													name="address"
													type="address"
													helperText={touched.address ? errors.address : null}
													error={touched.address && Boolean(errors.address)}
												/>
											) : null}
											{checked ? (
												<FormikTextField
													label="Phone Number"
													name="phone"
													type="phone"
													helperText={touched.phone ? errors.phone : null}
													error={touched.phone && Boolean(errors.phone)}
												/>
											) : null}
											<FormikTextField
												label="Password"
												name="password"
												type="password"
												helperText={touched.password ? errors.password : null}
												error={touched.password && Boolean(errors.password)}
												autoComplete="new-password"
											/>
											<FormikTextField
												label="Retype Password"
												name="passwordRepeat"
												type="password"
												helperText={touched.passwordRepeat ? errors.passwordRepeat : null}
												error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
												autoComplete="new-password"
											/>

											{errMessage ? <ErrorMessage>{errMessage}</ErrorMessage> : null}

											<AuthActions $isChecked={checked}>
												<Button
													style={{ margin: '10px 0' }}
													type="submit"
													color="primary"
													variant="contained"
													disabled={isSubmitting}>
													Create Account
												</Button>
												<AppLink to="/login" text="Already have an account?" />
											</AuthActions>
										</AuthForm>
									)}
								</Formik>
								{!checked && <DividerLine />}
								{!checked && <LoginSocial isRegister={true} />}
							</AuthContainerContentGridContent>
						</AuthContainerContentGrid>
						<TermsAndPrivacy />
					</AuthContainerContent>
					<Footer isRegister />
				</>
			) : (
				<LoadingScreen message={authLoadingText} />
			)}
		</>
	);
}

const Nav = styled.nav`
	background: transparent;
	height: 50px;
	display: flex;
	padding-left: 20px;
	align-items: left;
	position: fixed;
`;

const SwitchContainer = styled.div``;

const StyledSwitch = styled(Switch)`
	> .MuiSwitch-track {
		background-color: white;
	}
`;

const ErrorMessage = styled.span`
	color: red;
`;

const AuthActions = styled.div<{ $isChecked: boolean }>`
	display: flex;
	flex-direction: column;
	padding-top: ${({ $isChecked }) => ($isChecked ? '30px' : '')};
	gap: 5px;
	align-items: center;
	width: 100%;
`;

const AuthForm = styled.form<{ $isChecked: boolean }>`
	display: flex;
	gap: 15px;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
	max-width: 600px;
	place-content: center;
	flex-direction: ${({ $isChecked }) => ($isChecked ? 'row' : 'column')};

	@media (max-width: 685px) {
		flex-direction: column;
	}
`;
