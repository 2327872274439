import { Route, Routes } from 'react-router-dom';

import PrivateRoute from './routes/PrivateRoute';
import AuthRoute from './routes/AuthRoute';

import ProjectsList from './screens/ProjectsList';
import Login from './screens/auth/Login';
import Register from './screens/auth/Register';
// import Welcome from './screens/Welcome';
import NotFound from './screens/404page';
import DownloadDeliverables from './screens/DownloadDeliverables';
import ProjectInfo from './screens/ProjectInfo';
import UploadDeliverables from './screens/UploadDeliverables';
import AdminRoute from './routes/AdminRoute';
import ModelersRoute from './routes/ModelersRoute';
import Map from './screens/Map';
import Leads from './screens/Leads';
import Clients from './screens/Clients';
import ClientInfo from './screens/ClientInfo';
import Modelers from './screens/Modelers';
import ModelerPage from './screens/ModelerPage';
import PendingProjectShare from './screens/PendingProjectShare';
import ProjectUtilityForm from './screens/ProjectUtilityForm';
import ProjectUtilityFormList from './screens/ProjectUtilityFormList';
import CaptureFormGallery from './screens/CaptureFormGallery';
import Organizations from './screens/Organizations';
import OrganizationPage from './screens/OrganizationPage';
import TeamPage from './screens/TeamPage';
import UserManagement from './screens/UserManagement';
import UserManagementRoute from './routes/UserManagementRoute';
import LeadsRoute from './routes/LeadsRoute';
import CaptureFormGalleryV2 from './screens/CaptureFormGalleryV2';
import TemplatesList from './screens/TemplatesList';
import UtilityFormV2 from './screens/UtilityFormV2';
import TemplatesRoute from './routes/TemplatesRoute';
import V2FormsList from './screens/V2FormsList';
import V2FormsRoute from './routes/V2FormsRoute';
import NodesReorder from './screens/NodesReorder';
import UserStats from './screens/UserStats';
import Schedule from './screens/Schedule';
import QBRedirect from './screens/QBRedirect';

/**
 * Functional component that handles routing and contains the snackbar
 */
export default function App() {
	return (
		<Routes>
			<Route element={<AuthRoute />}>
				<Route index element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
			</Route>

			<Route element={<PrivateRoute />}>
				<Route path="/projects" element={<ProjectsList />} />
				<Route path="/projects/:projectId" element={<ProjectInfo />} />
				<Route path="/map" element={<Map />} />
				<Route
					path="/projects/:projectId/capture-form"
					element={<ProjectUtilityFormList />}
				/>
				<Route
					path="/projects/:projectId/capture-form/:formId"
					element={<ProjectUtilityForm />}
				/>
				<Route element={<V2FormsRoute />}>
					<Route path="/projects/:projectId/capture-form-v2" element={<V2FormsList />} />
				</Route>

				<Route
					path="/projects/:projectId/capture-form-v3/:formId"
					element={<UtilityFormV2 />}
				/>
				<Route
					path="/projects/:projectId/capture-form-v2/:formId"
					element={<UtilityFormV2 />}
				/>
				<Route
					path="/projects/:projectId/capture-form-v2/:formId/reorder"
					element={<NodesReorder />}
				/>
				<Route element={<TemplatesRoute />}>
					<Route path="/templates" element={<TemplatesList />} />
					<Route path="/templates/:templateId" element={<NodesReorder />} />
				</Route>
				<Route path="/organizations" element={<Organizations />} />
				<Route path="/organizations/:organizationId" element={<OrganizationPage />} />
				<Route path="/teams/:organizationId/:teamId" element={<TeamPage />} />
				<Route
					path="/teams/:organizationId/:teamId/reports/:reportId"
					element={<TeamPage />}
				/>
				<Route path="/schedule" element={<Schedule />} />
				<Route element={<AdminRoute />}>
					<Route path="/clients" element={<Clients />} />
					<Route path="/clients/:clientName" element={<ClientInfo />} />

					<Route element={<LeadsRoute />}>
						<Route path="/sales" element={<Leads />} />
					</Route>

					<Route element={<ModelersRoute />}>
						<Route path="/modelers" element={<Modelers />} />
						<Route path="/modelers/:modelerId" element={<ModelerPage />} />
					</Route>

					<Route element={<UserManagementRoute />}>
						<Route path="/neural-net" element={<UserManagement />} />
						<Route path="/neural-net/:userId" element={<UserStats />} />
					</Route>

					<Route path="/organizations" element={<Organizations />} />
					<Route path="/organizations/:organizationId" element={<OrganizationPage />} />
					<Route path="/teams/:organizationId/:teamId" element={<TeamPage />} />
				</Route>
			</Route>

			<Route path="/pending-project-share" element={<PendingProjectShare />} />

			<Route path="/capture-form-gallery/:formId" element={<CaptureFormGallery />} />
			<Route path="/capture-form-gallery-v2/:formId" element={<CaptureFormGalleryV2 />} />

			{/* Redirect Pages */}
			<Route path="/qb-redirect" element={<QBRedirect />} />

			{/* Anonymous Project Pages */}
			<Route
				path="/deliverables/:projectId/download"
				element={<DownloadDeliverables />}
			/>
			<Route path="/deliverables/:projectId/upload" element={<UploadDeliverables />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}
