import { IconButton } from '@mui/material';
import { createUtilityForm, getProject, getUtilityForms } from '../firebase';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ProjectContext } from '../context/ProjectProvider';
import Layout from '../components/reusable-components/Layout';
import styled from 'styled-components';
import { NoteAdd, Refresh } from '@mui/icons-material';
import UtilityFormListItem from '../components/screen-components/ProjectUtilityForm/UtilityFormListItem';
import {
	newElectrical,
	newFireProtection,
	newMechanical,
	newPlumbing,
} from '../components/screen-components/ProjectUtilityForm/utils/BaseCategories';
import { UtilityFormStatus } from '../components/screen-components/ProjectUtilityForm/types';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { cloneDeep } from 'lodash';
import LoadingScreen from '../components/reusable-components/LoadingScreen';
import { LayoutLink } from '../components/styled-components/links';
import { formatProjectTimestamps } from '../utils';

export type UtilityFormDetails = {
	id: string;
	status: UtilityFormStatus;
	formName: string;
	createdDate: Date | null;
	lastUpdated: Date;
	uploadedDate: Date | null;
	creator: string;
	editor?: string;
	canView?: boolean;
};

export default function ProjectUtilityFormList() {
	const navigate = useNavigate();
	const { project, setProject } = useContext(ProjectContext);
	const { projectId }: any = useParams();

	const isMounted = useRef(true);
	const [forms, setForms] = useState<UtilityFormDetails[]>([]);
	const [loadingForms, setLoadingForms] = useState(false);
	const [creatingForm, setCreatingForm] = useState(false);
	const [listenerForms, setListenerForms] = useState<UtilityFormDetails[]>([]);

	useEffect(() => {
		document.title = 'Form List';
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const projTitle = project
		? project.address || project.name || 'Unnamed Project'
		: 'Loading Project...';

	// Fetch, format, and set project
	useEffect(() => {
		const getSetProject = async () => {
			try {
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				setProject(formatProjectTimestamps([resData.project])[0]);
			} catch (err) {
				console.log(err);
				navigate('/404', { replace: true });
			}
		};
		getSetProject();
		// Cleanup on unmount
		return () => {
			setProject(null);
		};
	}, [projectId, setProject, navigate]);

	const callGetUtilityForm = useCallback(async () => {
		setLoadingForms(true);
		try {
			const response = await getUtilityForms(projectId);
			const formsList: UtilityFormDetails[] = (response.data as any)?.utilityForms;
			const listenerFormsArr: UtilityFormDetails[] = [];
			const formsListParsed = formsList.map(form => {
				form = {
					...form,
					createdDate: form.createdDate
						? new Date((form.createdDate as any)._seconds * 1000)
						: null,
					lastUpdated: new Date((form.lastUpdated as any)._seconds * 1000),
					uploadedDate: form.uploadedDate
						? new Date((form.uploadedDate as any)._seconds * 1000)
						: null,
				};
				if (form.status === 'processing' || form.status === 'uploaded') {
					listenerFormsArr.push(form);
				}
				return form;
			});

			if (isMounted.current) {
				setForms(formsListParsed);
				setLoadingForms(false);
				if (listenerFormsArr.length) {
					setListenerForms(listenerFormsArr);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}, [projectId]);

	useEffect(() => {
		callGetUtilityForm();
	}, [callGetUtilityForm]);

	// useEffect that initializes listener for uploaded and processing forms
	useEffect(() => {
		let unsub = () => {};

		if (listenerForms.length) {
			const formIds = listenerForms.map(form => form.id);
			// Create query for fetched forms that are processing
			const formQuery = query(
				collection(getFirestore(), 'utility_forms'),
				where('id', 'in', formIds)
			);

			// Create listener for processing forms
			unsub = onSnapshot(formQuery, snapshot => {
				snapshot.docs.forEach(formDoc => {
					const docData = formDoc.data();
					const processingIdx = listenerForms.findIndex(form => form.id === docData.id);

					// Update the UI with the new form status when it changes
					const oldStatus = listenerForms[processingIdx].status;
					const newStatus = docData.status;
					if (oldStatus !== newStatus) {
						// Update the forms useState, which controls the UI
						setForms(prev => {
							const formsIdx = prev.findIndex(form => form.id === docData.id);
							const formsClone = cloneDeep(prev);
							formsClone.splice(formsIdx, 1, {
								...prev[formsIdx],
								status: newStatus,
							});
							return formsClone;
						});
						// Update the listenerForm or remove the changed form from the listenerForms useState if the form is no longer uploaded or processing
						setListenerForms(prev => {
							if (newStatus === 'uploaded' || newStatus === 'processing') {
								return prev.splice(processingIdx, 1, {
									...prev[processingIdx],
									status: newStatus,
								});
							} else {
								return prev.filter(form => form.id !== docData.id);
							}
						});
					}
				});
			});
		}

		return () => {
			unsub();
		};
	}, [listenerForms]);

	const callCreateUtilityForm = async () => {
		setCreatingForm(true);

		const existingFormNames = forms?.map((form: any) => form.formName);
		let newFormName = 'Report';
		let count = 1;

		while (existingFormNames?.includes(newFormName)) {
			count += 1;
			newFormName = `Report-${count}`;
		}
		try {
			const response = await createUtilityForm({
				projectId: projectId,
				formName: newFormName,
				formData: [newElectrical(), newMechanical(), newPlumbing(), newFireProtection()],
			});
			const formId = response.data;
			navigate(`/projects/${projectId}/capture-form/${formId}`);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Layout
			LeftNavComponent={
				project ? (
					<LayoutLink
						to={`/projects/${project.id}`}
						title="Navigate to project details screen">
						<h1>{projTitle}</h1>
					</LayoutLink>
				) : null
			}>
			<Container>
				<TitleContainer>
					<Title>Reports</Title>
					<TitleButtonContainer onClick={callGetUtilityForm}>
						<IconButton title="Refresh report list">
							<Refresh />
						</IconButton>

						<IconButton onClick={callCreateUtilityForm} title="Create a new report">
							<NoteAdd />
						</IconButton>
					</TitleButtonContainer>
				</TitleContainer>

				<UtilityFormsContainer>
					{creatingForm ? (
						<LoadingScreen message="Creating new form..." textColor="black" />
					) : loadingForms ? (
						<LoadingScreen message="Loading forms..." textColor="black" />
					) : forms?.length ? (
						forms.map((form, i) => (
							<UtilityFormListItem
								key={form.id + form.status}
								form={form}
								projectId={projectId}
							/>
						))
					) : (
						<h3 style={{ margin: 'auto' }}>No reports to display...</h3>
					)}
				</UtilityFormsContainer>
			</Container>
		</Layout>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	background-color: white;

	width: 100%;
	overflow: hidden;
	padding: 10px;

	@media screen and (min-width: 769px) {
		width: 50%;
		max-width: 720px;
		margin: 10px auto;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		min-height: 100%;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 0;
	padding: 10px;

	border-bottom: solid 3px black;
`;

const Title = styled.h1`
	margin: 0;
`;

const TitleButtonContainer = styled.div`
	display: flex;
`;

const UtilityFormsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-height: 300px;
`;
