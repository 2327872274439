import { Box, IconButton } from '@mui/material';
import Layout from '../../components/reusable-components/Layout';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
	ListButtonsContainer,
	ListContainer,
	ListContent,
	ListTitle,
	ListTitleContainer,
} from '../../components/reusable-components/List';
import { UtilityFormV2 } from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { useContext, useEffect, useState } from 'react';
import { V2FormsContext } from '../../context/V2FormsProvider';
import FormsListItem from '../../components/screen-components/FormsList/FormsListItem';
import { useParams } from 'react-router-dom';
import Searchbar from '../../components/screen-components/TemplateList/Searchbar';
import AddFormDialog from '../../components/screen-components/FormsList/AddFormDialog';
import useAddScratchForm from './useAddForm';
import InputDialog from '../../components/reusable-components/InputDialog';
import useFormName from './useFormName';
import styled from 'styled-components';
import useAddTemplateForm from './useAddTemplateForm';
import PickTemplateDialog from '../../components/screen-components/FormsList/PickTemplateDialog';
import FormsLoadingLayer from '../../components/screen-components/FormsList/FormsLoadingLayer';

const V2FormsList = ({ hideNav = false }: { hideNav?: boolean }) => {
	const { projectId }: any = useParams();

	const [formType, setFormType] = useState<'scratch' | 'template' | undefined>();
	const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>();

	const { openAddNameDialog, closeNameDialog, handleAddName, isAdding, nameDialogOpen } =
		useFormName();

	const { addDialogOpen, closeAddDialog, openAddDialog } = useAddScratchForm();

	const {
		// callCreateLegacyForm,
		closePickTemplateDialog,
		creatingForm,
		openPickTemplateDialog,
		pickTemplate,
		pickTemplateDialogOpen,
	} = useAddTemplateForm();

	const {
		clearQuery,
		// existingFormNames,
		loadingForms,
		loadingV1Forms,
		nextPage,
		onChangeQuery,
		page,
		prevPage,
		searchQuery,
		totalPages,
		forms,
		project,
		setProjectId,
	} = useContext(V2FormsContext);

	useEffect(() => {
		if (setProjectId && projectId) setProjectId(projectId);
	}, [projectId, setProjectId]);

	// if on V2 url, doc title is 'Report List', if on Project Info page (hideNav = true), doc title remains as is (project address)
	useEffect(() => {
		!hideNav && (document.title = 'Report List');
	}, [hideNav]);

	const content = (
		<>
			<Box
				paddingTop={4}
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center">
				<Searchbar
					query={searchQuery}
					handleInputChange={onChangeQuery}
					clearQuery={clearQuery}
					onAdd={openAddDialog}
					name={'Report'}
					showButton={true}
					style={{ maxWidth: 650, width: '100%', marginBottom: 20 }}
				/>
				<ListButtonsContainer>
					<IconButton onClick={prevPage} disabled={loadingForms || page === 1}>
						<ChevronLeft style={{ fontSize: 28 }} color="primary" />
					</IconButton>
					<TextContainer>
						{page} / {totalPages}
					</TextContainer>
					<IconButton onClick={nextPage} disabled={loadingForms || page === totalPages}>
						<ChevronRight style={{ fontSize: 28 }} color="primary" />
					</IconButton>
				</ListButtonsContainer>
				<ListContainer>
					<ListTitleContainer>
						<ListTitle>Reports</ListTitle>
					</ListTitleContainer>
					<ListContent
						list={forms}
						loading={loadingForms}
						loadingAlternative={loadingV1Forms}
						renderItem={(form: UtilityFormV2) => (
							<FormsListItem key={form.id} form={form} />
						)}
						emptyMessage="No reports to display..."
						loadingMessage="Loading reports..."
					/>
				</ListContainer>
			</Box>
			<AddFormDialog
				open={addDialogOpen}
				handleClose={closeAddDialog}
				handleScratch={() => {
					closeAddDialog();
					setFormType('scratch');
					openAddNameDialog();
				}}
				handleTemplate={() => {
					closeAddDialog();
					setFormType('template');
					openPickTemplateDialog();
				}}
				// handleLegacy={async () => {
				// 	if (existingFormNames && !loadingV1Forms) {
				// 		closeAddDialog();
				// 		const formId = await callCreateLegacyForm(projectId, existingFormNames);
				// 		navigate(`/projects/${projectId}/capture-form/${formId}`);
				// 	}
				// }}
			/>
			<InputDialog
				open={nameDialogOpen}
				handleClose={closeNameDialog}
				handleSave={value =>
					handleAddName(value, formType, projectId, selectedTemplateId)
				}
				title="Enter the name of the new report"
				label="Name"
			/>
			<PickTemplateDialog
				handleClose={closePickTemplateDialog}
				handlePickTemplate={templateId => {
					setSelectedTemplateId(templateId);
					pickTemplate();
					openAddNameDialog();
				}}
				open={pickTemplateDialogOpen}
			/>
		</>
	);

	return hideNav ? (
		<>
			<FormsLoadingLayer showOverlay={isAdding || creatingForm} />
			{content}
		</>
	) : (
		<>
			<FormsLoadingLayer showOverlay={isAdding || creatingForm} />

			<Layout LeftNavComponent={<h1>{project?.address || 'Loading...'}</h1>}>
				{content}
			</Layout>
		</>
	);
};

const TextContainer = styled.div`
	color: #ffb310;
	font-size: 16px;
	font-weight: 600;
`;

export default V2FormsList;
