import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FormContext } from './FormContext';
import { UploadContext } from './UploadContext';
import { SnackContext } from '../../../context/SnackProvider';

import SubmittingForm from './screens/SubmittingForm';
import ProcessingForm from './screens/ProcessingForm';
import FormFill from './screens/FormFill';
import Layout from '../../reusable-components/Layout';
import { ProjectContext } from '../../../context/ProjectProvider';
import { Category } from './types';

import EditIcon from '@mui/icons-material/Edit';
import { AuthContext } from '../../../context/AuthProvider';
import { resetUtilityForm } from '../../../firebase';
import { YellowIconButton } from '../../styled-components/buttons';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { LayoutLink } from '../../styled-components/links';
import { Link } from 'react-router-dom';
import Forms from '../../../assets/projectInfoIcons/Forms.svg';
import CollectionsIcon from '@mui/icons-material/Collections';

/**
 * Component to render the form.
 */
export default function UtilityFormRenderer() {
	const { formId }: any = useParams();

	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const { uploadQueueState, globalCachedForm, setFormSubmitState } =
		useContext(UploadContext);
	const {
		formStatus,
		formUrl,
		formTitle,
		formError,
		submittingUtilityForm,
		wrappedDebounce,
		fetchingUtilityForm,
		fetchUtilityForm,
	} = useContext(FormContext);
	const FormsIcon = () => <img src={Forms} alt="" width={35} />;

	useEffect(() => {
		if (!(formId in uploadQueueState) && submittingUtilityForm) {
			wrappedDebounce('uploaded', globalCachedForm as Category[]);
			setFormSubmitState(prev => {
				delete prev[formId];
				return { ...prev };
			});

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Submission successful`,
			});
		}
	}, [
		formId,
		globalCachedForm,
		setFormSubmitState,
		setSnackbarProps,
		submittingUtilityForm,
		uploadQueueState,
		wrappedDebounce,
	]);

	const projTitle = project
		? project.address || project.name || 'Unnamed Project'
		: 'Loading Project...';

	const editProject = async () => {
		setSnackbarProps({
			open: true,
			message: `Setting ${formTitle?.formName} back to edit mode...`,
			severity: 'warning',
			hideDuration: null,
		});

		await resetUtilityForm({ formId: formId, editingForm: true });
		await fetchUtilityForm();

		setSnackbarProps({
			open: true,
			message: `You can now edit ${formTitle?.formName}!`,
			severity: 'success',
		});
	};

	return (
		<Layout
			LeftNavComponent={
				<>
					{project ? (
						<>
							<LayoutLink
								to={`/projects/${project.id}`}
								title="Navigate to project details page">
								<h1>{projTitle}</h1>
							</LayoutLink>

							<Link
								to={`/projects/${project?.id}/capture-form`}
								title="Navigate to form list"
								tabIndex={-1}>
								<YellowIconButton size="medium">
									<FormsIcon />
								</YellowIconButton>
							</Link>

							<Link
								to={`/capture-form-gallery/${formId}`}
								title="Navigate to this report's gallery"
								tabIndex={-1}>
								<YellowIconButton size="large">
									<CollectionsIcon style={{ fontSize: '30px' }} />
								</YellowIconButton>
							</Link>
						</>
					) : null}
				</>
			}
			RightNavComponent={
				formUrl && user?.isAdmin ? (
					<YellowIconButton title="Edit form" size="large" onClick={() => editProject()}>
						<EditIcon />
					</YellowIconButton>
				) : undefined
			}>
			{fetchingUtilityForm ? (
				<LoadingScreen message="Loading Form..." />
			) : submittingUtilityForm && uploadQueueState[formId] ? (
				<SubmittingForm formId={formId} />
			) : submittingUtilityForm ? (
				<LoadingScreen message="Submitting Form..." />
			) : formStatus === 'uploaded' || formStatus === 'processing' ? (
				<ProcessingForm />
			) : formStatus === 'processing failed' ? (
				<UtilityFormLoadingContainer>
					<h3>Failed to generate PDF!</h3>
					<h4>Please contact a member of the Robotic Imaging team.</h4>
				</UtilityFormLoadingContainer>
			) : formUrl ? (
				<PDFContainer>
					<PDFEmbed data={formUrl} type="application/pdf">
						<span>Could not load PDF Viewer:</span>
						<a href={formUrl}>Click to view PDF</a>
					</PDFEmbed>
				</PDFContainer>
			) : formError ? (
				<UtilityFormLoadingContainer>
					<h3>Failed to load form...</h3>
					<h4>
						{formError.code === 'functions/not-found'
							? 'This report does not exist.'
							: formError.message.includes('current editor')
							? 'You are not the current editor of this report.'
							: formError.message.includes('does not have access')
							? 'You do not have access to this report.'
							: formError.message}
					</h4>
				</UtilityFormLoadingContainer>
			) : (
				<FormFill />
			)}
		</Layout>
	);
}

export const UtilityFormLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;

	color: #ffb310;
	padding: 50px;
	height: 100%;
	width: 100%;

	> * {
		margin: 0;
	}
`;

const PDFContainer = styled.div`
	height: 100%;
	width: 100%;
	padding: 20px;
`;

const PDFEmbed = styled.object`
	height: 100%;
	width: 100%;
`;
