import { FC, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';

type Props = {
	imageUrl: string;
	imageName?: string;
	imageHeight?: number;
	imageWidth?: number;
};

const height = 600;
const width = 600;

const PreviewImage: FC<Props> = ({
	imageUrl,
	imageName,
	imageHeight = 32,
	imageWidth = 32,
}: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Thumbnail
				width={`${imageWidth}px`}
				height={`${imageHeight}px`}
				onClick={() => setOpen(true)}
				src={`${process.env.REACT_APP_image_resizer_url}?imageUrl=${imageUrl}&width=${imageWidth}&height=${imageHeight}`}
			/>
			<Dialog open={open}>
				<DialogTitleGrid>
					<h6>{imageName}</h6>
					<IconButton onClick={() => setOpen(!open)}>
						<CloseIconWhite />
					</IconButton>
				</DialogTitleGrid>
				<DialogContent style={{ padding: 0 }}>
					<img
						src={`${process.env.REACT_APP_image_resizer_url}?imageUrl=${imageUrl}&width=${width}&height=${height}`}
						alt={imageName}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

const Thumbnail = styled('img')<{ width: string; height: string }>`
	object-fit: cover;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid ${theme.palette.primary.main};
	}
`;

const DialogTitleGrid = styled(DialogTitle)`
	display: grid;
	grid-template-columns: 1fr max-content;
`;

const CloseIconWhite = styled(CloseIcon)`
	color: #fff;
`;

export default PreviewImage;
