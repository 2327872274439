import * as XLSX from 'xlsx';
import { Timestamp } from 'firebase/firestore';
import { ProjectData, ProjectStatus } from '../../../../types';

// convert Firestore timestamp to Date
const convertTimestampToDate = (
	timestamp: Timestamp | Date | null | undefined
): Date | null => {
	if (timestamp instanceof Timestamp) {
		return timestamp.toDate();
	} else if (timestamp instanceof Date) {
		return timestamp;
	}
	return null;
};

// order of statuses
const statusOrder: ProjectStatus[] = [
	'Proposal Pending',
	'Proposal Sent',
	'To Be Scheduled',
	'On-Site Capture Complete',
	'Registration',
	'Ready For Modeling',
	'Modeling In Progress',
	'QA/QC',
	'Pending Payment / Delivered',
	'Delivered',
	'Pending Payment',
	'Sample',
	'Template',
	'Archive',
];

// Sorting function for projects based on status order
const sortProjectsByStatus = (a: ProjectData, b: ProjectData): number => {
	const indexA = statusOrder.indexOf(a.projectStatus);
	const indexB = statusOrder.indexOf(b.projectStatus);
	if (indexA === -1 && indexB === -1) return 0;
	if (indexA === -1) return 1;
	if (indexB === -1) return -1;
	return indexA - indexB;
};

export const exportUserDataToXLSX = (projects: ProjectData[], userName: string) => {
	// Sort projects by status
	const sortedProjects = [...projects].sort(sortProjectsByStatus);

	const data = sortedProjects.map(project => {
		const deliveryDate = convertTimestampToDate(project.deliveryTimestamp);
		return {
			Status: project.projectStatus,
			'Organization(s)': project.orgs ? project.orgs.map(org => org.name).join(', ') : '',
			Address: project.address,
			'Delivery Date': deliveryDate ? deliveryDate.toLocaleDateString() : '',
		};
	});

	const headers = ['Status', 'Organization(s)', 'Address', 'Delivery Date'];
	const sheetData = [headers, ...data.map(Object.values)];

	const ws = XLSX.utils.aoa_to_sheet(sheetData);

	//  calculate cell width
	const calculateCellWidth = (value: string): number => {
		return Math.max(15, value.length * 1.2); // min width of 10, 1.2 multiplier for padding
	};

	// Set column widths
	const columnWidths = headers.map((header, index) => {
		const maxWidth = Math.max(
			calculateCellWidth(header),
			...data.map(row => calculateCellWidth(String(Object.values(row)[index])))
		);
		return { wch: maxWidth };
	});
	ws['!cols'] = columnWidths;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, 'Projects');

	XLSX.writeFile(wb, `${userName}_projects.xlsx`);
};
