import { useContext, useEffect, useState } from 'react';
import { fetchEquipment } from '../../../../../../utils/fetchEquipment';
import MultiStringCell from './cells/MultiStringCell';
import { AuthContext } from '../../../../../../context/AuthProvider';

export const EquipmentColumnComponent = ({
	row,
	rowEditing,
}: {
	row: any;
	rowEditing: boolean;
}) => {
	const { user } = useContext(AuthContext);

	const [equipmentOptions, setEquipmentOptions] = useState<string[]>([]);

	useEffect(() => {
		const getEquipmentOptions = async () => {
			const options = await fetchEquipment();
			setEquipmentOptions(options);
		};

		if (user?.isAdmin) getEquipmentOptions();
	}, [user?.isAdmin]);

	return (
		<MultiStringCell
			row={row}
			rowEditing={rowEditing}
			field="equipment"
			title="Equipment"
			options={equipmentOptions}
		/>
	);
};
