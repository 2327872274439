import { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../../../context/ProjectProvider';
import styled from 'styled-components';

const DeliveryCountdown = () => {
	const { project } = useContext(ProjectContext);
	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		if (project && project.deliveryTimestamp) {
			const deliveryDate = new Date(project.deliveryTimestamp).getTime();

			if (deliveryDate > Date.now()) {
				const timer = setInterval(() => {
					const currentTime = new Date().getTime();
					const difference = deliveryDate - currentTime;

					if (difference <= 0) {
						clearInterval(timer);
					} else {
						const days = Math.floor(difference / (1000 * 60 * 60 * 24));
						const hours = Math.floor(
							(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
						);
						const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
						const seconds = Math.floor((difference % (1000 * 60)) / 1000);

						setTimeRemaining({ days, hours, minutes, seconds });
					}
				}, 1000);

				return () => clearInterval(timer);
			} else {
				setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			}
		}
	}, [project]);

	return (
		<div>
			{project?.deliveryTimestamp &&
				new Date(project.deliveryTimestamp).getTime() > Date.now() && (
					<Countdown>
						Delivery in: <span style={{ color: '#ffb310' }}>{timeRemaining.days}</span>{' '}
						days <span style={{ color: '#ffb310' }}>{timeRemaining.hours}</span> hours{' '}
						<span style={{ color: '#ffb310' }}>{timeRemaining.minutes}</span> minutes{' '}
						<span style={{ color: '#ffb310' }}>{timeRemaining.seconds}</span> seconds
					</Countdown>
				)}
		</div>
	);
};

const Countdown = styled.div`
	font-size: 1rem;
	color: white;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	white-space: nowrap;
`;

export default DeliveryCountdown;
