import { OAuthProvider } from 'firebase/auth';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';

//The Azure Secret expires on 09/01/2026
const provider = new OAuthProvider('microsoft.com');
// provider.addScope('email.read');

const useMicrosoft = () => {
	const { handleSocialLogin, handleSocialRegister } = useContext(AuthContext);

	const login = async () => {
		await handleSocialLogin(provider);
	};

	const register = async () => {
		await handleSocialRegister(provider);
	};

	return { login, register };
};

export default useMicrosoft;
