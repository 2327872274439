import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	collection,
	doc,
	DocumentData,
	getDoc,
	getDocs,
	getFirestore,
	query,
	QuerySnapshot,
	where,
} from 'firebase/firestore';
import { ContainerProps, Team } from '../types';
import { ReportData } from '../types/reports';
import { FormTemplate } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { getTeams } from '../firebase';
import { AuthContext } from './AuthProvider';
import { OrganizationsContext } from './OrganizationsProvider';

export type UserHomeContextType = {
	loadData: (userId: string) => void;
	loadingReports: boolean;
	loadingTemplates: boolean;
	loadingTeams: boolean;
	reports: ReportData[] | undefined;
	teams: Team[];
	templates: FormTemplate[] | undefined;
	refreshTemplates: () => void;
	refreshReports: () => void;
	refreshTeams: (userId: string) => void;
};

export const UserHomeContext = createContext<UserHomeContextType>(
	{} as UserHomeContextType
);

export const UserHomeProvider = ({ children }: ContainerProps) => {
	const [reports, setReports] = useState<ReportData[] | undefined>();
	const [loadingReports, setLoadingReports] = useState(true);
	const [teams, setTeams] = useState<Team[]>([]);
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [loadingTemplates, setLoadingTemplates] = useState(true);
	const [userId, setUserId] = useState('');
	const [loadingTeams, setLoadingTeams] = useState(true);
	const { user } = useContext(AuthContext);
	const { selectedOrganizationId } = useContext(OrganizationsContext);

	const loadTeams = useCallback(
		async (userId: string) => {
			setLoadingTeams(true);

			const teamIds: string[] =
				(await getDoc(doc(getFirestore(), 'users', userId))).data()?.teamIds || [];

			const teamPromises = teamIds.map(teamId =>
				getDoc(doc(getFirestore(), 'teams', teamId))
			);

			const teamDocs = await Promise.all(teamPromises);
			const teamData = teamDocs.map(doc => ({ ...doc.data(), id: doc.id } as Team));

			// If the user is an org owner, fetch all teams for the organization
			const orgOwnerIds = user?.orgOwnerIds || [];
			const orgTeamPromises = orgOwnerIds.map(organizationId =>
				getTeams({ organizationId })
			);

			const orgTeamCollections = await Promise.all(orgTeamPromises);
			const orgTeams = orgTeamCollections
				.map(result => {
					const collection = result.data as Team[];
					return collection;
				})
				.flat();

			// Merge the admin teams with the fetched teams without duplicates
			const mergedTeams = [
				...teamData,
				...orgTeams.filter(
					team => !teamData.some(existingTeam => existingTeam.id === team.id)
				),
			];

			setTeams(mergedTeams);
			setLoadingTeams(false);
		},
		[user?.orgOwnerIds]
	);

	const loadData = useCallback(
		(userId: string) => {
			setUserId(userId);
			setLoadingReports(true);
			setLoadingTemplates(true);
			loadTeams(userId);
		},
		[loadTeams]
	);

	const loadReportsForAllTeams = useCallback(async () => {
		if (!teams || !user) return;
		setLoadingReports(true);
		if (!teams.length) {
			setReports([]);
			setLoadingReports(false);
			return;
		}

		const reportPromises: Promise<QuerySnapshot<DocumentData, DocumentData>>[] = [];
		teams.forEach(async team => {
			if ((team.userIds || []).includes(user.id)) {
				// If user is directly in the team, get all reports belonging to that team.
				reportPromises.push(
					getDocs(
						query(collection(getFirestore(), 'reports'), where('teamId', '==', team.id))
					)
				);
			} else if (user.orgOwnerIds && user.orgOwnerIds.length) {
				// If the user is an org owner, get all reports belonging to teams in that org.
				reportPromises.push(
					getDocs(
						query(
							collection(getFirestore(), 'reports'),
							where('organizationId', 'in', user.orgOwnerIds)
						)
					)
				);
			}
		});

		const reportCollections = await Promise.all(reportPromises);
		const reportIdMap: { [reportId: string]: boolean } = {};
		const reportsList = reportCollections
			.flatMap(collection =>
				collection.docs.map(doc => ({ ...doc.data(), id: doc.id } as ReportData))
			)
			.filter(report => {
				// Remove duplicate reports.
				if (reportIdMap[report.id || '']) return false;
				else {
					reportIdMap[report.id || ''] = true;
					return true;
				}
			})
			.sort(
				(a, b) =>
					(b.createdAt?.toDate().getTime() || 0) - (a.createdAt?.toDate().getTime() || 0)
			);

		setReports(reportsList);
		setLoadingReports(false);
	}, [teams, user]);

	const loadTemplatesForAllTeams = useCallback(async () => {
		if (!teams || !user) return;
		setLoadingTemplates(true);
		if (!teams.length) {
			setReports([]);
			setLoadingReports(false);
			return;
		}

		const templatePromises: Promise<QuerySnapshot<DocumentData, DocumentData>>[] = [];
		teams.forEach(team => {
			if (user.teamIds?.includes(team.id)) {
				// If user is directly in the team, get all templates belonging to that team.
				templatePromises.push(
					getDocs(
						query(
							collection(getFirestore(), 'utility_forms_v2_templates'),
							where('teamIds', 'array-contains', team.id)
						)
					)
				);
			} else if (user.orgOwnerIds && user.orgOwnerIds.length) {
				// If the user is an org owner, get all templates belonging to teams in that org.
				templatePromises.push(
					getDocs(
						query(
							collection(getFirestore(), 'utility_forms_v2_templates'),
							where('organizationIds', 'array-contains-any', user.orgOwnerIds)
						)
					)
				);
			}
		});

		const templateCollections = await Promise.all(templatePromises);
		const templateIdMap: { [templateId: string]: boolean } = {};
		const templatesList = templateCollections
			.flatMap(collection =>
				collection.docs.map(
					templateDoc => ({ ...templateDoc.data(), id: templateDoc.id } as FormTemplate)
				)
			)
			.filter(template => {
				// Remove duplicate templates.
				if (templateIdMap[template.id]) return false;
				else {
					templateIdMap[template.id] = true;
					return true;
				}
			})
			.sort((a, b) => a.name.localeCompare(b.name));

		setTemplates(templatesList);
		setLoadingTemplates(false);
	}, [teams, user]);

	useEffect(() => {
		if (userId && teams) {
			loadReportsForAllTeams();
			loadTemplatesForAllTeams();
		}
	}, [loadReportsForAllTeams, loadTemplatesForAllTeams, teams, userId]);

	const filteredTeams = useMemo(() => {
		if (!selectedOrganizationId) return teams;
		return teams.filter(team => team.organizationId === selectedOrganizationId);
	}, [teams, selectedOrganizationId]);

	const filteredReports = useMemo(() => {
		if (!selectedOrganizationId) return reports;
		return reports?.filter(report => {
			const team = teams.find(t => t.id === report.teamId);
			return team?.organizationId === selectedOrganizationId;
		});
	}, [reports, teams, selectedOrganizationId]);

	const filteredTemplates = useMemo(() => {
		if (!selectedOrganizationId) return templates;
		return templates?.filter(template =>
			template.teamIds.some(
				teamId =>
					teams.find(t => t.id === teamId)?.organizationId === selectedOrganizationId
			)
		);
	}, [templates, teams, selectedOrganizationId]);

	return (
		<UserHomeContext.Provider
			value={{
				loadData,
				loadingReports,
				loadingTemplates,
				loadingTeams,
				reports: filteredReports,
				teams: filteredTeams,
				templates: filteredTemplates,
				refreshTemplates: loadTemplatesForAllTeams,
				refreshReports: loadReportsForAllTeams,
				refreshTeams: loadTeams,
			}}>
			{children}
		</UserHomeContext.Provider>
	);
};
