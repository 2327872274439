import {
	query,
	collection,
	getFirestore,
	limit,
	orderBy,
	getDocs,
	getCountFromServer,
	startAfter,
	where,
	DocumentData,
	startAt,
	or,
} from 'firebase/firestore';
import {
	useRef,
	useState,
	useEffect,
	useCallback,
	createContext,
	useContext,
} from 'react';
import { debounce } from 'lodash';
import { FormTemplate } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { AuthContext } from './AuthProvider';

const ITEMS_PER_PAGE = 5;

export type TemplatesContextType = {
	clearQuery: () => void;
	loadingForms: boolean;
	nextPage: () => void;
	onChangeQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;
	page: number;
	prevPage: () => void;
	refreshData: () => void;
	searchQuery: string;
	totalPages: number;
	templates: FormTemplate[] | undefined;
};

export const TemplatesContext = createContext({} as TemplatesContextType);

export const TemplatesProvider = ({ children }: { children?: React.ReactNode }) => {
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [loadingForms, setLoadingForms] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
	const { user } = useContext(AuthContext);
	const cursorStack = useRef<{ firstVisible: DocumentData; lastVisible: DocumentData }[]>(
		[]
	);

	useEffect(() => {
		document.title = 'Templates List';
	}, []);

	const loadTotalPages = useCallback(async () => {
		let templateQuery;
		let total;
		if (user?.isAdmin) {
			if (debouncedSearchQuery.length > 2) {
				templateQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where('searchableName', 'array-contains', debouncedSearchQuery.toLowerCase())
				);
			} else {
				templateQuery = query(collection(getFirestore(), 'utility_forms_v2_templates'));
			}
			const templatesCount = await getCountFromServer(templateQuery);
			total = Math.ceil(templatesCount.data().count / ITEMS_PER_PAGE);
		} else {
			templateQuery = query(
				collection(getFirestore(), 'utility_forms_v2_templates'),
				where('teamIds', 'array-contains-any', user?.teamIds)
			);
			const templatesCollection = await getDocs(templateQuery);
			let templatesData = templatesCollection.docs.map(doc => ({
				...(doc.data() as FormTemplate),
				id: doc.id,
			}));

			if (debouncedSearchQuery.length > 2) {
				templatesData = templatesData.filter(template =>
					template.searchableName.includes(debouncedSearchQuery.toLowerCase())
				);
			}

			total = Math.ceil(templatesData.length / ITEMS_PER_PAGE);
		}
		setTotalPages(total);
		setPage(total === 0 ? 0 : 1);
	}, [debouncedSearchQuery, user?.isAdmin, user?.teamIds]);

	const loadData = async (direction: number) => {
		if (loadingForms) return;
		setLoadingForms(true);

		let baseQuery = query(
			collection(getFirestore(), 'utility_forms_v2_templates'),
			orderBy('updatedAt', 'desc'),
			limit(ITEMS_PER_PAGE)
		);

		if (user?.isAdmin) {
			if (debouncedSearchQuery.length > 2) {
				baseQuery = query(
					baseQuery,
					where('searchableName', 'array-contains', debouncedSearchQuery.toLowerCase())
				);
			}
		}

		if (!user?.isAdmin) {
			baseQuery = query(
				baseQuery,
				or(
					where('teamIds', 'array-contains-any', user?.teamIds),
					where('organizationIds', 'array-contains-any', user?.orgOwnerIds)
				)
			);
		}

		if (direction === 1 && cursorStack.current.length > 0) {
			baseQuery = query(
				baseQuery,
				startAfter(cursorStack.current[cursorStack.current.length - 1].lastVisible)
			);
		} else if (direction === -1 && cursorStack.current.length > 1) {
			baseQuery = query(
				baseQuery,
				startAt(cursorStack.current[cursorStack.current.length - 2].firstVisible)
			);
		}

		const templatesCollection = await getDocs(baseQuery);
		const templatesData = templatesCollection.docs.map(doc => ({
			...(doc.data() as FormTemplate),
			id: doc.id,
		}));
		setTemplates(templatesData);

		if (!user?.isAdmin && debouncedSearchQuery.length > 2) {
			const filteredTemplates = templatesData.filter(template =>
				template.searchableName.includes(debouncedSearchQuery.toLowerCase())
			);
			setTemplates(filteredTemplates);
		}

		if (direction === 1) {
			cursorStack.current.push({
				firstVisible: templatesCollection.docs[0],
				lastVisible: templatesCollection.docs[templatesCollection.docs.length - 1],
			});
		} else if (direction === -1) {
			cursorStack.current.pop();
		}

		setLoadingForms(false);
	};

	const firstLoad = useCallback(async () => {
		setLoadingForms(true);

		await loadTotalPages();

		let baseQuery = query(
			collection(getFirestore(), 'utility_forms_v2_templates'),
			orderBy('updatedAt', 'desc'),
			limit(ITEMS_PER_PAGE)
		);

		if (user?.isAdmin) {
			if (debouncedSearchQuery.length > 2) {
				baseQuery = query(
					baseQuery,
					where('searchableName', 'array-contains', debouncedSearchQuery.toLowerCase())
				);
			}
		}

		if (!user?.isAdmin) {
			baseQuery = query(
				baseQuery,
				or(
					where('teamIds', 'array-contains-any', user?.teamIds),
					where('organizationIds', 'array-contains-any', user?.orgOwnerIds)
				)
			);
		}

		const templatesCollection = await getDocs(baseQuery);
		const templatesData = templatesCollection.docs.map(doc => ({
			...(doc.data() as FormTemplate),
			id: doc.id,
		}));

		if (templatesCollection.docs.length > 0) {
			cursorStack.current = [
				{
					firstVisible: templatesCollection.docs[0],
					lastVisible: templatesCollection.docs[templatesCollection.docs.length - 1],
				},
			];
		}
		setTemplates(templatesData);

		if (!user?.isAdmin && debouncedSearchQuery.length > 2) {
			const filteredTemplates = templatesData.filter(template =>
				template.searchableName.includes(debouncedSearchQuery.toLowerCase())
			);
			setTemplates(filteredTemplates);
		}

		const total = Math.ceil(templatesData.length / ITEMS_PER_PAGE);
		setTotalPages(total);
		setPage(total === 0 ? 0 : 1);

		setLoadingForms(false);
	}, [
		loadTotalPages,
		user?.isAdmin,
		user?.teamIds,
		user?.orgOwnerIds,
		debouncedSearchQuery,
	]);

	useEffect(() => {
		firstLoad();
	}, [firstLoad]);

	const nextPage = () => {
		if (totalPages && page < totalPages) {
			setPage(prev => prev + 1);
			loadData(1);
		}
	};

	const prevPage = () => {
		if (page > 1) {
			setPage(prev => prev - 1);
			loadData(-1);
		}
	};

	const onChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSearchQuery(event.target.value);
	const clearQuery = () => setSearchQuery('');

	const debouncedSearch = useRef(
		debounce((query: string) => setDebouncedSearchQuery(query), 300)
	);

	useEffect(() => {
		setLoadingForms(true);
		debouncedSearch.current(searchQuery);
	}, [searchQuery]);

	const refreshData = () => {
		setLoadingForms(true);
		firstLoad();
	};

	return (
		<TemplatesContext.Provider
			value={{
				clearQuery,
				loadingForms,
				nextPage,
				onChangeQuery,
				page,
				prevPage,
				refreshData,
				searchQuery,
				totalPages,
				templates,
			}}>
			{children}
		</TemplatesContext.Provider>
	);
};
