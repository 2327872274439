import styled from 'styled-components';
import theme from '../../styles/theme';

export const DividerLine = () => {
	return (
		<Divider>
			<div></div>
			<span>OR</span>
			<div></div>
		</Divider>
	);
};

const Divider = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;

	div {
		background-color: ${theme.palette.primary.main};
		width: 1px;
		flex: 1;
	}

	span {
		border: 1px solid ${theme.palette.primary.main};
		border-radius: 100%;
		color: ${theme.palette.primary.main};
		padding: 8px 10px;
	}

	@media (max-width: 720px) {
		flex-direction: row;

		div {
			background-color: ${theme.palette.primary.main};
			height: 1px;
			flex: 1;
		}
	}
`;
