import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import TeamCard from './TeamCard';
import { Team } from '../../../../types';

export interface TeamGridProps {
	teams: Team[];
	inSelectionMode: boolean;
	selectedTeams: string[];
	onSelectTeam: (teamId: string) => void;
	userIsAdmin?: boolean;
	userIsOrgOwner?: boolean;
	userIsTeamMember: (teamId: string) => boolean;
	onUnauthorizedAccess: () => void;
	style?: CSSProperties;
	isCondensed?: boolean;
}

const TeamGrid: React.FC<React.PropsWithChildren<TeamGridProps>> = ({
	teams,
	inSelectionMode,
	selectedTeams,
	onSelectTeam,
	userIsAdmin,
	userIsOrgOwner,
	userIsTeamMember,
	onUnauthorizedAccess,
	style,
	isCondensed = false,
}) => {
	return (
		<FlexContainer style={style} $isCondensed={isCondensed}>
			{teams.map(team => (
				<TeamCard
					key={team.id}
					team={team}
					userIsAdmin={userIsAdmin}
					userIsOrgOwner={userIsOrgOwner}
					inSelectionMode={inSelectionMode}
					isSelected={selectedTeams.includes(team.id)}
					onSelect={() => onSelectTeam(team.id)}
					userIsTeamMember={userIsTeamMember(team.id)}
					onUnauthorizedAccess={onUnauthorizedAccess}
					isCondensed={isCondensed}
				/>
			))}
		</FlexContainer>
	);
};

export default TeamGrid;

const FlexContainer = styled.div<{ $isCondensed: boolean }>`
	display: flex;
	flex-wrap: ${props => (props.$isCondensed ? 'nowrap' : 'wrap')};
	gap: 20px;
	padding: 20px;
	place-content: center;
	${props =>
		props.$isCondensed &&
		`
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  `}
	@media (max-width: 400px) {
		padding: 0px;
	}
`;
