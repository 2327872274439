import { ProjectStatus } from '../../../../types';
import whitePin from './assets/whitepin.png';
import redPin from './assets/redspin.png';
import yellowPin from './assets/yellowpin.png';
import bluePin from './assets/bluepin.png';
import purplePin from './assets/purplepin.png';
import greenPin from './assets/greenpin.png';
import pinkPin from './assets/pinkpin.png';
import blueberryPin from './assets/blueberrypin.png';
import grapePin from './assets/grapepin.png';
import quartzPin from './assets/quartzpin.png';
import chartreusePin from './assets/chartreusepin.png';

export const startingLatLng = {
	lat: 37.6872362,
	lng: -97.3431005,
};

export const mapHiddenStatuses: ProjectStatus[] = [
	'Proposal Pending',
	'Sample',
	'Template',
	'Archive',
];

export const getPinSrc = (projectStatus: ProjectStatus) => {
	switch (projectStatus) {
		case 'Proposal Sent':
			return whitePin;
		case 'To Be Scheduled':
			return redPin;
		case 'On-Site Capture Complete':
			return yellowPin;
		case 'Registration':
			return blueberryPin;
		case 'Ready For Modeling':
			return pinkPin;
		case 'Modeling In Progress':
			return bluePin;
		case 'QA/QC':
			return purplePin;
		case 'Pending Payment / Delivered':
			return chartreusePin;
		case 'Delivered':
			return greenPin;
		case 'Pending Payment':
			return grapePin;
		case 'Sample':
			return quartzPin;
		default:
			return whitePin;
	}
};

const cameraIcons = {
	P_Series:
		'https://firebasestorage.googleapis.com/v0/b/robotic-imaging---test.appspot.com/o/misc%2FCamera_Pin_Icons%2FP50(100x100).png?alt=media&token=39d46115-eb6a-4c62-a499-44ac2dfc486e',
	RTC: 'https://firebasestorage.googleapis.com/v0/b/robotic-imaging---test.appspot.com/o/misc%2FCamera_Pin_Icons%2FRTC(100x100).png?alt=media&token=3631f013-45e4-4335-ae57-ce228c163b6b',
	BLK: 'https://firebasestorage.googleapis.com/v0/b/robotic-imaging---test.appspot.com/o/misc%2FCamera_Pin_Icons%2FBLK(100x100).png?alt=media&token=d884a1e4-0814-4b29-bab9-bdd4d94a1877',
	MatterPort:
		'https://firebasestorage.googleapis.com/v0/b/robotic-imaging---test.appspot.com/o/misc%2FCamera_Pin_Icons%2FMatterport(100x100).png?alt=media&token=a4391195-83ea-4c92-842f-2f2ab13b1276',
};

export const getCameraIcon = (cameras: string[]) => {
	const defaultSize = { width: 30, height: 40 };
	const imageSize = { width: 35, height: 35 };
	if (!cameras || cameras.length === 0) {
		return [redPin, defaultSize];
	} else if (
		cameras.includes('P40') ||
		cameras.includes('P50') ||
		cameras.includes('P30')
	) {
		return [cameraIcons['P_Series'], imageSize];
	} else if (cameras.includes('RTC360')) {
		return [cameraIcons['RTC'], imageSize];
	} else if (cameras.includes('BLK360') || cameras.includes('BLK360 2')) {
		return [cameraIcons['BLK'], imageSize];
	} else if (
		cameras.includes('Matterport Pro 2') ||
		cameras.includes('Matterport Pro 3')
	) {
		return [cameraIcons['MatterPort'], imageSize];
	} else {
		return [redPin, defaultSize];
	}
};
