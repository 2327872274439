import { Button } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const ScopeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 5px;
`;

export const ScopeTitleContainer = styled.div`
	position: relative;
`;

export const ScopeTitle = styled(Button)`
	width: 100%;
	height: 30px;
	padding: 5px;
	position: relative;

	color: ${theme.palette.secondary.main};
	background-color: ${theme.palette.primary.main};

	border: solid 1px ${theme.palette.primary.main};
	border-radius: 5px;
	text-transform: capitalize;
	line-height: normal;

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: ${theme.palette.primary.main};
	}
`;

export const ArrowContainer = styled.div`
	display: flex;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: ${theme.palette.secondary.main};
`;
