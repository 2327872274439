import { useContext, useRef, useState } from 'react';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { ProjectData } from '../../../../types';
import { Button } from '@mui/material';
import { FieldLabel } from '../../../../screens/ProjectInfo';
import { getAnonUploadLink } from '../../../../utils';
import ProjectStatusField from './field-components/ProjectStatusField';
import InvoiceLinks from './field-components/InvoiceLinks';
import SubTotal from './field-components/SubTotal';
import { updateProjectPassword } from '../../../../firebase';
import InternalNotes from './field-components/InternalNotes';
import VRLink from './field-components/VRLink';
import TwoDLinks from './field-components/TwoDLinks';
import ThreeDLinks from './field-components/ThreeDLinks';
import PointCloudLinks from './field-components/PointCloudLinks';
import { AuthContext } from '../../../../context/AuthProvider';
import LinkWithCopyButton from '../../../reusable-components/LinkWithCopyButton';
import styled from 'styled-components';
import MultiStringField from './field-components/MultiStringField';
import TimestampField from './field-components/TimestampField';
import { LoadingContext } from '../../../../context/LoadingProvider';
import { updateProjectFields } from '../../../../firebase/firestore/projects';
import Costs from './field-components/Costs';
import DownloadLink from './field-components/DownloadLink';
import FileDndField from './field-components/FileDndField';
import TimeValueField from './field-components/TimeValueField';
import MultiLineStringField from './field-components/MultiLineStringField';
import MultiTimestampField from './field-components/MultiTimestampField';
import UserField from './field-components/UserField';
import { ProjectCategory, downloadCategories, uploadCategories } from '../utils';
import SingleLineStringField from './field-components/SingleLineStringField';
import { ProjectInfoTextField } from '../styled-components';
import SharedTeams from './field-components/SharedTeams';
import Equipment from './field-components/Equipment/Equipment';
import ProjectScoping from '../../../reusable-components/ProjectScoping';
// import AccountManager from './field-components/AccountManager';

export default function ProjectInfoCategory({
	category,
	fields,
	anonUploadPassword,
	setAnonUploadPassword,
	isComplete,
	icon,
	isVisible = true,
}: {
	category: ProjectCategory;
	fields: (keyof ProjectData)[];
	children?: React.ReactNode;
	defaultOpen?: boolean;
	anonUploadPassword?: string;
	setAnonUploadPassword?: React.Dispatch<React.SetStateAction<string>>;
	isComplete?: boolean;
	icon?: React.ReactNode;
	isVisible?: boolean;
}) {
	const { setLoadingStatus } = useContext(LoadingContext);
	const { user } = useContext(AuthContext);
	const { project, setProject } = useContext(ProjectContext);

	const [isActive, setIsActive] = useState(false);
	const [partialFormState, setPartialFormState] = useState<Partial<ProjectData>>({});

	const uploadPassRef = useRef<HTMLInputElement>();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPartialFormState(prev => ({
			...prev,
			[e.target.name]: value,
		}));
	};

	const handleSubmit = async () => {
		if (project) {
			if (uploadCategories.includes(category)) {
				const value = uploadPassRef.current?.value;
				if (value && value !== anonUploadPassword) {
					await updateProjectPassword({
						projectId: project.id,
						password: value,
					});
					if (setAnonUploadPassword) {
						setAnonUploadPassword(value);
					}
				}
			}

			const submitData = partialFormState;

			if (fields.includes('registrationManagers') && !submitData.registrationManagers) {
				submitData.registrationManagers = [];
			}
			if (fields.includes('qaQcManagers') && !submitData.qaQcManagers) {
				submitData.qaQcManagers = [];
			}

			setLoadingStatus({ title: 'Updating project fields...' });
			await updateProjectFields(project, submitData, setProject);
			setLoadingStatus(null);

			setIsActive(false);
		}
	};

	return (
		<>
			{!!project ? (
				<ProjectInfoCategoryBox $isComplete={isComplete} $isVisible={isVisible}>
					<div className="category-top-row">
						<div className="category-content">
							{downloadCategories.includes(category) ? (
								<DownloadLink project={project} />
							) : null}

							{fields.map(field => {
								switch (field) {
									// Project Status
									case 'projectStatus':
										return (
											<ProjectStatusField
												key={field}
												isActive={isActive}
												handleChange={handleChange}
											/>
										);

									// Single line string fields.
									case 'address':
										return (
											<SingleLineStringField
												key={field}
												field={field}
												handleChange={handleChange}
												isActive={isActive}
												showMap
											/>
										);
									case 'name':
									case 'buildingSize':
									case 'lastContacted':
									case 'storeNumber':
										return (
											<SingleLineStringField
												key={field}
												field={field}
												handleChange={handleChange}
												isActive={isActive}
											/>
										);

									// Multi-line string fields.
									case 'description':
									case 'registrationNotes':
									case 'modelingScope':
									case 'vrDeliveryTimeline':
									case 'photoReportTimeline':
										return (
											<MultiLineStringField
												key={field}
												field={field}
												handleChange={handleChange}
												isActive={isActive}
											/>
										);

									case 'invoiceLinks':
										return (
											<InvoiceLinks
												key={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// Accounts Payable
									case 'accountsPayable':
									case 'invoiceNums':
										return (
											<MultiStringField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
												adminOnly
											/>
										);

									// Sub-Total
									case 'subTotal':
										return (
											<SubTotal
												key={field}
												isActive={isActive}
												handleChange={handleChange}
											/>
										);

									case 'scanningCost':
									case 'registrationCost':
									case 'modelingCost':
										return (
											<Costs
												key={field}
												costType={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									case 'equipment':
										return (
											<Equipment
												key={field}
												isActive={isActive}
												partialFormState={partialFormState ?? { equipment: [] }}
												setPartialFormState={setPartialFormState}
												project={project}
											/>
										);

									// Timestamp fields.
									case 'registrationDueTimestamp':
									case 'modelingStartTimestamp':
									case 'modelingDueTimestamp':
									case 'deliveryTimestamp':
										return (
											<TimestampField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// Multi-Timestamp fields.
									case 'captureTimestamps':
										return (
											<MultiTimestampField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// User fields.
									case 'scanningTechnicians':
									case 'registrationManagers':
									case 'qaQcManagers':
									case 'bimTechnicians':
									case 'accountManager':
										return (
											<UserField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									case 'modelers':
									case 'designSoftware':
										return (
											<MultiStringField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
												adminOnly={field === 'modelers'}
											/>
										);

									// // Sales Account Manager
									// case 'accountManager':
									// 	return (
									// 		<AccountManager
									// 			key={field}
									// 			isActive={isActive}
									// 			handleChange={handleChange}
									// 			partialFormState={partialFormState ?? { accountManager: {} }}
									// 			project={project}
									// 			setPartialFormState={setPartialFormState}
									// 		/>
									// 	);

									// Internal Notes
									case 'internalNotes':
										return (
											<InternalNotes
												key={field}
												handleChange={handleChange}
												isActive={isActive}
											/>
										);

									// VR (matterport) Link
									case 'matterport':
										return (
											<VRLink
												key={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// 2D Links
									case 'twoDLinks':
										return (
											<TwoDLinks
												key={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// 3D Links
									case 'threeDLinks':
										return (
											<ThreeDLinks
												key={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									// Point Cloud Links
									case 'pointCloudLinks':
										return (
											<PointCloudLinks
												key={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);

									case 'proposal':
										return <FileDndField key={field} field={field} showDocusign />;
									case 'existingDocs':
										return <FileDndField key={field} field={field} />;

									case 'timeOnSite':
									case 'totalDuration':
										return (
											<TimeValueField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
											/>
										);
									case 'sku':
									case 'cpu':
										return (
											<MultiStringField
												key={field}
												field={field}
												isActive={isActive}
												setPartialFormState={setPartialFormState}
												freeSolo={false}
												adminOnly={field === 'cpu' ? true : false}
											/>
										);
									case 'sharedTeams':
										return (
											<SharedTeams
												key={field}
												isActive={isActive}
												partialFormState={partialFormState}
												setPartialFormState={setPartialFormState}
											/>
										);
									default:
										return null;
								}
							})}

							{category === 'projectScope' ? <ProjectScoping /> : null}

							{/* Upload link and password*/}
							{!!user?.isAdmin && uploadCategories.includes(category) ? (
								<>
									<div className="infoRow">
										<FieldLabel label={'Upload'} />
										<LinkWithCopyButton
											link={getAnonUploadLink(project) || ''}
											title={'Public upload'}
										/>
									</div>

									<div className="infoRow">
										<FieldLabel label={'Upload Link Password'} />
										{anonUploadPassword ? (
											isActive ? (
												<ProjectInfoTextField
													fullWidth
													defaultValue={anonUploadPassword}
													inputRef={uploadPassRef}
												/>
											) : (
												<div className="fieldInfo">
													<span className="blurred">{anonUploadPassword}</span>
												</div>
											)
										) : (
											<div className="fieldInfo">Loading...</div>
										)}
									</div>
								</>
							) : null}
						</div>

						{icon ? <div className="category-icon">{icon}</div> : null}
					</div>

					{user?.isAdmin ||
					(user?.isContractor && category === 'projectScope') ||
					(user?.isContractor && category === 'projectDetails') ? (
						<BottomButtonContainer>
							<BottomButton
								type="submit"
								variant="contained"
								$isComplete={isComplete}
								onClick={() => {
									setIsActive(prev => !prev);
									setPartialFormState({});
									// handleClose();
								}}>
								{!isActive ? 'Edit' : 'Cancel'}
							</BottomButton>

							{isActive ? (
								<BottomButton
									type="submit"
									variant="contained"
									$isComplete={isComplete}
									onClick={() => handleSubmit()}>
									Submit
								</BottomButton>
							) : null}
						</BottomButtonContainer>
					) : null}
				</ProjectInfoCategoryBox>
			) : null}
		</>
	);
}

type ProjectInfoCategoryProps = {
	$isComplete?: boolean;
	$isVisible?: boolean;
};

const ProjectInfoCategoryBox = styled.div<ProjectInfoCategoryProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 20px;
	height: fit-content;
	width: 100%;
	background-color: transparent;
	border: ${({ $isComplete, $isVisible }) =>
		$isVisible ? `1px solid ${$isComplete ? '#5BC236' : '#ffb310'}` : 'none'};
	padding: 20px 10px;
	transition: max-height 0.2 ease-out;

	.category-top-row {
		display: flex;
		align-items: start;
	}

	.category-content {
		flex: 1;
		width: 100%;
	}

	.category-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		margin-right: -10px;
		margin-top: -10px;
		margin-bottom: -10px;
	}
`;

const BottomButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 10px;
`;

const BottomButton = styled(Button)<ProjectInfoCategoryProps>`
	transition: 0.2s;
	line-height: normal;
	border-radius: 5px;
	text-transform: capitalize;
	padding: 8px 14px;

	background-color: transparent;
	border: 1px solid #ffb310;
	border-color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};
	color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};

	&:hover {
		border-color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};
		background-color: ${({ $isComplete }) => ($isComplete ? '#6f94619e' : '#c78400')};
	}
`;
