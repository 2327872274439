import { FC, useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { ReportData } from '../../../types/reports';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

type EditReportNameProps = {
	open: boolean;
	report: ReportData;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditReportNameDialog: FC<EditReportNameProps> = ({ open, report, setOpen }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [name, setName] = useState(report.name);
	const [saving, setSaving] = useState(false);

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		setName(e.target.value);

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (!name || !report.id) return;

		setSaving(true);
		try {
			await updateDoc(doc(db, 'reports', report.id), { name });
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully updated report name.`,
			});
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to update analytics name.`,
			});
		}
		setSaving(false);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Edit analytics name</DialogTitle>

			{!saving ? (
				<DialogContent>
					<TextField
						variant="standard"
						label="Name"
						name="name"
						value={name}
						onChange={onChange}
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Saving analytics..." textColor="black" size={50} />
				</DialogContent>
			)}

			<DialogActions>
				{!saving ? (
					<>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSubmit}>Save</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
};

export default EditReportNameDialog;
