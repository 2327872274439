import { sendEmail } from '../../firebase/auth';
import { AuthContext } from '../../context/AuthProvider';
import { ProjectContext } from '../../context/ProjectProvider';
import { bccMailingList } from '../../firebase/auth';
import { TextField, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { OrganizationsContext } from '../../context/OrganizationsProvider';
import { Organization, ProjectBase, UserObj } from '../../types';
import { projectStatusText, projectStatuses } from '../../utils';
import PlacesAutocompleteWrapper from './PlacesAutocomplete/PlacesAutocompleteWrapper';
import MultiAutoCompleteInput from './MultiAutoCompleteInput';
import OrgTeamSelector from './OrgTeamSelector';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useOrgTeams from '../screen-components/ProjectInfo/components/field-components/SharedTeams/hooks/useOrgTeams';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ProjectScoping from './ProjectScoping';
import { ProjectScopingData } from './ProjectScoping/types';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import styled from 'styled-components';

const emptyProject = (isClient = false, clients: string[] = []) => {
	const projectBase: ProjectBase = {
		projectStatus: !isClient ? 'Proposal Pending' : 'To Be Scheduled',
		clients: clients,
		address: '',
		buildingSize: '',
		sku: [],
		subTotal: '',
		name: '',
		cameras: [],

		designSoftware: [],
		modelers: [],
		registrationManagers: [],
		qaQcManagers: [],

		bimTechnicians: [],
		modelingScope: '',

		description: '',
		internalNotes: '',
		owner: '',
		lastContacted: '',
		signedProposal: false,

		matterport: [],
		twoDLinks: [],
		threeDLinks: [],
		pointCloudLinks: [],
		invoiceLinks: [],

		proposal: [],
		pointCloud: [],
		photos: [],
		pdf: [],
		autodesk: [],

		accountsPayable: [],
	};
	return projectBase;
};

/**
 * Functional component for displaying a dialog box to add a new project
 * @param showingDialog boolean useState that determines whether or not the dialog is shown
 * @param setShowingDialog useState updater for showingDialog
 */
export default function AddProjectForm({
	showingDialog,
	setShowingDialog,
	isClient = false,
	clients = [],
	accountManager,
	leadsAddition,
	orgRequired,
}: {
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isClient?: boolean;
	clients?: string[];
	accountManager?: UserObj;
	leadsAddition?: boolean;
	orgRequired?: boolean;
}) {
	const { handleAddProject, cachedClients } = useContext(ProjectContext);
	const { user } = useContext(AuthContext);
	const { organizations } = useContext(OrganizationsContext);
	const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
	const [formState, setFormState] = useState(emptyProject(isClient, clients));
	const [selectedTeams, addTeam, removeTeam] = useOrgTeams([]);
	const [projectScopes, setProjectScopes] = useState<ProjectScopingData | undefined>(
		undefined
	);

	const handleClose = () => {
		setFormState(emptyProject(isClient, clients));
		setShowingDialog(false);
	};

	const handleSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const teamIds: string[] = selectedTeams.map(team => team.id);

		const updatedFormState = {
			...formState,
			sharedTeams: teamIds,
		};

		const newProjectId = await handleAddProject(updatedFormState);

		if (projectScopes && user?.isAdmin) {
			const projectScopeDocRef = doc(
				db,
				`projects/${newProjectId}/private/projectScoping`
			);
			setDoc(projectScopeDocRef, projectScopes);
		}

		if (isClient && user) {
			sendEmail({
				destination: process.env.REACT_APP_firebase_info_email,
				cc: [],
				bcc: process.env.REACT_APP_firebase_env === 'prod' ? bccMailingList : [],
				subject: 'Client Has Created a New Project',
				text: `${user.fullName} (${user.email}) has created a project, ${
					formState.address || formState.name || 'Unnamed Project'
				}, with a status of "To Be Scheduled".`,
				html: `<div>
				${user.fullName} (${user.email}) has created a project, ${
					formState.address || formState.name || 'Unnamed Project'
				}, with a status of "To Be Scheduled".
				</div>`,
			});
		}

		handleClose();
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const value = e.target.value;
		setFormState({
			...formState,
			[e.target.name]: value,
		});
	};

	return (
		<Dialog open={showingDialog} onClose={handleClose} maxWidth="xs" fullWidth>
			<FormContainer onSubmit={handleSubmit}>
				<DialogTitle>Create A New Project</DialogTitle>
				<StyledDialogContent>
					<TextField
						select
						name="projectStatus"
						label="Status"
						InputLabelProps={{
							shrink: true,
						}}
						value={!isClient ? formState.projectStatus : 'To Be Scheduled'}
						disabled={isClient}
						onChange={handleChange}
						fullWidth
						variant="standard"
						required>
						{projectStatuses.map(status => (
							<MenuItem value={status} key={status}>
								{projectStatusText(status)}
							</MenuItem>
						))}
					</TextField>

					{user?.isAdmin ? (
						<OrgTeamSelector
							organizations={organizations}
							selectedOrg={selectedOrg}
							setSelectedOrg={setSelectedOrg}
							selectedTeams={selectedTeams}
							addTeam={addTeam}
							removeTeam={removeTeam}
						/>
					) : null}

					{leadsAddition &&
						(!isClient && user?.isAdmin ? (
							<MultiAutoCompleteInput
								label="Client(s)"
								name="clients"
								value={formState.clients}
								options={cachedClients ?? []}
								onChange={(e, newValue) => {
									setFormState(prev => ({
										...prev,
										clients: Array.from(newValue),
									}));
								}}
								onBlur={inputValue => {
									setFormState(prev => ({
										...prev,
										clients: [...prev.clients, inputValue],
									}));
								}}
								autoFocus={false}
								variant="standard"
								required
							/>
						) : null)}

					<PlacesAutocompleteWrapper
						formState={formState}
						setFormState={
							setFormState as React.Dispatch<React.SetStateAction<{ [key: string]: any }>>
						}
						addressField="address"
						required
						label={'Address / Project Name'}
					/>

					{!isClient && user?.isAdmin ? (
						<>
							<TextField
								label="BIM Notes"
								name="modelingScope"
								value={formState.modelingScope}
								onChange={handleChange}
								fullWidth
								multiline
								maxRows={5}
								variant="standard"
								required
							/>

							<ProjectScoping
								updateCallback={updatedData => {
									setProjectScopes(updatedData);
								}}
							/>
						</>
					) : null}
				</StyledDialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button type="submit" color="primary">
						Submit
					</Button>
				</DialogActions>
			</FormContainer>
		</Dialog>
	);
}

const FormContainer = styled.form`
	max-height: calc(100vh - 64px);
`;

const StyledDialogContent = styled(DialogContent)`
	max-height: calc(100% - 110px);
	overflow-y: auto;
`;
