import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	styled,
} from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useCallback, useContext, useEffect } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { ItemsToBeRemovedInterface } from '../../../screens/TeamPage';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import theme from '../../../styles/theme';

export default function RemoveProjectDialog({
	projectsToBeRemoved,
	setProjectsToBeRemoved,
	teamId,
}: {
	projectsToBeRemoved: ItemsToBeRemovedInterface[] | undefined;
	setProjectsToBeRemoved: React.Dispatch<
		React.SetStateAction<ItemsToBeRemovedInterface[] | undefined>
	>;
	teamId: string;
}) {
	const { removeProjectFromTeam, removingProjectFromTeam } =
		useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = useCallback(() => {
		setProjectsToBeRemoved(undefined);
	}, [setProjectsToBeRemoved]);

	const handleDelete = async () => {
		for (const projectToBeRemoved of projectsToBeRemoved || []) {
			if (projectToBeRemoved) {
				const result = await removeProjectFromTeam(projectToBeRemoved.id, teamId);
				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed projects from team!`,
					});
				}
			}
		}

		handleClose();
	};

	const handleRemoveProject = (project: ItemsToBeRemovedInterface) => {
		setProjectsToBeRemoved(projectsToBeRemoved?.filter(item => item.id !== project.id));
	};

	useEffect(() => {
		if (projectsToBeRemoved?.length === 0) {
			handleClose();
		}
	}, [handleClose, projectsToBeRemoved]);

	const projectWord = projectsToBeRemoved?.length === 1 ? 'project' : 'projects';

	return (
		<Dialog open={!!projectsToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove {projectWord} from team</DialogTitle>
			{removingProjectFromTeam ? (
				<DialogContent>
					<LoadingScreen message="Removing projects from the team..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<Text>
						Are you sure you want to remove the following {projectWord} from this team?
					</Text>

					<Container>
						{projectsToBeRemoved?.map((project, index) => (
							<ContainerContent key={index}>
								<IconRemove onClick={() => handleRemoveProject(project)} />
								<Address>{project.name}</Address>
							</ContainerContent>
						))}
					</Container>
				</DialogContent>
			)}
			{!removingProjectFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Remove {projectWord}</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}

const Text = styled('span')`
	color: #000
	font-size: 16px;
	font-weight: 400;
`;

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 20px 0 0 10px;
`;

const ContainerContent = styled('div')`
	display: flex;
	align-items: center;
	gap: 7px;
`;

const Address = styled('span')`
	color: #595959;
	font-size: 14px;
	font-weight: 400;
`;

const IconRemove = styled(RemoveCircleOutlineIcon)`
	color: ${theme.palette.primary.main};
	width: 15px;
	height: 15px;
	cursor: pointer;
`;
