import { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';

type Props = {
	style?: React.CSSProperties;
};

export const TermsAndPrivacy: FC<Props> = ({ style }: Props) => {
	return (
		<Terms style={style}>
			By continuing, you agree to our{' '}
			<ClickableText
				onClick={() =>
					window.open('https://www.roboticimaging.com/terms-and-conditions', '_blank')
				}>
				Terms of Service
			</ClickableText>{' '}
			and acknowledge our{' '}
			<ClickableText
				onClick={() =>
					window.open('https://www.roboticimaging.com/privacy-policy', '_blank')
				}>
				Privacy Notice
			</ClickableText>
			.
		</Terms>
	);
};

const Terms = styled.span`
	color: #ffb310;
	background-color: ${theme.palette.secondary.main};
	padding: 20px 40px;
	border-radius: 10px;
	text-align: center;
`;

const ClickableText = styled.span`
	cursor: pointer;
	font-weight: bold;
	color: #ffb310;
	&:hover {
		text-decoration: underline;
	}
`;
