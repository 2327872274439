import { db } from '../../firebase';
import { AuthContext } from '../../context/AuthProvider';
import { Organization, Team } from '../../types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Chip, MenuItem, TextField, Typography } from '@mui/material';
import GlowLoader from './GlowLoader/GlowLoader';
import styled from 'styled-components';

type OrgTeamSelectorProps = {
	organizations: Organization[];
	selectedOrg: Organization | null;
	setSelectedOrg: (org: Organization | null) => void;
	selectedTeams: Team[];
	addTeam: (teamId: string) => void;
	removeTeam: (teamId: string) => void;
	orgRequired?: boolean;
};

export default function OrgTeamSelector({
	organizations,
	selectedOrg,
	setSelectedOrg,
	selectedTeams,
	addTeam,
	removeTeam,
}: OrgTeamSelectorProps) {
	const { user, firebaseAuthData } = useContext(AuthContext);
	const [teams, setTeams] = useState<Team[]>([]);
	const [loading, setLoading] = useState(false);
	const orgRef = useRef(selectedOrg);

	useEffect(() => {
		orgRef.current = selectedOrg;

		if (selectedOrg) {
			const fetchTeams = async () => {
				setLoading(true);
				const collRef = collection(db, 'teams');
				let q;

				if (user?.isAdmin || (user?.orgOwnerIds && user.orgOwnerIds.length > 0)) {
					q = query(collRef, where('organizationId', '==', selectedOrg.id));
				} else {
					q = query(
						collRef,
						where('organizationId', '==', selectedOrg.id),
						where('userIds', 'array-contains', firebaseAuthData?.uid)
					);
				}
				const snap = await getDocs(q);

				if (selectedOrg === orgRef.current) {
					const fetchedTeams = snap.docs.map(
						fetchedDoc =>
							({
								...fetchedDoc.data(),
								id: fetchedDoc.id,
							} as Team)
					);
					setTeams(fetchedTeams);
				}
				setLoading(false);
			};
			fetchTeams();
		}
	}, [selectedOrg, user, firebaseAuthData]);

	const handleTeamChange = (teamId: string) => {
		addTeam(teamId);
	};

	const handleTeamDelete = (teamId: string) => {
		removeTeam(teamId);
	};

	// filter to hide already selectedTeams from TeamsTextField Menu Items
	const filteredTeams = teams.filter(
		team => !selectedTeams.some(selected => selected.id === team.id)
	);

	return (
		<>
			<Autocomplete
				options={organizations}
				getOptionLabel={option => option.name}
				renderInput={params => (
					<TextField {...params} label="Organization" variant="standard" />
				)}
				value={selectedOrg}
				onChange={(event, value) => setSelectedOrg(value)}
				fullWidth
			/>

			<TextField
				select
				label="Team(s)"
				value=""
				fullWidth
				variant="standard"
				disabled={!selectedOrg}>
				{loading ? (
					<MenuItem disabled>
						<GlowLoader size={24} />
						Loading teams...
					</MenuItem>
				) : filteredTeams.length ? (
					filteredTeams.map(team => (
						<MenuItem
							value={team.id}
							key={team.id}
							onClick={() => handleTeamChange(team.id)}>
							{team.name}
						</MenuItem>
					))
				) : (
					<MenuItem value="" disabled>
						This organization has 0 teams
					</MenuItem>
				)}
			</TextField>
			<ChipContainer>
				<ExistingHeader>Selected Team(s):</ExistingHeader>
				{selectedTeams.map(team => {
					const org = organizations.find(org => org.teamIds?.includes(team.id));
					return (
						<SelectedChip
							key={team.id}
							label={
								<ChipBox>
									<Typography variant="caption" color="textSecondary">
										{org?.name}
									</Typography>
									<Typography variant="body2">{team.name}</Typography>
								</ChipBox>
							}
							onDelete={() => handleTeamDelete(team.id)}
						/>
					);
				})}
			</ChipContainer>
		</>
	);
}

const ChipContainer = styled(Box)`
	display: flex;
	overflow-y: auto;
	min-height: 200px;
	max-height: 300px;
	background-color: #c8c8c8;
	padding: 5px;
	justify-content: flex-start;
	flex-direction: column;
	gap: 2px;
`;

const ExistingHeader = styled.span`
	font-size: 15px;
	text-align: center;
`;

const SelectedChip = styled(Chip)`
	width: 100%;
	min-height: 50px;
	justify-content: space-between;
`;

const ChipBox = styled(Box)`
	display: flex;
	flex-direction: column;
`;
