import { useContext } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import StreamSaver from 'streamsaver';
import { ZipFile } from '../../types';
import {
	FormNode,
	PhotoV2,
} from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { db } from '../../firebase';
import { SnackContext } from '../../context/SnackProvider';
import Zip from './Zip';

const useDownloadZippedFiles = () => {
	const { setSnackbarProps } = useContext(SnackContext);

	const downloadZippedFiles = async (
		files: ZipFile[],
		zipFileName: string,
		onUpdate?: (message: string) => void
	) => {
		if (!files || files.length === 0) return;
		try {
			const zip = new Zip(true);
			var processed: ZipFile[] = [];

			// Check if there are repeated filenames and add numbers to them
			const uniqueFiles = files.reduce<ZipFile[]>((acc, file) => {
				if (!acc.find(name => name.name === file.name)) {
					acc.push(file);
					return acc;
				}

				const name = file.name.split('.');
				const extension = name.pop();
				const fileName = name.join('.');

				var count = 1;
				var exists = true;

				while (exists) {
					count++;
					const newFilename = fileName + ' (' + count + ').' + extension;
					exists = acc.some(name => name.name === newFilename);
					if (!exists) acc.push({ name: newFilename, url: file.url, order: file.order });
				}

				return acc;
			}, []);

			// Loop through the files and add them to the zip
			for (const file of uniqueFiles) {
				let receivedLength = 0;
				zip.startFile(file.name);
				let totalSize = 0;

				// Append all the downloaded data
				try {
					await new Promise((resolve, reject) => {
						fetch(file.url)
							.then(response => {
								totalSize = parseInt(response.headers.get('content-length') || '0');
								return response.body;
							})
							.then(async stream => {
								processed.push(file);

								if (!stream) {
									reject('Failed to fetch the file');
									return;
								}

								const reader = stream.getReader();
								let doneReading = false;

								while (!doneReading) {
									const chunk = await reader.read();
									const { done, value } = chunk;

									receivedLength += value?.length || 0;

									const received = Math.round(receivedLength / 1024 / 1024);
									const total = Math.round(totalSize / 1024 / 1024);
									const percentage = Math.round((receivedLength / totalSize) * 100);

									onUpdate?.(
										`${processed.length}/${files.length} - ${file.name} - ${received} MB / ${total} MB (${percentage}%)`
									);

									if (done) {
										// If this stream has finished, resolve and return
										resolve(true);
										doneReading = true;
									} else {
										// If not, append data to the zip
										zip.appendData(value);
									}
								}
							})
							.catch(err => {
								reject(err);
							});
					});

					zip.endFile();
				} catch (e) {
					console.error(`Error while piping data into zip: ${e}`);
				}
			}

			zip.finish();

			const reader = zip.outputStream.getReader();
			const fileStream = StreamSaver.createWriteStream(zipFileName);
			const writer = fileStream.getWriter();

			onUpdate?.('Saving...');

			const pump = (): any =>
				reader.read().then(({ value, done }) => {
					if (done) writer.close();
					else {
						writer.write(value);
						return writer.ready.then(pump);
					}
				});

			await pump()
				.then(() => console.log('Closed the stream, Done writing'))
				.catch((err: any) => console.log(err));
		} catch (error) {
			setSnackbarProps({
				open: true,
				message: 'Error downloading files - result zip file is too big',
				severity: 'error',
			});
		}
	};

	const buildPhotosListFromForm = async (
		formId: string,
		projectId: string
	): Promise<ZipFile[]> => {
		const photoQuery = query(
			collection(db, 'utility_forms_v2_items'),
			where('formId', '==', formId),
			where('projectId', '==', projectId),
			where('type', '==', 'photos')
		);

		const nodesCollection = await getDocs(photoQuery);
		const nodes = nodesCollection.docs
			.map(doc => doc.data() as FormNode)
			.filter(node => node.value && (node.value as PhotoV2[]).length > 0);

		const parentsTitlePromises = nodes.map(async node => {
			const parentsTitle = await getParentsTitle({ node });
			return { ...node, parentsTitle };
		});

		const nodesWithParentsTitle = await Promise.all(parentsTitlePromises);

		const photos: ZipFile[] = [];
		nodesWithParentsTitle.forEach(node => {
			if (node.type === 'photos') {
				const photosList = node.value as PhotoV2[];
				const files = photosList.map((photo, index) => ({
					name: !!node.parentsTitle
						? node.parentsTitle?.split(' > ').map(safeFileName).join('/') +
						  '/' +
						  addZeros(node.order || 0) +
						  ' ' +
						  safeFileName(node.displayTitle) +
						  '/' +
						  addZeros(index + 1) +
						  ' ' +
						  photo.originalName
						: addZeros(node.order || 0) +
						  ' ' +
						  safeFileName(node.displayTitle) +
						  '/' +
						  addZeros(index + 1) +
						  ' ' +
						  photo.originalName,
					url: photo.url!,
					order: index,
				}));
				photos.push(...files);
			}
		});
		console.log('photos', photos);
		return photos;
	};

	const getParentsTitle = async ({ node }: { node: FormNode }): Promise<string> => {
		const parents = [];
		let currentParentId = node.parentId;

		while (currentParentId) {
			const parentNode = await getDoc(doc(db, 'utility_forms_v2_items', currentParentId));
			const parent = parentNode.data() as FormNode;
			parents.push(`${addZeros(parent.order || 0)}||${parent.displayTitle}`);
			parents.push(parent.displayTitle);
			currentParentId = parent.parentId;
		}

		return parents.reverse().join(' > ');
	};

	const safeFileName = (text: string) => text.replace(/[^ a-z0-9()-+=.]/gi, '-');
	const addZeros = (num: number) => num.toString().padStart(4, '0');

	return { downloadZippedFiles, buildPhotosListFromForm, safeFileName };
};

export default useDownloadZippedFiles;
