import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	styled,
} from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useCallback, useContext, useEffect } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import theme from '../../../styles/theme';
import { ItemsToBeRemovedInterface } from '../../../screens/TeamPage';

export default function RemoveUserDialog({
	usersToBeRemoved,
	setUsersToBeRemoved,
	teamId,
}: {
	usersToBeRemoved: ItemsToBeRemovedInterface[] | undefined;
	setUsersToBeRemoved: React.Dispatch<
		React.SetStateAction<ItemsToBeRemovedInterface[] | undefined>
	>;
	teamId: string;
}) {
	const { removeUserFromTeam, removingUserFromTeam } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = useCallback(() => {
		setUsersToBeRemoved(undefined);
	}, [setUsersToBeRemoved]);

	const handleDelete = async () => {
		for (const userToBeRemoved of usersToBeRemoved || []) {
			if (userToBeRemoved) {
				const result = await removeUserFromTeam(userToBeRemoved.id, teamId);
				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed the user from team!`,
					});
				}
			}
		}
		handleClose();
	};

	const handleRemoveUser = (user: ItemsToBeRemovedInterface) => {
		setUsersToBeRemoved(usersToBeRemoved?.filter(item => item.id !== user.id));
	};

	useEffect(() => {
		if (usersToBeRemoved?.length === 0) {
			handleClose();
		}
	}, [handleClose, usersToBeRemoved]);

	const userWord = usersToBeRemoved?.length === 1 ? 'user' : 'users';

	return (
		<Dialog open={!!usersToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove {userWord} from team</DialogTitle>
			{removingUserFromTeam ? (
				<DialogContent>
					<LoadingScreen message="Removing users from the team..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<Text>
						Are you sure you want to remove the following {userWord} from this team?
					</Text>

					<Container>
						{usersToBeRemoved?.map((user, index) => (
							<ContainerContent key={index}>
								<IconRemove onClick={() => handleRemoveUser(user)} />
								<Address>{user.name}</Address>
							</ContainerContent>
						))}
					</Container>
				</DialogContent>
			)}
			{!removingUserFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Remove {userWord}</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}

const Text = styled('span')`
	color: #000
	font-size: 16px;
	font-weight: 400;
`;

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 20px 0 0 10px;
`;

const ContainerContent = styled('div')`
	display: flex;
	align-items: center;
	gap: 7px;
`;

const Address = styled('span')`
	color: #595959;
	font-size: 14px;
	font-weight: 400;
`;

const IconRemove = styled(RemoveCircleOutlineIcon)`
	color: ${theme.palette.primary.main};
	width: 15px;
	height: 15px;
	cursor: pointer;
`;
