import { MenuItem, Select } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { FieldLabel } from '../../../../screens/ProjectInfo';
import styled from 'styled-components';
import theme from '../../../../styles/theme';
import { AccountManager } from '../../../../types';

interface AccountManagerProps {
	isActive: boolean;
	value?: AccountManager;
	handleChange: (value: AccountManager) => void;
}

const AccountManagerSelect = ({ isActive, value, handleChange }: AccountManagerProps) => {
	const { projectRoleOptions, user } = useContext(AuthContext);

	const listAccountManager = () => {
		const list = projectRoleOptions['accountManager'];

		list.sort((a, b) => {
			if (a.fullName < b.fullName) {
				return -1;
			}
			if (a.fullName > b.fullName) {
				return 1;
			}
			return 0;
		});

		return list.map(value => {
			return {
				id: value.id,
				fullName: value.fullName,
				email: value.email,
			} as AccountManager;
		});
	};

	return user?.isAdmin ? (
		<div className="infoRow">
			<FieldLabel label="Account Manager" />
			{isActive ? (
				<SelectStyled
					id="accountManager"
					sx={{
						'.MuiSelect-icon': {
							color: theme.palette.primary.main,
						},
					}}
					value={value?.id || ''}
					onChange={e => {
						const userSelected = listAccountManager().find(
							user => user.id === e.target.value
						) as AccountManager;

						handleChange(userSelected);
					}}>
					<MenuItem value={''}>None</MenuItem>
					{listAccountManager().map(item => (
						<MenuItem value={item.id} key={item.id}>
							{item.fullName}
						</MenuItem>
					))}
				</SelectStyled>
			) : (
				<span className="fieldInfo">{value?.fullName}</span>
			)}
		</div>
	) : null;
};

export default AccountManagerSelect;

const SelectStyled = styled(Select)`
	border: 1px solid ${theme.palette.primary.main};
	max-width: 100%;
	min-width: 110px;
	height: 43px;
	color: ${theme.palette.primary.main};
	padding: 0;
`;
