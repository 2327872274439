import { useState, createContext, useContext, useEffect } from 'react';
import { SnackContext } from './SnackProvider';

import { loginWithEmail } from '../firebase/auth';
import { auth, createUser, db, getUsers } from '../firebase/index';
import {
	GoogleAuthProvider,
	OAuthProvider,
	User,
	getAuth,
	onAuthStateChanged,
	sendPasswordResetEmail,
	// signInWithCredential,
	signInWithPopup,
	signOut,
} from 'firebase/auth';
import { BaseRegisterValues, ContractorRegisterValues } from '../types/auth';
import { UserWithData } from '../types';
import {
	ProjectRole,
	projectRoleTitleMap,
	projectRoleToUserTagMap,
} from '../types/project-roles';
import { getKeys } from '../utils/typescript-utils';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

type AuthContextType = {
	user: UserWithData | null;
	setUser: React.Dispatch<React.SetStateAction<UserWithData | null>>;
	firebaseAuthData: User | null;

	fetchingUsers: boolean;
	usersWithData: UserWithData[];
	setUsersWithData: React.Dispatch<React.SetStateAction<UserWithData[]>>;

	projectRoleOptions: Record<ProjectRole, UserWithData[]>;

	handleOnLogin: (values: { email: string; password: string }) => Promise<void>;
	loginError: string | null;
	setLoginError: React.Dispatch<React.SetStateAction<string | null>>;
	handleOnLogout: () => void;
	handleOnRegister: (
		values: BaseRegisterValues | ContractorRegisterValues,
		isContractor: boolean
	) => Promise<void>;
	resetPassword: (emailAddress: string) => void;
	handleSocialRegister: (provider: OAuthProvider | GoogleAuthProvider) => void;
	handleSocialLogin: (provider: OAuthProvider | GoogleAuthProvider) => void;
	loadingUser: boolean;
	authLoadingText: string;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [user, setUser] = useState<UserWithData | null>(null);
	const [firebaseAuthData, setFirebaseAuthData] = useState<User | null>(null);

	const [fetchingUsers, setFetchingUsers] = useState(false);
	const [usersWithData, setUsersWithData] = useState<UserWithData[]>([]);

	const [loginError, setLoginError] = useState<string | null>(null);
	const [loadingUser, setLoadingUser] = useState(false);
	const [authLoadingText, setAuthLoadingText] = useState('');

	const handleOnLogout = () => {
		localStorage.clear();
		setUser(null);
		setLoginError(null);
		signOut(auth);
	};

	const handleOnLogin = async (values: { email: string; password: string }) => {
		setLoadingUser(true);
		setAuthLoadingText('Logging in...');
		const { email, password } = values;
		try {
			await loginWithEmail(email, password);
		} catch (e: any) {
			setLoginError(() => {
				if (e?.message === 'Firebase: Error (auth/user-not-found).')
					return 'Invalid Email';
				else if (e?.message === 'Firebase: Error (auth/wrong-password).')
					return 'Invalid Password';
				else if (e?.message === 'Firebase: Error (auth/user-disabled).')
					return 'Account has been disabled. Please contact the Robotic Imaging team to reactivate your account.';
				else {
					console.log(e?.message);
					return 'Login Error';
				}
			});
			setAuthLoadingText('');
			setLoadingUser(false);
		}
	};

	const handleOnRegister = (
		values: BaseRegisterValues | ContractorRegisterValues,
		isContractor: boolean
	) => {
		setLoadingUser(true);
		setAuthLoadingText('Creating account...');

		return new Promise<void>((resolve, reject) => {
			createUser({
				...values,
				isContractor,
			})
				.then(async () => {
					await handleOnLogin(values);
					resolve();
				})
				.catch(err => {
					console.error(err);
					setAuthLoadingText('');
					setLoadingUser(false);
					reject(err);
				});
		});
	};

	const resetPassword = (emailAddress: string) => {
		sendPasswordResetEmail(auth, emailAddress)
			.then(function () {
				// Email sent.;
				setSnackbarProps({
					open: true,
					message: 'Email has been sent to reset password',
					severity: 'success',
				});
			})
			.catch(function (error) {
				// An error happened.
				setSnackbarProps({
					open: true,
					message: 'Email is not associated with an account',
					severity: 'error',
				});
			});
	};

	const handleSocialRegister = async (provider: OAuthProvider | GoogleAuthProvider) => {
		const auth = getAuth();

		setLoadingUser(true);
		setAuthLoadingText('Creating account...');

		try {
			const result = await signInWithPopup(auth, provider);
			const user = result.user;

			if (!user.email || !user.displayName) {
				throw new Error("Can't get email or displayName");
			}

			const data = {
				email: user.email,
				fullName: user.displayName,
			};

			await createUser(data);
		} catch (e: any) {
			setLoginError(e.message);
			setLoadingUser(false);
		}
	};

	const handleSocialLogin = async (provider: OAuthProvider | GoogleAuthProvider) => {
		const auth = getAuth();
		setLoadingUser(true);
		setAuthLoadingText('Logging in...');
		const result = await signInWithPopup(auth, provider);
		const userUid = result.user.uid;

		const userDoc = await getDoc(doc(db, `users/${userUid}`));

		if (!userDoc.exists()) {
			handleOnLogout();
			setLoginError('User does not exist');
			setLoadingUser(false);
			return;
		}
	};

	useEffect(() => {
		let userDocUnsub = () => {};
		const authStateUnsub = onAuthStateChanged(auth, async function (authData) {
			setFirebaseAuthData(authData);
			if (authData && !authData.isAnonymous) {
				setLoadingUser(true);
				setAuthLoadingText('Fetching user data...');

				// Listen to logged in user's user doc so that if the user doc is deleted
				// or disabled, the web app can immediately respond.
				userDocUnsub = onSnapshot(
					doc(db, `users/${authData.uid}`),
					docSnap => {
						const userDocData = docSnap.data() as UserWithData | undefined;
						if (user && (!userDocData || userDocData?.disabled)) {
							// If the user doc doesn't exist or is disabled, logout.
							handleOnLogout();
							setSnackbarProps({
								open: true,
								severity: 'error',
								message: `Your account has been ${
									!userDocData ? 'deleted' : 'disabled'
								}.`,
							});
						}

						// If the user doc exists and is not disabled, update the 'user' state.
						if (userDocData && !userDocData.disabled) {
							setUser({ ...userDocData, id: docSnap.id });
							setAuthLoadingText('');
							setLoadingUser(false);
						}
					},
					error => {
						console.error(error);
						setAuthLoadingText('');
						setLoadingUser(false);
					}
				);
			} else if (!authData) {
				userDocUnsub();
			}
		});

		return () => {
			authStateUnsub();
			userDocUnsub();
		};
	}, [setSnackbarProps]);

	useEffect(() => {
		if (user?.isAdmin) {
			// Get list of users.
			setFetchingUsers(true);
			getUsers().then(res => {
				setUsersWithData(res.data as UserWithData[]);
				setFetchingUsers(false);
			});
		}
	}, [user?.isAdmin]);

	const projectRoleOptions = getKeys(projectRoleTitleMap).reduce((acc, projectRole) => {
		acc[projectRole] = usersWithData.filter(
			userWithData => userWithData.userTags?.[projectRoleToUserTagMap[projectRole]]
		);
		return acc;
	}, {} as Record<ProjectRole, UserWithData[]>);

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				firebaseAuthData,

				fetchingUsers,
				usersWithData,
				setUsersWithData,

				projectRoleOptions,

				handleOnLogin,
				loginError,
				setLoginError,
				handleOnLogout,
				handleOnRegister,
				resetPassword,
				handleSocialRegister,
				handleSocialLogin,
				loadingUser,
				authLoadingText,
			}}>
			{children}
		</AuthContext.Provider>
	);
};
