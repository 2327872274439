import { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ProjectContext } from '../../../context/ProjectProvider';
import ProposalBox from './boxes/ProposalBox';
import MatterportBox from './boxes/MatterportBox';
import PointCloudBox from './boxes/PointCloudBox';
import PhotosBox from './boxes/PhotosBox';
import PdfBox from './boxes/PdfBox';
import AutodeskBox from './boxes/AutodeskBox';
import ExistingBox from './boxes/ExistingBox';

export default function ClientDeliverableFiles({
	downloadOnly = false,
	anonUpload = false,
	hasFiles = true,
}: {
	downloadOnly?: boolean;
	anonUpload?: boolean;
	hasFiles?: boolean;
}) {
	const { project } = useContext(ProjectContext);

	useEffect(() => {
		const prevent = (e: DragEvent) => {
			e.preventDefault();
		};
		window.addEventListener('dragover', prevent, false);
		window.addEventListener('drop', prevent, false);

		return () => {
			window.removeEventListener('dragover', prevent);
			window.removeEventListener('drop', prevent);
		};
	}, []);

	return (
		<>
			{!hasFiles && (
				<p style={{ color: 'white', textAlign: 'center', paddingTop: 20 }}>
					No files have been uploaded yet.{' '}
				</p>
			)}

			{downloadOnly && project?.projectStatus === 'Proposal Sent' ? (
				<ProposalBox />
			) : (
				<DeliverableFileListContainer>
					{project?.existingDocs && project.existingDocs.length > 0 && (
						<ExistingBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					)}
					{project?.photos && project.photos.length > 0 && (
						<PhotosBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					)}
					{project?.matterport && project.matterport.length > 0 && (
						<MatterportBox downloadOnly={downloadOnly} />
					)}
					{project?.pointCloud && project.pointCloud.length > 0 && (
						<PointCloudBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					)}
					{project?.pdf && project.pdf.length > 0 && (
						<PdfBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					)}
					{project?.autodesk && project?.autodesk.length > 0 && (
						<AutodeskBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					)}
				</DeliverableFileListContainer>
			)}
		</>
	);
}

const DeliverableFileListContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	overflow: visible;
	height: calc(100vh - 300px);
`;
