import { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SnackContext } from '../../../../context/SnackProvider';
import { AuthContext } from '../../../../context/AuthProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { FormV2Context } from '../context/FormV2Context.types';
import Layout from '../../../reusable-components/Layout';
import { LayoutLink } from '../../../styled-components/links';
import { YellowIconButton } from '../../../styled-components/buttons';
import { ReactComponent as Forms } from '../../../../assets/projectInfoIcons/Forms.svg';
import CollectionsIcon from '@mui/icons-material/Collections';
import EditIcon from '@mui/icons-material/Edit';
import LoadingScreen from '../../../reusable-components/LoadingScreen';
import ProcessingForm from '../../ProjectUtilityForm/screens/ProcessingForm';
import styled from 'styled-components';
import { db } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import useArchivedListener from '../hooks/useArchivedListener';
import Form from '../../Form';
import FormFillV2 from '../components/FormContainer/components/FormFill/FormFillV2';

export default function UtilityFormV2Renderer() {
	const { formId, projectId }: any = useParams();
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const isV3 = window.location.href.includes('capture-form-v3');

	useArchivedListener();

	const { utilityForm, isSubmitting, setIsSubmitting, isLoading } =
		useContext(FormV2Context);
	const formDocRef = doc(db, `utility_forms_v2/${formId}`);

	useEffect(() => {
		if (utilityForm?.status === 'completed') {
			setIsSubmitting(false);
		}
	}, [utilityForm?.status, setIsSubmitting]);

	const projectTitle = project
		? project.address || project.name || 'Unnamed Project'
		: 'Loading Project...';

	const editForm = async () => {
		setSnackbarProps({
			open: true,
			message: `Setting ${utilityForm?.formName} back to edit mode...`,
			severity: 'warning',
			hideDuration: null,
		});
		if (utilityForm) {
			await updateDoc(formDocRef, { status: 'draft' });
		}
		setSnackbarProps({
			open: true,
			message: `You can now edit ${utilityForm?.formName}!`,
			severity: 'success',
		});
	};

	return (
		<Layout
			LeftNavComponent={
				<>
					{project ? (
						<>
							<LayoutLink
								to={`/projects/${project.id}`}
								title="Navigate to project details page">
								<h1>{projectTitle}</h1>
							</LayoutLink>

							<Link
								to={`/projects/${project.id}/capture-form-v2`}
								title="Navigate to report list">
								<YellowIconButton size="large" sx={{ padding: '2.5px' }}>
									<FormsIcon />
								</YellowIconButton>
							</Link>

							<Link
								to={`/capture-form-gallery-v2/${formId}`}
								title="Navigate to this report's gallery">
								<YellowIconButton size="large">
									<CollectionsIcon />
								</YellowIconButton>
							</Link>
						</>
					) : null}
				</>
			}
			RightNavComponent={
				utilityForm?.downloadUrlVirtual &&
				utilityForm.status === 'completed' &&
				user?.isAdmin ? (
					<YellowIconButton title="Edit form" size="large" onClick={() => editForm()}>
						<EditIcon />
					</YellowIconButton>
				) : undefined
			}>
			{isLoading ? (
				<LoadingScreen message="Loading Report..." />
			) : utilityForm?.status === 'uploaded' || utilityForm?.status === 'processing' ? (
				<ProcessingForm />
			) : isSubmitting ? (
				<LoadingScreen message="Submitting Report..." />
			) : utilityForm?.status === 'processing failed' ? (
				<UtilityFormLoadingContainer>
					<h3>Failed to generate PDF!</h3>
					<h4>Please contact a member of the Robotic Imaging team for assistance.</h4>
				</UtilityFormLoadingContainer>
			) : isV3 ? (
				<Form formId={formId} projectId={projectId} />
			) : (
				<FormFillV2 />
			)}
		</Layout>
	);
}

const FormsIcon = styled(Forms)`
	margin-left: 5px;
	width: 24px;
`;

export const UtilityFormLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;

	color: #ffb310;
	padding: 50px;
	height: 100%;
	width: 100%;

	> * {
		margin: 0;
	}
`;
