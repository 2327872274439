import { Column, CellProps } from 'react-table';
import { PendingUserObj } from '../../../../screens/TeamPage';
import { UserObj } from '../../../../types';
import {
	getStatusBackgroundColor,
	getStatusColor,
	StatusChip,
} from '../../../reusable-components/GroupedStatusTable/components/columns';

export const columns: Column<UserObj | PendingUserObj>[] = [
	{
		Header: 'Status',
		id: 'status',
		Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => (
			<StatusChip
				label={'name' in row.original ? 'Active' : 'Pending'}
				$bgColor={getStatusBackgroundColor(
					'name' in row.original ? 'Delivered' : 'To Be Scheduled'
				)}
				$borderColor={getStatusColor(
					'name' in row.original ? 'Delivered' : 'To Be Scheduled'
				)}
			/>
		),
		accessor: (user: UserObj | PendingUserObj) => {
			return 'name' in user ? 'Active' : 'Pending';
		},
		width: 100,
	},
	{
		Header: 'Name',
		id: 'name',
		Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => (
			<span>{'name' in row.original ? row.original.name : '-'}</span>
		),
		accessor: (user: UserObj | PendingUserObj) => ('name' in user ? user.name : ''),
	},
	{
		Header: 'Email',
		id: 'email',
		Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => (
			<span>{row.original.email || '-'}</span>
		),
		accessor: (user: UserObj | PendingUserObj) => user.email,
		width: 230,
	},
];
