import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	styled,
} from '@mui/material';
import { FC } from 'react';

type AddFormDialogProps = {
	handleClose: () => void;
	handleScratch: () => void;
	handleTemplate: () => void;
	open: boolean;
};

const AddFormDialog: FC<React.PropsWithChildren<AddFormDialogProps>> = ({
	handleClose,
	handleScratch,
	handleTemplate,
	open,
}) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Add report</DialogTitle>
			<DialogContent style={{ alignItems: 'center' }}>
				<GrayButton color="primary" onClick={handleScratch}>
					Create a new report from scratch
				</GrayButton>
				<Box>OR</Box>
				<GrayButton color="primary" onClick={handleTemplate}>
					Use a saved template
				</GrayButton>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

const GrayButton = styled(Button)`
	background-color: #17171b;
	width: 90%;
	max-width: 500px;
	color: white;
	padding: 20px 50px;
	white-space: nowrap;
	border-radius: 20px;
	margin: 10px 0;

	box-shadow: 1px 3px 4px rgba(89, 89, 89, 0.4);

	&:hover {
		color: #ffb310;
		background-color: #17171b;
	}

	@media screen and (max-width: 424px) {
		padding: 20px 20px;
		white-space: normal;
	}
`;

export default AddFormDialog;
