import { ContainerBorder } from './ProjectsList/components/ClientProjects/styles';
import {
	ProjectCategory,
	categoryFields,
	validateCategory,
} from '../components/screen-components/ProjectInfo/utils';
import {
	// getCompletedDocusign,
	getCompletedForms,
	getProject,
	getProjectPassword,
	getSharedUsers,
} from '../firebase';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Fab, Tab, Tabs } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { useWindowSize } from './Welcome';
import { ProjectContext } from '../context/ProjectProvider';
import { LoadingContext } from '../context/LoadingProvider';
import { formatProjectTimestamps, useNav } from '../utils';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import CompletionIconButton from '../components/screen-components/ProjectInfo/components/CompletionIconButton';
import ProjectInfoCategory from '../components/screen-components/ProjectInfo/components/ProjectInfoCategory';
import DeliverableFiles from '../components/reusable-components/DeliverableFiles';
import RightNavButtons from '../components/screen-components/ProjectInfo/components/RightNavButtons';
import SectionHeader from './ProjectsList/components/ClientProjects/components/SectionHeader';
import LoadingLayer from '../components/reusable-components/LoadingLayer';
import V2FormsList from './V2FormsList';
import NewTracker from '../components/reusable-components/ProjectTracker/NewTracker';
import styled from 'styled-components';
import Layout from '../components/reusable-components/Layout';
import Paper from '@mui/material/Paper';
import '../styles/ProjectInfo.css';
import {
	PendingUser,
	SharedUser,
} from '../components/screen-components/ProjectInfo/components/RightNavButtons/types';
import { ProjectInfoDropdown } from '../components/screen-components/ProjectInfo/components/ProjectInfoDropdown';
import ClientUploadBoxContent from '../components/reusable-components/DeliverableFiles/components/ClientUploadBox/ClientUploadBoxContent';
import ProjectInfoMap from '../components/screen-components/ProjectInfo/components/ProjectInfoMap';
import { Add } from '@mui/icons-material';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { MdiIcon } from '../components/reusable-components/MdiIcon';
import theme from '../styles/theme';
import ClientDeliverableFiles from '../components/reusable-components/DeliverableFiles/ClientIndex';
import DeliveryCountdown from '../components/reusable-components/ProjectTracker/DeliveryCountdown';
import ClientTracker from '../components/reusable-components/ProjectTracker/ClientTracker';

export const FieldLabel = ({ label }: { label: string }) => {
	return <div className="fieldLabel">{`${label}:`}</div>;
};

// Functional component that displays the details of the project selected by the project useState
const ProjectInfo: React.FC = () => {
	const { loadingStatus, setLoadingStatus } = useContext(LoadingContext);
	const { user } = useContext(AuthContext);
	const isContractor = !!user?.isContractor;
	const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);
	const [pendingUsers, setPendingUsers] = useState<PendingUser[]>([]);
	const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
	const { project, setProject, setShowContractorDialog } = useContext(ProjectContext);

	// const [docusignComplete, setDocusignComplete] = useState(false);
	const [utilityFormComplete, setUtilityFormComplete] = useState(false);
	const size = useWindowSize();
	const _isMounted = useRef(true);
	const { projectId } = useParams();
	const navigate = useNav();
	const [currentTab, setCurrentTab] = useState(0);

	const hasFiles = [
		project?.existingDocs,
		project?.photos,
		project?.matterport,
		project?.pointCloud,
		project?.pdf,
		project?.autodesk,
	].some(array => array && array.length > 0);

	const [categoryCompletions, setCategoryCompletions] = useState<
		Record<ProjectCategory, boolean>
	>({
		projectScope: false,
		scanning: false,
		dataProcessing: false,
		bim: false,
		delivery: false,
		projectDetails: false,
	});

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const getSetMembers = useCallback(async () => {
		if (!project) return;

		setLoadingUsers(true);
		const res = await getSharedUsers({ projectId: project.id });
		const data = res.data as any;
		setSharedUsers(data.sharedUsers ?? []);
		setPendingUsers(data.pendingUsers ?? []);
		setLoadingUsers(false);
	}, [project]);

	useEffect(() => {
		if (!!project) {
			for (const category in categoryFields) {
				const projCat = category as ProjectCategory;
				let complete = true;

				switch (projCat) {
					case 'projectScope':
						if (
							!validateCategory(
								project,
								projCat
								// [docusignComplete]
							)
						) {
							complete = false;
						}
						break;
					case 'scanning':
						if (!validateCategory(project, projCat)) {
							complete = false;
						}
						break;
					case 'dataProcessing':
						if (
							!validateCategory(
								project,
								projCat
								// [utilityFormComplete]
							)
						) {
							complete = false;
						}
						break;
					default:
						if (!validateCategory(project, projCat)) {
							complete = false;
						}
				}

				setCategoryCompletions(prev => {
					return {
						...prev,
						[projCat]: complete,
					};
				});
			}
		}
	}, [project, utilityFormComplete]);

	const fetchCompletionStatus = useCallback(async () => {
		if (!projectId) return;
		try {
			// const docusignStatus = (await getCompletedDocusign({ projectId })) as any;
			// setDocusignComplete(docusignStatus.data);

			const utilityFormStatus = (await getCompletedForms({ projectId })) as any;
			setUtilityFormComplete(utilityFormStatus.data);
		} catch (error) {
			console.error('Error fetching completion status:', error);
		}
	}, [projectId]);

	useEffect(() => {
		let isMounted = true;
		if (projectId) {
			fetchCompletionStatus().then(() => {
				if (!isMounted) return;
			});
		}
		return () => {
			isMounted = false;
		};
	}, [projectId, fetchCompletionStatus]);

	// Fetch, format, and set project
	useEffect(() => {
		const getSetProject = async () => {
			try {
				setLoadingStatus({ title: 'Loading project...' });
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				setProject(formatProjectTimestamps([resData.project])[0]);
				setLoadingStatus(null);
			} catch (err) {
				console.log(err);
				navigate('/404', { replace: true });
			}
		};
		getSetProject();

		return () => {
			setProject(null);
		};
	}, [projectId, user, setProject, navigate, setLoadingStatus]);

	const [anonUploadPassword, setAnonUploadPassword] = useState<string>('');

	// Fetches password for anonymous upload page
	useEffect(() => {
		if (project) {
			document.title = document.title = `${
				project.address || project.name || 'Unnamed Project'
			} | Project Page`;

			getProjectPassword({ projectId: project.id })
				.then((res: any) => {
					if (_isMounted.current) {
						const password = res.data.result;
						setAnonUploadPassword(password);
					}
				})
				.catch(e => {
					console.error(e);
				});
		}
	}, [project]);

	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (project && !isContractor) {
			getSetMembers();
		}
	}, [project, isContractor, getSetMembers]);

	const formIcon = useMemo(
		() => (
			<MdiIcon
				path={mdiFileDocumentEditOutline}
				color={
					utilityFormComplete ? theme.palette.success.main : theme.palette.primary.main
				}
			/>
		),
		[utilityFormComplete]
	);

	const renderData = [
		{
			isAdmin: false,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Project Details'}
					sharedUsers={sharedUsers}
					pendingUsers={pendingUsers}
					isAdmin={user?.isAdmin}
					isLoadingEmail={loadingUsers}
					isContractor={isContractor}
					showEmailButton>
					<ProjectInfoCategory
						category="projectDetails"
						fields={categoryFields.projectDetails}
						isVisible={false}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: false,
			render: true,
			node: (
				<ProjectInfoDropdown title={'Upload New Files'}>
					<ClientUploadBoxContent />
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Project Scope'}
					sharedUsers={sharedUsers}
					pendingUsers={pendingUsers}
					isAdmin={!!user?.isAdmin}
					isLoadingEmail={loadingUsers}
					isContractor={isContractor}
					isComplete={categoryCompletions.projectScope}
					showEmailButton>
					<ProjectInfoCategory
						category="projectScope"
						fields={categoryFields.projectScope}
						isVisible={false}
						isComplete={categoryCompletions.projectScope}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Processing'}
					isAdmin={!!user?.isAdmin}
					isComplete={categoryCompletions.dataProcessing}>
					<ProjectInfoCategory
						category="dataProcessing"
						fields={categoryFields.dataProcessing}
						anonUploadPassword={anonUploadPassword}
						setAnonUploadPassword={setAnonUploadPassword}
						isComplete={categoryCompletions.dataProcessing}
						icon={
							!!project && (
								<Link
									to={`/projects/${project.id}/capture-form-v2`}
									title="Navigate to project forms"
									tabIndex={-1}>
									<CompletionIconButton
										isComplete={utilityFormComplete}
										icon={formIcon}
									/>
								</Link>
							)
						}
						isVisible={false}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: true,
			isContractor: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'BIM'}
					isAdmin={!!user?.isAdmin}
					isComplete={categoryCompletions.bim}>
					<ProjectInfoCategory
						category="bim"
						fields={categoryFields.bim}
						anonUploadPassword={anonUploadPassword}
						setAnonUploadPassword={setAnonUploadPassword}
						isVisible={false}
						isComplete={categoryCompletions.bim}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Delivery'}
					isAdmin={!!user?.isAdmin}
					isComplete={categoryCompletions.delivery}>
					<ProjectInfoCategory
						category="delivery"
						fields={categoryFields.delivery}
						isVisible={false}
						isComplete={categoryCompletions.delivery}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAll: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Map'}
					isAdmin={!!user?.isAdmin}
					customIcon={
						user?.isAdmin && (
							<StyledFab
								title="Add a new contractor"
								onClick={event => [
									event.stopPropagation(),
									setShowContractorDialog(true),
								]}>
								<Add fontSize="small" sx={{ fill: theme.palette.common.black }} />
							</StyledFab>
						)
					}>
					{!!project?.address && <ProjectInfoMap address={project?.address} />}
				</ProjectInfoDropdown>
			),
		},
	];

	return (
		<Layout
			title={
				project
					? project.address || project.name || 'Unnamed Project'
					: 'Loading Project...'
			}
			LeftNavComponent={
				<>
					<div>
						{user?.isAdmin ? (
							<NewTracker projectStatus={project?.projectStatus} nav={true} />
						) : (
							<ClientTracker projectStatus={project?.projectStatus} nav={true} />
						)}
					</div>
					<DeliveryCountdown />
				</>
			}
			RightNavComponent={
				<>
					<RightNavButtons />
				</>
			}>
			<ProjectInfoWrapper>
				<LoadingLayer showOverlay={!!loadingStatus} />
				<Paper
					elevation={0}
					sx={{
						backgroundColor: 'transparent',
						color: 'white',
						width: '100%',
						height: '100%',
						overflow: size.width >= 1024 ? 'auto' : '',
						justifyContent: 'center',
						padding: '0px 35px',
					}}>
					<div className="siteSummaryGrid">
						{renderData.map(
							({ isAdmin, isContractor, node, render, isAll }, index) =>
								(isAdmin === !!user?.isAdmin ||
									isAll ||
									isContractor === !!user?.isContractor) &&
								render && <div key={index}>{node}</div>
						)}
					</div>
				</Paper>

				{/* Deliverable Files Container on Right side of page */}
				<Paper
					elevation={0}
					style={{
						backgroundColor: 'transparent',
						width: '100%',
						height: '100%',
						color: 'white',
						paddingRight: '2px',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<ContainerBorder
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}>
						<SectionHeader showLogo={false}>
							<Tabs value={currentTab} onChange={handleTabChange} centered>
								<StyledTab label="Files" />
								<StyledTab label="Reports" />
							</Tabs>
						</SectionHeader>
						<div style={{ height: '100%', width: '100%' }}>
							{currentTab === 0 &&
								(isContractor || user?.isAdmin ? (
									<DeliverableFiles />
								) : (
									<ClientDeliverableFiles hasFiles={hasFiles} />
								))}
							{currentTab === 1 && <V2FormsList hideNav={true} />}
						</div>
					</ContainerBorder>
				</Paper>
			</ProjectInfoWrapper>
		</Layout>
	);
};

const ProjectInfoWrapper = styled.div`
	display: grid;

	width: 100%;
	height: 100%;

	@media screen and (min-width: 1024px) {
		grid-template-columns: 550px auto;
	}
	@media screen and (max-width: 1023px) {
		grid-template-columns: 100%;
		padding: 0 10px;
		height: fit-content;
	}
`;

const StyledFab = styled(Fab)`
	margin-top: -10px;
	margin-bottom: -10px;
	width: 24px;
	height: 24px;
	background-color: ${theme.palette.error.main};
	transition: 0.3s;
	&:hover {
		opacity: 0.7;
		background-color: ${theme.palette.error.main};
	}
	min-height: unset;
`;

export const StyledTab = styled(Tab)`
	color: ${theme.palette.common.white};
	text-transform: none;
`;
export default ProjectInfo;
