import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LoadingScreen from '../../../reusable-components/LoadingScreen';
import { useContext } from 'react';
import { Team } from '../../../../types';
import { SnackContext } from '../../../../context/SnackProvider';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { deleteTeams } from '../../../../firebase';

export default function DeleteTeamDialog({
	teamsToBeDeleted,
	setTeamsToBeDeleted,
}: {
	teamsToBeDeleted: Team[] | undefined;
	setTeamsToBeDeleted: React.Dispatch<React.SetStateAction<Team[] | undefined>>;
}) {
	const { deletingTeams, setDeletingTeams, setTeams } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setTeamsToBeDeleted(undefined);
	};

	const handleDelete = async () => {
		if (teamsToBeDeleted && teamsToBeDeleted.length > 0) {
			const teamIds = teamsToBeDeleted.map(team => team.id);
			try {
				setDeletingTeams(true);
				await deleteTeams({ ids: teamIds });
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Successfully deleted ${teamsToBeDeleted.length} team(s)!`,
				});
				setDeletingTeams(false);
				setTeams(prev => prev?.filter(team => !teamIds.includes(team.id)));
				handleClose();
			} catch (error) {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Unable to delete ${teamsToBeDeleted.length} team(s) because team(s) still have users.`,
				});
				setDeletingTeams(false);
				handleClose();
			}
		}
	};

	return (
		<Dialog open={!!teamsToBeDeleted} onClose={handleClose}>
			<DialogTitle>{`Delete ${teamsToBeDeleted?.length} Team(s)`}</DialogTitle>
			{deletingTeams ? (
				<DialogContent>
					<LoadingScreen message="Deleting teams..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<span>Are you sure you want to delete these teams?</span>
				</DialogContent>
			)}
			{!deletingTeams && (
				<DialogActions>
					<Button autoFocus onClick={handleClose} sx={{ textTransform: 'none' }}>
						Cancel
					</Button>
					<Button onClick={handleDelete} sx={{ textTransform: 'none' }}>
						Ok
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
