import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { Team } from '../../../types';
import CreateReportDialog from '../../screen-components/TeamPage/CreateReportDialog';
import useReports from '../../screen-components/TeamPage/useReports';
import styled from 'styled-components';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

export default function TeamSelectionDialog({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { teams } = useContext(UserHomeContext);
	const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
	const [createReportDialogOpen, setCreateReportDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);
	const teamId = selectedTeam ? selectedTeam.id : '';
	const { loadTeam } = useContext(OrganizationsContext);

	const { createReport, loadForms, projectsWithForms, toggleForm, totalSelectedForms } =
		useReports({ organizationId: selectedTeam?.organizationId ?? '', teamId });

	const handleTeamSelection = (value: Team) => {
		if (!value.projectIds || value.projectIds.length < 1) {
			setSnackbarProps({
				open: true,
				message: `${value.name} has 0 projects. Please select a team with projects in order to create analytics.`,
				severity: 'error',
			});
			setSelectedTeam(null);
			return;
		} else {
			setSelectedTeam(value);
			setSnackbarProps({
				open: false,
			});
		}
	};

	const openCreateReportDialog = () => {
		loadForms();
		setOpen(!open);
		setCreateReportDialogOpen(!createReportDialogOpen);
	};

	const handleClose = () => {
		setOpen(!open);
		setSelectedTeam(null);
	};

	return (
		<>
			<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
				<DialogTitle>Select Team for Analytics</DialogTitle>
				<DialogContent>
					<TextField
						select
						name="team"
						label="Team"
						value={selectedTeam?.id || ''}
						fullWidth
						variant="standard"
						required
						InputLabelProps={{
							shrink: true,
						}}
						onChange={e => {
							const teamId = e.target.value as string;
							if (teamId) {
								const chosenTeam = teams.find(team => team.id === teamId);
								if (chosenTeam) {
									loadTeam(chosenTeam?.id);

									const selectedTeamObj: Team = {
										id: chosenTeam.id,
										name: chosenTeam.name,
										organizationId: chosenTeam.organizationId,
										projectIds: chosenTeam.projectIds,
										userIds: chosenTeam.userIds,
									};
									handleTeamSelection(selectedTeamObj);
								}
							}
						}}>
						{teams.map(team => (
							<MenuItem value={team.id} key={team.id}>
								{team.name}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<StyledActions>
					<ActionButton onClick={handleClose}>Cancel</ActionButton>
					<ActionButton
						onClick={openCreateReportDialog}
						autoFocus
						disabled={!selectedTeam || selectedTeam?.id === ''}>
						Continue
					</ActionButton>
				</StyledActions>
			</Dialog>

			<CreateReportDialog
				open={createReportDialogOpen}
				createReport={createReport}
				projects={projectsWithForms}
				setOpen={setCreateReportDialogOpen}
				toggleForm={toggleForm}
				totalSelectedForms={totalSelectedForms}
			/>
		</>
	);
}

const StyledActions = styled(DialogActions)`
	text-decoration: none;
`;

const ActionButton = styled(Button)`
	text-transform: none;
`;
