import { useCallback, useContext, useEffect, useState } from 'react';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import styled from 'styled-components';
import SkeletonGrid from '../grid-components/SkeletonGrid';
import TeamSearchBar from './TeamsSearchbar';
import { Button } from '@mui/material';
import TeamGrid from '../grid-components/TeamGrid';
import DeleteTeamDialog from './DeleteTeamDialog';
import AddTeamDialog from './AddTeamDialog';
import { AuthContext } from '../../../../context/AuthProvider';
import { useParams } from 'react-router-dom';
import { Team } from '../../../../types';
import { SnackContext } from '../../../../context/SnackProvider';
import { db } from '../../../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default function TeamsTab() {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { teams } = useContext(OrganizationsContext);
	const { organizationId }: any = useParams();

	const isAdmin = user?.isAdmin;
	const isOrgOwner = user?.orgOwnerIds?.includes(organizationId);

	const [searchQuery, setSearchQuery] = useState('');
	const [openTeamDialog, setOpenTeamDialog] = useState(false);

	const [isSelectionMode, setIsSelectionMode] = useState(false);
	const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
	const [teamsToBeDeleted, setTeamsToBeDeleted] = useState<Team[] | undefined>();

	const [userTeams, setUserTeams] = useState<Team[]>([]);

	const toggleSelectionMode = () => {
		setIsSelectionMode(!isSelectionMode);
		setSelectedTeams([]);
	};

	const handleDeleteSelected = async () => {
		if (selectedTeams.length > 0) {
			const teamsToDelete = (teams || []).filter(team => selectedTeams.includes(team.id));
			setTeamsToBeDeleted(teamsToDelete);
		}
	};

	const handleTeamSelect = (teamId: string) => {
		setSelectedTeams(prevSelectedTeams => {
			const newSelectedTeams = prevSelectedTeams.includes(teamId)
				? prevSelectedTeams.filter(id => id !== teamId)
				: [...prevSelectedTeams, teamId];

			// console.log('Selected Teams:', newSelectedTeams);
			return newSelectedTeams;
		});
	};

	const userIsTeamMember = (teamId: string) => {
		if (user) {
			const team = (teams || []).find(t => t.id === teamId);
			return team?.userIds?.includes(user.id) ?? false;
		} else {
			return false;
		}
	};

	const handleUnauthorizedAccess = () => {
		setSnackbarProps({
			open: true,
			message: `Permission denied. Contact your organization's admin to obtain access to this team.`,
			severity: 'warning',
			hideDuration: null,
		});
	};

	const fetchAllUserTeams = useCallback(async () => {
		const queryUserTeams = query(
			collection(db, 'teams'),
			where('userIds', 'array-contains', user?.id)
		);
		const teamsUserCollection = await getDocs(queryUserTeams);
		const fetchedTeams = teamsUserCollection.docs.map(
			fetchedDoc =>
				({
					...fetchedDoc.data(),
					id: fetchedDoc.id,
				} as Team)
		);

		if (user?.orgOwnerIds && user.orgOwnerIds.length > 0) {
			const queryOrgTeams = query(
				collection(db, 'teams'),
				where('organizationId', 'in', user?.orgOwnerIds)
			);
			const teamsOrgCollection = await getDocs(queryOrgTeams);

			teamsOrgCollection.docs.forEach(fetchedDoc => {
				if (!fetchedTeams.find(team => team.id === fetchedDoc.id)) {
					fetchedTeams.push({
						...fetchedDoc.data(),
						id: fetchedDoc.id,
					} as Team);
				}
			});
		}

		fetchedTeams.sort((a, b) => a.name.localeCompare(b.name));

		setUserTeams(fetchedTeams);
	}, [user]);

	useEffect(() => {
		fetchAllUserTeams();
	}, [fetchAllUserTeams, user]);

	const filteredTeams = () => {
		if (user?.isAdmin) {
			return (
				teams?.filter(team =>
					team.name.toLowerCase().includes(searchQuery.toLowerCase())
				) || []
			);
		} else {
			return (
				userTeams.filter(team =>
					team.name.toLowerCase().includes(searchQuery.toLowerCase())
				) || []
			);
		}
	};

	return (
		<TeamContainer>
			{teams ? (
				<>
					<TeamSearchBar
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
						onAddTeam={() => setOpenTeamDialog(true)}
						showButton={isAdmin || isOrgOwner}
						name={'Team'}
					/>
					<TeamContainerHeaderContainer>
						<TeamContainerHeader>Teams</TeamContainerHeader>
						{isAdmin || isOrgOwner ? (
							<SelectionButtonsContainer>
								{!isSelectionMode ? (
									<SelectionButton onClick={toggleSelectionMode}>Select</SelectionButton>
								) : (
									<>
										<SelectionButton onClick={handleDeleteSelected}>
											Delete
										</SelectionButton>
										<SelectionButton onClick={toggleSelectionMode}>
											Cancel
										</SelectionButton>
									</>
								)}
							</SelectionButtonsContainer>
						) : null}
					</TeamContainerHeaderContainer>
					<div className="grid-container">
						<TeamGrid
							teams={filteredTeams()}
							inSelectionMode={isSelectionMode}
							selectedTeams={selectedTeams}
							onSelectTeam={handleTeamSelect}
							userIsAdmin={isAdmin}
							userIsOrgOwner={isOrgOwner}
							userIsTeamMember={userIsTeamMember}
							onUnauthorizedAccess={handleUnauthorizedAccess}
						/>
					</div>
					<DeleteTeamDialog
						teamsToBeDeleted={teamsToBeDeleted}
						setTeamsToBeDeleted={setTeamsToBeDeleted}
					/>
					<AddTeamDialog
						open={openTeamDialog}
						setOpen={setOpenTeamDialog}
						organizationId={organizationId}
					/>
				</>
			) : (
				<SkeletonGrid />
			)}
		</TeamContainer>
	);
}

const TeamContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	align-items: center;

	@media (max-width: 1020px) {
		width: 100%;
	}

	.grid-container {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
	}

	@media (max-width: 400px) {
		padding: 10px;
	}
`;

const TeamContainerHeaderContainer = styled.div`
	width: 95%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	min-width: 230px;
	padding-left: 10px;
	padding-right: 2px;
	align-items: center;

	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const TeamContainerHeader = styled.h3`
	display: flex;
	color: white;
	text-align: center;
	font-weight: normal;
	font-size: 2rem;
	width: 100%;
	align-self: center;
	justify-content: center;
`;

const SelectionButtonsContainer = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	gap: 5px;

	@media (max-width: 888px) {
		position: static;
		transform: none;
		top: 10%;
		transform: translateY(-90%);

		align-self: flex-end;
	}
`;

const SelectionButton = styled(Button)`
	font-size: 1rem;
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 50px;
	margin-left: auto;
	align-items: center;
	height: 40px;
	text-transform: none;
	padding: 15px;
	text-align: center;
	right: 0;

	&:hover {
		background-color: #ffb310;
	}
`;
