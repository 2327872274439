import { ProjectStatus } from '../../../types';

export type StatusGroupName = 'Pending' | 'In-Progress' | 'Delivered';
export const statusGroups: { [groupName in StatusGroupName]: ProjectStatus[] } = {
	Pending: ['Proposal Pending', 'Proposal Sent', 'To Be Scheduled'],
	'In-Progress': [
		'On-Site Capture Complete',
		'Registration',
		'Ready For Modeling',
		'Modeling In Progress',
		'QA/QC',
	],
	Delivered: [
		'Pending Payment',
		'Pending Payment / Delivered',
		'Delivered',
		'Sample',
		'Template',
	],
};
export const StatusGroupColor: { [groupName in StatusGroupName]: string } = {
	Pending: 'rgb(237, 84, 84)',
	'In-Progress': 'rgb(255, 179, 16)',
	Delivered: 'rgb(91, 194, 54)',
};

export const StatusGroupBackgroundColor: { [groupName in StatusGroupName]: string } = {
	Pending: 'rgb(240, 163, 163)',
	'In-Progress': '#ffb310',
	Delivered: 'rgb(215, 255, 184)',
};
