import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '../../../../styles/theme';

interface ScopeInputProps {
	checked: boolean;
	onChange: (newValue: boolean) => Promise<void>;
}

export default function ScopeInput({ checked, onChange }: ScopeInputProps) {
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onChange(!checked);
		}
	};

	return (
		<CheckboxContainer>
			<XCheckbox
				type="checkbox"
				checked={checked}
				onChange={e => onChange(e.target.checked)}
				onKeyUp={handleKeyPress}
			/>
			<Checkmark checked={checked} />
		</CheckboxContainer>
	);
}

const CheckboxContainer = styled.div`
	display: flex;
	position: relative;
	width: fit-content;
`;

const boxWidth = '25px';
const boxBorderWidth = '2px';

const XCheckbox = styled.input`
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${boxWidth};
	height: ${boxWidth};
	margin: 2px;
	position: relative;
	cursor: pointer;

	/* Checkbox */
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: ${boxWidth};
		height: ${boxWidth};
		background: ${theme.palette.secondary.dark};
		outline: solid white ${boxBorderWidth};
		box-sizing: border-box;
		transition: 0.1s;
	}
	&:hover::before {
		outline: solid ${theme.palette.primary.main} ${boxBorderWidth};
	}

	/* Accessibility */
	&:focus {
		outline: none;
		&::before {
			outline: solid ${theme.palette.primary.main} ${boxBorderWidth};
		}
	}
`;

const Checkmark = styled(ClearIcon)<{ checked: boolean }>`
	position: absolute;
	top: ${boxBorderWidth};
	left: ${boxBorderWidth};

	width: ${boxWidth};
	height: ${boxWidth};
	fill: ${theme.palette.primary.main};
	pointer-events: none;
	transition: 200ms;

	opacity: ${({ checked }) => (checked ? '1' : '0')};
	transform: ${({ checked }) =>
		checked ? 'scale(1) rotate(0)' : 'scale(0) rotate(45deg)'};
	-webkit-transform: ${({ checked }) =>
		checked ? 'scale(1) rotate(0)' : 'scale(0) rotate(45deg)'};
`;
