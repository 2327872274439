import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	styled,
} from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import useTemplates from './useTemplates';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { ItemsToBeRemovedInterface } from '../../../screens/TeamPage';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import theme from '../../../styles/theme';

export default function RemoveTemplateDialog({
	templatesToBeRemoved,
	setTemplatesToBeRemoved,
	teamId,
	organizationId,
}: {
	templatesToBeRemoved: ItemsToBeRemovedInterface[] | undefined;
	setTemplatesToBeRemoved: Dispatch<
		SetStateAction<ItemsToBeRemovedInterface[] | undefined>
	>;
	teamId: string;
	organizationId: string;
}) {
	const { loadTeam } = useContext(OrganizationsContext);
	const { removeTemplateFromTeam } = useTemplates({ teamId, organizationId });
	const { setSnackbarProps } = useContext(SnackContext);
	const [removingTemplateFromTeam, setRemovingTemplateFromTeam] = useState(false);

	const handleClose = () => setTemplatesToBeRemoved(undefined);

	const handleDelete = async () => {
		for (const templateToBeRemoved of templatesToBeRemoved || []) {
			if (templateToBeRemoved) {
				setRemovingTemplateFromTeam(true);
				const result = await removeTemplateFromTeam(templateToBeRemoved.id);
				await loadTeam(teamId);

				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed templates from team!`,
					});
				}
			}
		}
		handleClose();
		setRemovingTemplateFromTeam(false);
	};

	useEffect(() => {
		if (templatesToBeRemoved?.length === 0) {
			handleClose();
		}
	}, [handleClose, templatesToBeRemoved]);

	const handleRemoveTemplate = (project: ItemsToBeRemovedInterface) => {
		setTemplatesToBeRemoved(templatesToBeRemoved?.filter(item => item.id !== project.id));
	};

	const templateWord = templatesToBeRemoved?.length === 1 ? 'template' : 'templates';

	return (
		<Dialog open={!!templatesToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove {templateWord} from team</DialogTitle>
			{removingTemplateFromTeam ? (
				<DialogContent>
					<LoadingScreen
						message="Removing templates from the team..."
						textColor="black"
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<Text>
						Are you sure you want to remove the following {templateWord} from this team?
					</Text>
					<Container>
						{templatesToBeRemoved?.map((project, index) => (
							<ContainerContent key={index}>
								<IconRemove onClick={() => handleRemoveTemplate(project)} />
								<Address>{project.name}</Address>
							</ContainerContent>
						))}
					</Container>{' '}
				</DialogContent>
			)}
			{!removingTemplateFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Remove {templateWord}</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}

const Text = styled('span')`
	color: #000
	font-size: 16px;
	font-weight: 400;
`;

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 20px 0 0 10px;
`;

const ContainerContent = styled('div')`
	display: flex;
	align-items: center;
	gap: 7px;
`;

const Address = styled('span')`
	color: #595959;
	font-size: 14px;
	font-weight: 400;
`;

const IconRemove = styled(RemoveCircleOutlineIcon)`
	color: ${theme.palette.primary.main};
	width: 15px;
	height: 15px;
	cursor: pointer;
`;
