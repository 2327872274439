import { SectionProps } from './Section.types';
import SectionCreate from './SectionCreate';
import SectionExist from './SectionExist';

const Section: React.FC<SectionProps> = ({
	node,
	isCreate,
	level,
	itemsByParentId,
	expandedList,
	toggleCollapse,
	orderedItems,
}) => {
	return isCreate ? (
		<SectionCreate
			node={node}
			level={level}
			itemsByParentId={itemsByParentId}
			expandedList={expandedList}
			toggleCollapse={toggleCollapse}
			orderedItems={orderedItems}
		/>
	) : (
		<SectionExist
			node={node}
			level={level}
			itemsByParentId={itemsByParentId}
			expandedList={expandedList}
			toggleCollapse={toggleCollapse}
			orderedItems={orderedItems}
		/>
	);
};

export default Section;
