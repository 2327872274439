import { useContext, useEffect, useState } from 'react';
import { PendingShare, UserObj } from '../../../../types';
import { SnackContext } from '../../../../context/SnackProvider';
import { db, getOrgOwners } from '../../../../firebase';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import DataTable from '../../../reusable-components/DataTable';
import { columns } from './OrganizationPageColumns';
import AddOrgOwnerDialog from './AddOrgOwnerDialog';
import { PendingUserObj } from '../../../../screens/TeamPage';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { AuthContext } from '../../../../context/AuthProvider';

export default function OwnerTab() {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const { organizationId } = useParams();

	const [orgOwners, setOrgOwners] = useState<UserObj[]>([]);
	const [loadingOrgOwners, setLoadingOrgOwners] = useState(false);

	const [pendingOwners, setPendingOwners] = useState<PendingUserObj[]>([]);

	const [openAddOrgOwner, setOpenAddOrgOwner] = useState(false);

	useEffect(() => {
		if (
			!user?.userPerms?.organizations &&
			!user?.orgOwnerIds?.includes(organizationId as '')
		)
			return;
		let unsubPendingOwners = () => {};
		if (organizationId) {
			setLoadingOrgOwners(true);

			// Get org owners.
			getOrgOwners({ orgId: organizationId })
				.then(res => {
					const owners = (res.data as UserObj[]) || [];
					setOrgOwners(owners);
				})
				.catch(err => {
					console.error(err);
					setSnackbarProps({
						open: true,
						severity: 'error',
						message: 'Could not fetch organization owners',
					});
				})
				.finally(() => {
					setLoadingOrgOwners(false);
				});

			// Get pending org owners.
			const coll = collection(db, 'pending_project_shares');
			const pendingOwnerQuery = query(
				coll,
				where('orgOwnerIds', 'array-contains', organizationId)
			);
			unsubPendingOwners = onSnapshot(pendingOwnerQuery, snap => {
				const fetchedOwners: PendingUserObj[] = snap.docs.map(pendingShareDoc => {
					const pendingShare = pendingShareDoc.data() as PendingShare;
					return {
						email: pendingShare.email,
						lastShared: new Date((pendingShare as any).lastUpdated.seconds * 1000),
						orgOwnerIds: pendingShare.orgOwnerIds || [],
					};
				});
				setPendingOwners(fetchedOwners);
			});
		}

		return () => {
			unsubPendingOwners();
		};
	}, [
		organizationId,
		setSnackbarProps,
		user?.orgOwnerIds,
		user?.userPerms?.organizations,
	]);

	return (
		<Container>
			{loadingOrgOwners ? (
				<SkeletonTable rows={5} />
			) : (
				<DataTable
					data={[...pendingOwners, ...orgOwners]}
					columns={columns}
					name="user"
					queryFields={['name', 'email']}
					onAdd={() => setOpenAddOrgOwner(true)}
				/>
			)}

			<AddOrgOwnerDialog
				open={openAddOrgOwner}
				setOpen={setOpenAddOrgOwner}
				orgId={organizationId || ''}
			/>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;

	padding: 0 20px;
	width: 100%;
`;
