import {
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ContainerProps, Organization, ProjectData, Team, UserObj } from '../types';
import {
	addProjectToTeam,
	addProjectsToTeam,
	addUserToTeam,
	createOrganization,
	createTeam,
	deleteOrganization,
	getOrganization,
	getOrganizations,
	getTeam,
	getTeams,
	removeProjectFromTeam,
	removeUserFromTeam,
	updateOrganization,
	updateTeam,
} from '../firebase';
import { AuthContext } from './AuthProvider';
import { SnackContext } from './SnackProvider';

export type OrganizationsContextType = {
	addingOrganization: boolean;
	addingProjectToTeam: boolean;
	addingTeam: boolean;
	addingUserToTeam: boolean;
	addOrganization: (name: string) => Promise<string | null>;
	addProjectToTeam: (projectId: string, teamId: string) => Promise<boolean>;
	addProjectsToTeam: (
		projectIds: string[],
		teamId: string,
		organizationId: string
	) => Promise<boolean>;
	addTeam: (team: Team) => Promise<boolean>;
	addUserToTeam: (
		email: string,
		teamId: string,
		organizationId: string
	) => Promise<boolean>;
	clearOrganization: () => void;
	deletingTeams: boolean;
	filterProjects: (projects: ProjectData[]) => ProjectData[];
	setDeletingTeams: React.Dispatch<SetStateAction<boolean>>;
	loadOrganization: (organizationId: string) => Promise<void>;
	loadTeam: (teamId: string) => Promise<void>;
	loadingOrganization: boolean;
	loadingOrganizations: boolean;
	loadingTeam: boolean;
	logoUrl: string | null;
	logoLoading: boolean;
	organization: Organization | null;
	organizations: Organization[];
	organizationsQuery: string;
	removeOrganization: (organizationId: string) => Promise<boolean>;
	removeProjectFromTeam: (projectId: string, teamId: string) => Promise<boolean>;
	removeUserFromTeam: (email: string, teamId: string) => Promise<boolean>;
	removingOrganization: boolean;
	removingProjectFromTeam: boolean;
	removingUserFromTeam: boolean;
	setOrganization: React.Dispatch<SetStateAction<Organization | null>>;
	setOrganizationsQuery: React.Dispatch<SetStateAction<string>>;
	setTeam: React.Dispatch<SetStateAction<Team | undefined>>;
	setTeams: React.Dispatch<SetStateAction<Team[] | undefined>>;
	team: Team | undefined;
	teams: Team[] | undefined;
	updateLogoUrl: (newUrl: string | null) => void;
	updateOrganization: (organization: Organization) => Promise<boolean>;
	updateTeam: (team: Team) => Promise<boolean>;
	updatingOrganization: boolean;
	updatingTeam: boolean;
	selectedOrganizationId: string | null;
	setSelectedOrganizationId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const OrganizationsContext = createContext<OrganizationsContextType>(
	{} as OrganizationsContextType
);

export const OrganizationsProvider = ({ children }: ContainerProps) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);

	const [addingOrganization, setAddingOrganization] = useState(false);
	const [addingProjectToTeam, setAddingProjectToTeam] = useState(false);
	const [addingTeam, setAddingTeam] = useState(false);
	const [addingUserToTeam, setAddingUserToTeam] = useState(false);
	const [deletingTeams, setDeletingTeams] = useState(false);
	const [loadingOrganization, setLoadingOrganization] = useState(false);
	const [loadingOrganizations, setLoadingOrganizations] = useState(false);
	const [loadingTeam, setLoadingTeam] = useState(false);
	const [logoUrl, setLogoUrl] = useState<string | null>(null);
	const [logoLoading, setLogoLoading] = useState<boolean>(false);
	const [organization, setOrganization] = useState<Organization | null>(null);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [organizationsQuery, setOrganizationsQuery] = useState('');
	const [removingOrganization, setRemovingOrganization] = useState(false);
	const [removingProjectFromTeam, setRemovingProjectFromTeam] = useState(false);
	const [removingUserFromTeam, setRemovingUserFromTeam] = useState(false);
	const [team, setTeam] = useState<Team | undefined>(undefined);
	const [teams, setTeams] = useState<Team[] | undefined>(undefined);
	const [updatingOrganization, setUpdatingOrganization] = useState(false);
	const [updatingTeam, setUpdatingTeam] = useState(false);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(
		null
	);

	const updateLogoUrl = useCallback((newUrl: string | null) => {
		setLogoLoading(true);
		setLogoUrl(newUrl);
		if (newUrl) {
			setLogoLoading(true);
			const img = new Image();
			img.src = newUrl;
			img.onload = () => setLogoLoading(false);
			img.onerror = () => setLogoLoading(false);
		} else {
			setLogoLoading(false);
		}
	}, []);

	useEffect(() => {
		if (
			organization &&
			organization.logo &&
			organization.logo[0] &&
			organization.logo[0].downloadUrl
		) {
			updateLogoUrl(organization.logo[0].downloadUrl);
		}
	}, [organization, updateLogoUrl]);

	const loadOrganizations = useCallback(async () => {
		setLoadingOrganizations(true);
		try {
			const organizationsArr = (await getOrganizations()).data as Organization[];
			setOrganizations(organizationsArr);
		} catch (error) {
			console.error('Failed to load organizations:', error);
			setOrganizations([]);
		}
		setLoadingOrganizations(false);
	}, []);

	useEffect(() => {
		if (user) loadOrganizations();
	}, [user, loadOrganizations]);

	const addOrganization = async (name: string): Promise<string | null> => {
		try {
			setAddingOrganization(true);
			const newOrganizationId = await createOrganization({ name });
			setAddingOrganization(false);

			if (typeof newOrganizationId.data === 'string') {
				const orgId = newOrganizationId.data;
				setOrganizations(prev =>
					[
						...prev,
						{
							name,
							id: orgId,
							adminNotes: '',
							orgNotes: '',
							logo: [],
						},
					].sort((a, b) => a.name.localeCompare(b.name))
				);
				return orgId;
			}
		} catch (error) {
			setAddingOrganization(false);
			console.error(error);
		}

		return null;
	};

	const removeOrganization = async (organizationId: string): Promise<boolean> => {
		try {
			setRemovingOrganization(true);
			const result = await deleteOrganization({ id: organizationId });
			setRemovingOrganization(false);

			if (!result) return false;

			setOrganizations(prev =>
				prev.filter(organization => organization.id !== organizationId)
			);

			return true;
		} catch (error) {
			setRemovingOrganization(false);
			console.error(error);
		}

		return false;
	};

	const loadOrganization = useCallback(
		async (organizationId: string) => {
			try {
				setLoadingOrganization(true);
				const organizationDoc = await getOrganization({ id: organizationId });
				const organizationData = organizationDoc.data as Organization;
				setOrganization(organizationData);
				setLogoLoading(true);
				updateLogoUrl(organizationData.logo?.[0]?.downloadUrl || null);
				setLogoLoading(false);
				const teamsArr = (await getTeams({ organizationId })).data as Team[];
				setTeams(teamsArr);
				setLoadingOrganization(false);
			} catch (error) {
				setLoadingOrganization(false);
				console.error(error);
			}
		},
		[updateLogoUrl]
	);

	const updateOrganizationLocal = async (
		updatedOrganization: Organization
	): Promise<boolean> => {
		if (organization && organization.id) {
			try {
				setUpdatingOrganization(true);
				const result = await updateOrganization({
					...updatedOrganization,
					id: organization.id,
				});
				setUpdatingOrganization(false);

				if (!result) return false;

				setOrganizations(prev =>
					prev
						? prev
								.map(prevOrganization =>
									prevOrganization.id === organization.id
										? organization
										: prevOrganization
								)
								.sort((a, b) => a.name.localeCompare(b.name))
						: []
				);
				setOrganization(updatedOrganization);

				return true;
			} catch (error) {
				setLoadingOrganization(false);
				console.error(error);
			}
		}

		return false;
	};

	const filterProjects = useCallback(
		(projects: ProjectData[]) => {
			if (!selectedOrganizationId) {
				return projects; // Return all projects when no organization is selected
			}

			return projects.filter(project => {
				// Check if the project is shared with a team in the selected organization
				const isSharedWithOrgTeam = project.sharedTeams?.some(teamId => {
					const team = teams?.find(t => t.id === teamId);
					return team && team.organizationId === selectedOrganizationId;
				});

				// Check if the project is directly associated with the selected organization
				const isAssociatedWithOrg = project.orgs?.some(
					org => org.id === selectedOrganizationId
				);

				return isSharedWithOrgTeam || isAssociatedWithOrg;
			});
		},
		[selectedOrganizationId, teams]
	);
	const addTeam = async (team: Team): Promise<boolean> => {
		try {
			setAddingTeam(true);

			const newTeamId = await createTeam({
				name: team.name,
				organizationId: team.organizationId,
			});
			setAddingTeam(false);

			if (typeof newTeamId.data === 'string') {
				setTeams(prev =>
					[...(prev || []), { ...team, id: newTeamId.data as string }].sort((a, b) =>
						a.name.localeCompare(b.name)
					)
				);
				return true;
			}
		} catch (error) {
			setAddingTeam(false);
			console.error(error);
		}

		return false;
	};

	const loadTeam = useCallback(
		async (teamId: any) => {
			try {
				setLoadingTeam(true);
				setLogoLoading(true);
				const teamDoc = await getTeam({ id: teamId });
				const teamData = teamDoc.data as Team;
				setTeam(teamData);
				setLoadingTeam(false);

				if (!organization || organization.id !== teamData.organizationId) {
					const organizationDoc = await getOrganization({ id: teamData.organizationId });
					const organizationData = organizationDoc.data as Organization;
					setOrganization(organizationData);
					updateLogoUrl(organizationData.logo?.[0]?.downloadUrl || null);
				}
				setLogoLoading(false);
			} catch (error) {
				setLoadingTeam(false);
				console.error(error);
			}
		},
		[organization, updateLogoUrl]
	);

	const updateTeamLocal = async (updatedTeam: Team): Promise<boolean> => {
		if (team && team.id) {
			try {
				setUpdatingTeam(true);
				const result = await updateTeam({
					...updatedTeam,
					id: team.id,
				});
				setUpdatingTeam(false);

				if (!result) return false;

				setTeams(prev =>
					prev
						? prev
								.map(prevTeam => (prevTeam.id === team.id ? team : prevTeam))
								.sort((a, b) => a.name.localeCompare(b.name))
						: []
				);

				return true;
			} catch (error) {
				setLoadingOrganization(false);
				console.error(error);
			}
		}

		return false;
	};

	const addUserToTeamLocal = async (
		email: string,
		teamId: string,
		organizationId: string
	): Promise<boolean> => {
		try {
			setAddingUserToTeam(true);
			const result = await addUserToTeam({
				email,
				teamId,
				organizationId: organizationId,
			});
			setAddingUserToTeam(false);

			if (!result?.data) {
				console.error(`addUserToTeam did not return any data`);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: 'Failed to add team member',
				});
			} else {
				const returnData = result.data as { email: string; id?: string; name?: string };
				if (returnData.id && returnData.name) {
					setTeam(prev =>
						prev
							? {
									...prev,
									userIds: [...(prev.userIds || []), (returnData as UserObj).id],
									users: [...(prev.users || []), returnData as UserObj],
							  }
							: undefined
					);
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: 'Added new team member!',
					});
				} else {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: 'Invited new team member!',
					});
				}
			}
			return true;
		} catch (error) {
			console.error(`addUserToTeam errored out`, error);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Failed to add team member',
			});
		}

		return false;
	};

	const addProjectsToTeamLocal = async (
		projectIds: string[],
		teamId: string,
		organizationId: string
	): Promise<boolean> => {
		try {
			setAddingProjectToTeam(true);
			const result = await addProjectsToTeam({
				projectIds,
				teamId,
				organizationId,
			});
			setAddingProjectToTeam(false);

			if (!result) return false;

			const projects = result.data as ProjectData[];
			if (!projects || !projects.length) return false;

			setTeam(prev =>
				prev
					? {
							...prev,
							projectIds: [...(prev.projectIds || []), ...projectIds],
							projects: [...(prev.projects || []), ...projects],
					  }
					: undefined
			);

			return true;
		} catch (error) {
			console.error(error);
			setAddingProjectToTeam(false);
			return false;
		}
	};

	const removeUserFromTeamLocal = async (
		userId: string,
		teamId: string
	): Promise<boolean> => {
		try {
			setRemovingUserFromTeam(true);
			const result = await removeUserFromTeam({
				userId,
				teamId,
				organizationId: team?.organizationId || '',
			});
			setRemovingUserFromTeam(false);

			if (!result) return false;

			setTeam(prev =>
				prev
					? {
							...prev,
							userIds: prev.userIds?.filter(id => id !== userId),
							users: prev.users?.filter(userObj => userObj.id !== userId),
					  }
					: undefined
			);

			return true;
		} catch (error) {
			console.error(error);
		}

		return false;
	};

	const addProjectToTeamLocal = async (
		projectId: string,
		teamId: string
	): Promise<boolean> => {
		try {
			setAddingProjectToTeam(true);
			const result = await addProjectToTeam({
				projectId,
				teamId,
			});
			setAddingProjectToTeam(false);

			if (!result) return false;

			const project = result.data as ProjectData;
			if (!project || !project.id) return false;

			setTeam(prev =>
				prev
					? {
							...prev,
							projectIds: [...(prev.projectIds || []), project.id!],
							projects: [...(prev.projects || []), project],
					  }
					: undefined
			);

			return true;
		} catch (error) {
			console.error(error);
		}

		return false;
	};

	const removeProjectFromTeamLocal = async (
		projectId: string,
		teamId: string
	): Promise<boolean> => {
		try {
			setRemovingProjectFromTeam(true);
			const result = await removeProjectFromTeam({
				projectId,
				teamId,
				organizationId: team?.organizationId || '',
			});
			setRemovingProjectFromTeam(false);

			if (!result) return false;

			setTeam(prev =>
				prev
					? {
							...prev,
							projectIds: prev.projectIds?.filter(id => id !== projectId),
							projects: prev.projects?.filter(project => project.id !== projectId),
					  }
					: undefined
			);

			return true;
		} catch (error) {
			console.error(error);
		}

		return false;
	};

	const clearOrganization = () => {
		setOrganization(null);
		setTeam(undefined);
		setTeams(undefined);
		setOrganizationsQuery('');
		setOrganizations([]);
		setLogoUrl(null);
	};

	return (
		<OrganizationsContext.Provider
			value={{
				addingOrganization,
				addingProjectToTeam,
				addingTeam,
				addingUserToTeam,
				addOrganization,
				addProjectToTeam: addProjectToTeamLocal,
				addProjectsToTeam: addProjectsToTeamLocal,
				addTeam,
				addUserToTeam: addUserToTeamLocal,
				clearOrganization,
				deletingTeams,
				filterProjects,
				setDeletingTeams,
				loadOrganization,
				loadTeam,
				loadingOrganization,
				loadingOrganizations,
				loadingTeam,
				logoUrl,
				logoLoading,
				organization,
				organizations,
				organizationsQuery,
				removeOrganization,
				removeProjectFromTeam: removeProjectFromTeamLocal,
				removeUserFromTeam: removeUserFromTeamLocal,
				removingOrganization,
				removingProjectFromTeam,
				removingUserFromTeam,
				selectedOrganizationId,
				setOrganization,
				setSelectedOrganizationId,
				setOrganizationsQuery,
				setTeam,
				setTeams,
				team,
				teams,
				updateLogoUrl,
				updateOrganization: updateOrganizationLocal,
				updateTeam: updateTeamLocal,
				updatingOrganization,
				updatingTeam,
			}}>
			{children}
		</OrganizationsContext.Provider>
	);
};
