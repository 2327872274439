import { useContext } from 'react';
import styled from 'styled-components';
import ShareProjectButton from './components/ShareProjectButton';
import { AuthContext } from '../../../../../context/AuthProvider';

export default function RightNavButtons() {
	const { user } = useContext(AuthContext);

	const isContractor = !!user?.isContractor;

	return !isContractor ? (
		<Container>
			<ShareProjectButton />
		</Container>
	) : null;
}

const Container = styled.div`
	display: flex;
	gap: inherit;
`;
