import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
} from '@mui/material/';
import Drawer from '@mui/material/Drawer';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DomainIcon from '@mui/icons-material/Domain';
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FeedIcon from '@mui/icons-material/Feed';

import { YellowIconButton } from '../../../styled-components/buttons';
import { AuthContext } from '../../../../context/AuthProvider';
import { UploadContext } from '../../../screen-components/ProjectUtilityForm/UploadContext';
import { useLocation } from 'react-router-dom';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { Link } from 'react-router-dom';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { CalendarMonth } from '@mui/icons-material';
import { db } from '../../../../firebase';
import { doc, getDoc } from 'firebase/firestore';

export default function LayoutDrawer({
	drawerOpen,
	setDrawerOpen,
	width,
	appBarHeight,
}: {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	width: string;
	appBarHeight: string;
}) {
	const location = useLocation();
	const { user, handleOnLogout } = useContext(AuthContext);
	const { uploadQueueState, setUploadQueueState } = useContext(UploadContext);
	const { setProject, setProjectsSelected, setProjectsListQuery } =
		useContext(ProjectContext);
	const { clearOrganization, organizations } = useContext(OrganizationsContext);
	const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
	const organizationId = useRef('');

	const [ongoingUpload, setOngoingUpload] = useState(false);
	useEffect(() => {
		if (Object.keys(uploadQueueState).length) {
			setOngoingUpload(true);
		} else {
			setOngoingUpload(false);
		}
	}, [uploadQueueState]);

	const logout = () => {
		handleOnLogout();
		setProject(null);
		setProjectsSelected([]);
		setProjectsListQuery('');
		clearOrganization();
	};

	const getOrganizationIdByFirstUserTeamId = useCallback(async () => {
		if (user?.teamIds && user?.teamIds.length > 0) {
			const firstUserTeamId = user?.teamIds[0];

			const itemRef = doc(db, `teams/${firstUserTeamId}`);
			const docSnap = await getDoc(itemRef);

			if (docSnap.exists()) {
				const team = docSnap.data();

				if (team?.organizationId) {
					organizationId.current = team.organizationId;
				}
			}
		}
	}, [user]);

	useEffect(() => {
		getOrganizationIdByFirstUserTeamId();
	}, [getOrganizationIdByFirstUserTeamId]);

	return (
		<>
			<YellowIconButton
				onClick={() => setDrawerOpen(true)}
				$hideButton={drawerOpen}
				title="Open menu"
				size="large">
				<MenuIcon />
			</YellowIconButton>

			<Container $width={width} open={drawerOpen} variant="persistent" anchor="right">
				<DrawerHeader $appBarHeight={appBarHeight}>
					<YellowIconButton onClick={() => setDrawerOpen(false)} size="large">
						<ChevronRightIcon />
					</YellowIconButton>
				</DrawerHeader>

				<List>
					<StyledLink to="/projects">
						<ListItemButton selected={location.pathname === '/projects'}>
							<ListItemIcon>{user?.isAdmin ? <DomainIcon /> : <HomeIcon />}</ListItemIcon>
							<ListItemText primary={user?.isAdmin ? 'Projects' : 'Home'} />
						</ListItemButton>
					</StyledLink>

					{user?.isAdmin && user?.userPerms?.organizations ? (
						<StyledLink to="/organizations">
							<ListItemButton selected={location.pathname === '/organizations'}>
								<ListItemIcon>
									<PeopleOutlineIcon />
								</ListItemIcon>
								<ListItemText primary={'Organizations'} />
							</ListItemButton>
						</StyledLink>
					) : (
						<StyledLink
							to={
								organizationId.current ? `/organizations/${organizationId.current}` : '#'
							}>
							<ListItemButton
								disabled={organizations.length === 0}
								selected={location.pathname === '/organizations'}>
								<ListItemIcon>
									<PeopleOutlineIcon />
								</ListItemIcon>
								<ListItemText primary={'Organization'} />
							</ListItemButton>
						</StyledLink>
					)}

					{user?.isAdmin && user?.userPerms?.leads ? (
						<StyledLink to="/sales">
							<ListItemButton selected={location.pathname === '/sales'}>
								<ListItemIcon>
									<PermPhoneMsgIcon />
								</ListItemIcon>
								<ListItemText primary={'Sales'} />
							</ListItemButton>
						</StyledLink>
					) : null}

					<StyledLink to="/templates">
						<ListItemButton selected={location.pathname === '/templates'}>
							<ListItemIcon>
								<FeedIcon />
							</ListItemIcon>
							<ListItemText primary={'Templates'} />
						</ListItemButton>
					</StyledLink>

					{user?.userPerms?.userManagement ? (
						<StyledLink to="/neural-net">
							<ListItemButton selected={location.pathname === '/neural-net'}>
								<ListItemIcon>
									<ManageAccountsIcon />
								</ListItemIcon>
								<ListItemText primary={'Neural Net'} />
							</ListItemButton>
						</StyledLink>
					) : null}

					<StyledLink to="/schedule">
						<ListItemButton selected={location.pathname === '/schedule'}>
							<ListItemIcon>
								<CalendarMonth />
							</ListItemIcon>
							<ListItemText primary={'Schedule'} />
						</ListItemButton>
					</StyledLink>

					<ListItemButton
						onClick={() => {
							if (ongoingUpload) {
								setLogoutDialogOpen(true);
							} else {
								logout();
							}
						}}>
						<ListItemIcon>
							<MeetingRoomIcon />
						</ListItemIcon>
						<ListItemText primary={'Log Out'} />
					</ListItemButton>
				</List>

				<LastItem>
					<Typography variant="h6">{user?.fullName}</Typography>
					<Typography variant="body1">{user?.email}</Typography>
				</LastItem>
			</Container>

			<Dialog open={logoutDialogOpen} onClose={() => setLogoutDialogOpen(false)}>
				<DialogTitle>
					{'Upload in progress! Are you sure you want to log out?'}
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => setLogoutDialogOpen(false)}>Stay on the page</Button>
					<Button
						onClick={() => {
							setUploadQueueState({});
							setOngoingUpload(false);
							setLogoutDialogOpen(false);
							logout();
						}}>
						Log out
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const Container = styled(Drawer)<{ $width: string }>`
	.MuiDrawer-paper {
		width: ${({ $width }) => $width};
		color: #ffb310;
		background-color: black;
		position: fixed;
		-webkit-overflow-scrolling: touch;
		z-index: 500;
	}
`;

const DrawerHeader = styled.div<{ $appBarHeight: string }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: solid 2px #ffb310;
	height: ${({ $appBarHeight }) => $appBarHeight};
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: inherit;
`;

const LastItem = styled(ListItem)`
	flex-direction: column;
	align-items: baseline;
	margin-top: auto;
`;
