import { Add, Cancel, CheckBoxOutlined, Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';

const style: SxProps<Theme> = { fontSize: 30 };

type ToolbarProps = {
	isSelecting: boolean;
	selectedIds?: string[];
	setIsSelecting: React.Dispatch<React.SetStateAction<boolean>>;
	onDelete?: () => void;
	onAdd?: () => void;
};

const Toolbar = ({
	isSelecting,
	onAdd,
	onDelete,
	selectedIds,
	setIsSelecting,
}: ToolbarProps) => {
	return onAdd || onDelete ? (
		<Row style={{ marginLeft: 12 }}>
			{onAdd ? (
				<IconButton onClick={onAdd}>
					<Add color="primary" sx={style} />
				</IconButton>
			) : null}

			{!isSelecting && onDelete ? (
				<IconButton onClick={() => setIsSelecting(true)}>
					<CheckBoxOutlined color="primary" sx={style} />
				</IconButton>
			) : null}
			{isSelecting && onDelete ? (
				<>
					<IconButton disabled={selectedIds?.length === 0} onClick={onDelete}>
						<Delete
							color={selectedIds?.length === 0 ? 'secondary' : 'primary'}
							sx={style}
						/>
					</IconButton>
					<IconButton onClick={() => setIsSelecting(false)}>
						<Cancel color="primary" sx={style} />
					</IconButton>
				</>
			) : null}
		</Row>
	) : null;
};
export default Toolbar;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
