import { styled } from '@mui/material';

export const ContentList = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const Center = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const Scroller = styled('div')`
	overflow-y: auto;
	width: 100%;
	min-height: calc(100% - 51px);
	max-height: 100px;
`;
