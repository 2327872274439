import { OAuthProvider } from 'firebase/auth';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';

const provider = new OAuthProvider('apple.com');
provider.setCustomParameters({});
provider.addScope('email');
provider.addScope('name');

const useApple = () => {
	const { handleSocialLogin, handleSocialRegister } = useContext(AuthContext);

	const login = async () => {
		handleSocialLogin(provider);
	};

	const register = async () => {
		handleSocialRegister(provider);
	};

	return { login, register };
};

export default useApple;
