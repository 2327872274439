import { ContentContainer, RightColumn, LayoutContainer } from './styles';
import { AuthContext } from '../../../../context/AuthProvider';
import { UserHomeContext } from '../../../../context/UserHomeProvider';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { useContext, useEffect } from 'react';
import UserProjects from '../../../../components/reusable-components/UserProjects';
import UserReports from '../../../../components/reusable-components/UserReports';
import Layout from '../../../../components/reusable-components/Layout';
import UserMap from '../../../../components/reusable-components/UserMap';

export default function ClientProjects() {
	const { loadingOrganizations, setSelectedOrganizationId } =
		useContext(OrganizationsContext);
	const { loadData, templates, reports } = useContext(UserHomeContext);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user?.id) {
			if (!templates && !reports) loadData(user.id);
		}
	}, [loadData, reports, templates, user?.id]);

	useEffect(() => {
		setSelectedOrganizationId(null);
	}, [setSelectedOrganizationId]);

	const layoutTitle = loadingOrganizations ? 'Loading organizations...' : 'Home';

	const userHasTeams =
		(user?.teamIds && user.teamIds?.length > 0) ||
		(user?.orgOwnerIds && user.orgOwnerIds.length);

	return (
		<Layout title={layoutTitle}>
			<LayoutContainer style={{ padding: '20px' }}>
				<ContentContainer>
					<UserProjects />

					{userHasTeams && (
						<RightColumn>
							<UserMap />
							<UserReports />
						</RightColumn>
					)}
				</ContentContainer>
			</LayoutContainer>
		</Layout>
	);
}
