import styled from 'styled-components';
import theme from '../../styles/theme';

export const AuthContainerContentGrid = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	gap: 40px;
	background-color: ${theme.palette.secondary.main};
	padding: 26px;
	text-align: center;
	border-radius: 10px;
	max-width: 685px;
	width: 100%;
`;

export const AuthContainerContentGridContent = styled.div`
	display: flex;
	gap: 40px;
	justify-content: center;
	width: 100%;

	@media (max-width: 720px) {
		flex-direction: column;
	}
`;

export const AuthContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 20px;

	width: 100vw;
	height: 100vh;
`;

export const AuthContainerContent = styled.div<{ $isRegister: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;
	max-width: 685px;
	width: 100%;
	padding: 72px 0 0 0;
	margin: 0 auto;

	@media (max-width: 720px) {
		margin: 0 24px;
		width: calc(100% - 48px);
	}
	@media (min-height: ${({ $isRegister }) =>
			$isRegister ? '1000' : '950'}px) and (min-width: 720px) {
		position: absolute;
		left: calc(50% - 342.5px);
		top: calc(50% - 440px);
	}
`;

export const RealityBackground = styled.div`
	background-color: ${theme.palette.secondary.main};
	padding: 15px;
	border-radius: 10px;
	text-align: center;
`;

export const Nav = styled.nav`
	background: transparent;
	display: flex;
	height: 50px;
	align-items: center;
	padding-left: 20px;
	margin-top: 15px;
	position: fixed;
`;

export const AppButton = styled.img`
	width: 120px;
`;

export const AppButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 50px;
	margin: 0 auto;
`;

export const DownloadToday = styled.div`
	font-size: 40px;
	font-weight: 700;
	color: ${theme.palette.newText.primary};
	text-align: center;
`;

export const DesktopFooter = styled.div<{ $isRegister: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	padding: 15px;
	gap: 5px;
	width: 100%;
	letter-spacing: 1.2px;
	font-size: 1rem;
	font-weight: 300;

	@media (min-height: ${({ $isRegister }) =>
			$isRegister ? '980' : '930'}px) and (min-width: 720px) {
		position: fixed;
		bottom: 0;
		left: 0;
	}
`;

export const FooterRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px 20px;
	align-items: center;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
`;

export const FooterYellowLink = styled.a`
	color: ${theme.palette.primary.main};
	text-transform: none;
	font-family: Helvetica;
	white-space: nowrap;
`;

export const WhiteFooterLink = styled.a`
	color: white;
	text-transform: none;
	font-family: Helvetica;
	white-space: nowrap;

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
		color: ${theme.palette.primary.main};
	}
`;

export const FooterGreyText = styled.span`
	margin: 0;
	align-items: center;
	color: grey;
	text-transform: none;
	font-family: Helvetica;
	white-space: nowrap;
	@media (max-width: 550px) {
		width: 100%;
		text-align: center;
		margin-top: 16px;
	}
`;

export const FooterGreyLink = styled.a`
	color: grey;
	text-transform: none;
	font-family: Helvetica;
	white-space: nowrap;
`;

export const AppDownloads = styled.div`
	margin-bottom: 20px;
`;
