import {
	fetchUserDocument,
	queryProjectsByTeams,
	queryProjectsSharedWithUser,
} from '../components/screen-components/UserStats/utils/utils';
import ProjectStatusSidebar, {
	SelectedStatus,
} from '../components/reusable-components/ProjectStatusSidebar';
import {
	ButtonWrapper,
	MainWrapper,
	OverviewButton,
	PageContent,
	SearchbarWrapper,
	Sidebar,
} from '../components/screen-components/UserStats/UserStats.styles';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { projectStatuses } from '../utils';
import { ProjectData, ProjectStatus, UserWithData } from '../types';
import Layout from '../components/reusable-components/Layout';
import LoadingScreen from '../components/reusable-components/LoadingScreen';
import StatsPage from '../components/screen-components/UserStats/StatsPage/StatsPage';
import TableSearchbar from '../components/reusable-components/DataTable/TableSearchbar';
import UserTable from '../components/screen-components/UserStats/UserTable';

export default function UserStats() {
	const { userId } = useParams();
	const [searchQuery, updateSearchQuery] = useState('');
	const [loadingUser, setLoadingUser] = useState(false);
	const [userProjects, setUserProjects] = useState<ProjectData[] | null>(null);
	const [filteredProjects, setFilteredProjects] = useState<ProjectData[] | null>(null);
	const [selectedStatus, setSelectedStatus] = useState<SelectedStatus>('Overview');
	const [userDetails, setUserDetails] = useState<UserWithData | null>(null);

	const projectStatusCount: Partial<Record<ProjectStatus, number>> =
		filteredProjects?.reduce((obj, proj) => {
			const status = proj.projectStatus;
			return {
				...obj,
				[status]: (obj?.[status] || 0) + 1,
			};
		}, {} as Partial<Record<ProjectStatus, number>>) || {};

	useEffect(() => {
		const fetchUserProjects = async () => {
			if (!userId) return;

			setLoadingUser(true);
			try {
				const [sharedProjects, teamProjects] = await Promise.all([
					queryProjectsSharedWithUser(userId),
					queryProjectsByTeams(userId),
				]);

				const projectIds = new Set();
				const combinedProjects = [...sharedProjects, ...teamProjects].filter(project => {
					if (projectIds.has(project.id)) {
						return false;
					} else {
						projectIds.add(project.id);
						return true;
					}
				});
				setUserProjects(combinedProjects);
			} catch (error) {
				console.error('Error fetching user projects:', error);
				setUserProjects([]);
			} finally {
				setLoadingUser(false);
			}
		};
		fetchUserProjects();
	}, [userId]);

	const filterUserProjects = useCallback(
		(searchQuery: string) => {
			if (Array.isArray(userProjects)) {
				if (searchQuery === undefined || searchQuery === null || searchQuery === '') {
					setFilteredProjects(userProjects);
				} else {
					const filtered: SetStateAction<ProjectData[] | null> = [];
					userProjects.forEach(item => {
						if (
							item.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
							item.clients?.some(client =>
								client.toLowerCase().includes(searchQuery.toLowerCase())
							)
						)
							filtered.push(item);
					});
					setFilteredProjects(filtered);
				}
			}
		},
		[userProjects]
	);

	useEffect(() => {
		if (userId) {
			setLoadingUser(true);
			fetchUserDocument(userId)
				.then(userData => {
					setUserDetails(userData);
					setLoadingUser(false);
				})
				.catch(error => {
					console.error('Failed to fetch user details:', error);
					setLoadingUser(false);
				});
		}
	}, [userId]);

	useEffect(() => {
		if (userDetails) {
			document.title = `${userDetails.fullName} | Robotic Imaging`;
		}
	}, [userDetails]);

	// Handle input chance in search bar
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		updateSearchQuery(value);
	};

	// clear search bar query
	const clearQuery = () => {
		updateSearchQuery('');
		setFilteredProjects(userProjects);
	};

	useEffect(() => {
		if (userProjects?.length) {
			filterUserProjects(searchQuery);
		} else {
			setFilteredProjects([]);
		}
	}, [filterUserProjects, searchQuery, userProjects]);

	const handleOverviewClick = () => {
		setSelectedStatus('Overview');
	};

	return (
		<Layout title={userDetails?.fullName || 'User Stats'}>
			{!loadingUser ? (
				<PageContent>
					<Sidebar>
						<ButtonWrapper>
							<OverviewButton onClick={handleOverviewClick}>
								<span>Overview</span>
							</OverviewButton>
						</ButtonWrapper>
						<ProjectStatusSidebar
							selectedStatus={selectedStatus}
							setSelectedStatus={setSelectedStatus}
							statuses={projectStatuses}
							projectStatusCount={projectStatusCount}
						/>
					</Sidebar>

					<MainWrapper>
						<SearchbarWrapper>
							<TableSearchbar
								query={searchQuery}
								handleInputChange={handleInputChange}
								clearQuery={clearQuery}
								name={'Projects'}
								style={{ width: '100%' }}
							/>
						</SearchbarWrapper>

						{selectedStatus === 'Overview' ? (
							<StatsPage user={userDetails} filteredProjects={filteredProjects} />
						) : (
							<UserTable
								status={selectedStatus as ProjectStatus}
								filteredProjects={
									filteredProjects?.filter(
										proj => proj.projectStatus === selectedStatus
									) || []
								}
								setUserProjects={setUserProjects}
							/>
						)}
					</MainWrapper>
				</PageContent>
			) : (
				<LoadingScreen message="Loading User Stats..." />
			)}
		</Layout>
	);
}
