import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, styled, Tabs } from '@mui/material';
import { OrganizationsContext } from '../context/OrganizationsProvider';
import { checkPermissions } from '../utils';
import { AuthContext } from '../context/AuthProvider';
import { useTeamPageColumns } from '../components/screen-components/TeamPage/TeamPageColumns';
import Layout from '../components/reusable-components/Layout';
import DataTable from '../components/reusable-components/DataTable';
import AddUserDialog from '../components/screen-components/TeamPage/AddUserDialog';
import RemoveUserDialog from '../components/screen-components/TeamPage/RemoveUserDialog';
import AddProjectDialog from '../components/screen-components/TeamPage/AddProjectDialog';
import RemoveProjectDialog from '../components/screen-components/TeamPage/RemoveProjectDialog';
import SkeletonTable from '../components/reusable-components/SkeletonTable';
import Reports from '../components/screen-components/TeamPage/Reports';
import {
	MainContainer,
	TeamPageContainer,
	TeamTab,
} from '../components/screen-components/TeamPage/StyledComponents';
import useTemplates from '../components/screen-components/TeamPage/useTemplates';
import AddTemplateDialog from '../components/screen-components/TeamPage/AddTemplateDialog';
import RemoveTemplateDialog from '../components/screen-components/TeamPage/RemoveTemplateDialog';
import useTeamUsers from '../components/screen-components/TeamPage/useTeamUsers';
import { getStatusGroup } from '../components/reusable-components/GroupedStatusTable/components/columns';
import { BackButton } from '../components/reusable-components/BackButton';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export type PendingUserObj = {
	id?: string;
	email: string;
	lastShared: Date;
	organizationIds?: string[];
	teamIds?: string[];
	orgOwnerIds?: string[];
};

export interface ItemsToBeRemovedInterface {
	id: string;
	name: string;
}

export default function TeamPage() {
	const { organizationId, teamId, reportId }: any = useParams();
	const { loadTeam, organizations, team } = useContext(OrganizationsContext);
	const [openUserDialog, setOpenUserDialog] = useState(false);
	const [openProjectDialog, setOpenProjectDialog] = useState(false);
	const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
	const [projectsToBeRemoved, setProjectsToBeRemoved] = useState<
		ItemsToBeRemovedInterface[] | undefined
	>([]);
	const [templatesToBeRemoved, setTemplatesToBeRemoved] = useState<
		ItemsToBeRemovedInterface[] | undefined
	>();
	const [usersToBeRemoved, setUsersToBeRemoved] = useState<
		ItemsToBeRemovedInterface[] | undefined
	>();
	const projects = team?.projects?.sort((a, b) => {
		if (getStatusGroup(a.projectStatus) > getStatusGroup(b.projectStatus)) return 1;
		if (getStatusGroup(a.projectStatus) < getStatusGroup(b.projectStatus)) return -1;

		if (a.address > b.address) return 1;
		if (a.address < b.address) return -1;

		return 0;
	});
	const users = team?.users;
	const defaultTitle = 'Loading Team...';
	const { user } = useContext(AuthContext);
	const isAdmin = checkPermissions(user, 'admin');
	const [isOrgOwner, setIsOrgOwner] = useState(false);
	const showButton = isAdmin || isOrgOwner;
	const [loadingProjects, setLoadingProjects] = useState(true);
	const [loadingUsers, setLoadingUsers] = useState(true);
	const [currentTab, setCurrentTab] = useState(reportId ? 3 : 0);
	const { loadTeamUsers, pendingUsers } = useTeamUsers(teamId, organizationId);
	const { loading: loadingTemplates, templates } = useTemplates({
		organizationId,
		teamId,
	});

	useEffect(() => {
		if (user?.orgOwnerIds && Array.isArray(user.orgOwnerIds)) {
			setIsOrgOwner(user.orgOwnerIds.includes(organizationId));
		}
	}, [organizationId, user?.orgOwnerIds]);

	const { projectColumns, usersColumns, templateColumns } = useTeamPageColumns();

	useEffect(() => {
		if (teamId) {
			loadTeam(teamId).then(() => {
				setLoadingProjects(false);
				setLoadingUsers(false);
			});
		}
	}, [loadTeam, teamId]);

	useEffect(() => {
		if (team && team.name) {
			document.title = `${team.name} | Robotic Imaging`;
		} else {
			document.title = defaultTitle;
		}
	}, [team]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const handleOnDeleteProjects = (ids: string[]) => {
		const items = ids.map(id => {
			const address = projects?.find(project => project.id === id)?.address;
			return {
				id,
				name: address || '',
			};
		});
		setProjectsToBeRemoved(items);
	};

	const handleOnDeleteUsers = (ids: string[]) => {
		const items = ids.map(id => {
			const email = [...pendingUsers, ...(users || [])]?.find(
				user => user.id === id
			)?.email;
			return {
				id,
				name: email || '',
			};
		});
		setUsersToBeRemoved(items);
	};

	const handleOnDeleteTemplates = (ids: string[]) => {
		const items = ids.map(id => {
			const name = templates?.find(template => template.id === id)?.name;
			return {
				id,
				name: name || '',
			};
		});
		setTemplatesToBeRemoved(items);
	};

	return (
		<Layout
			title={
				team?.name
					? `${team.name} - ${
							organizations.find(org => org.id === team.organizationId)?.name
					  }`
					: defaultTitle
			}
			orgLogo={
				!!team
					? organizations.find(org => org.id === team.organizationId)?.logo?.[0]
							?.downloadUrl
					: ''
			}
			isTeamPage>
			<TeamPageContainer>
				<MainContainer>
					<TabsWrapper>
						<BackButtonWrapper>
							<BackButton
								url={user?.isAdmin ? `/organizations/${organizationId}` : undefined}
								buttonText={user?.isAdmin ? 'Return to Organization' : undefined}
							/>
						</BackButtonWrapper>
						<Tabs value={currentTab} onChange={handleTabChange}>
							<TeamTab label="Projects" />
							<TeamTab label="Members" />
							<TeamTab label="Templates" />
							<TeamTab label="Analytics" />
						</Tabs>
					</TabsWrapper>

					<CustomTabPanel index={0} value={currentTab}>
						{loadingProjects ? (
							<Box sx={{ p: '0 24px' }}>
								<SkeletonTable rows={5} />
							</Box>
						) : (
							<DataTable
								data={projects || []}
								columns={projectColumns}
								name="project(s)"
								onAdd={() => setOpenProjectDialog(true)}
								textEmptyResult="Add a project to this team to get started!"
								textNoSearchQueryResult="No Displayable Projects"
								children={
									<RemoveProjectDialog
										setProjectsToBeRemoved={setProjectsToBeRemoved}
										projectsToBeRemoved={projectsToBeRemoved}
										teamId={teamId}
									/>
								}
								onDelete={handleOnDeleteProjects}
								showButton={showButton}
								queryFields={['address']}
							/>
						)}
						<AddProjectDialog
							open={openProjectDialog}
							setOpen={setOpenProjectDialog}
							teamId={teamId}
						/>
					</CustomTabPanel>

					<CustomTabPanel index={1} value={currentTab}>
						{loadingUsers ? (
							<Box sx={{ p: '0 24px' }}>
								<SkeletonTable rows={5} />
							</Box>
						) : (
							<DataTable
								data={[...pendingUsers, ...(users || [])]}
								columns={usersColumns}
								name="user"
								onAdd={() => setOpenUserDialog(true)}
								textEmptyResult="Add a member to this team to get started!"
								textNoSearchQueryResult="No Displayable Members"
								children={
									<RemoveUserDialog
										setUsersToBeRemoved={setUsersToBeRemoved}
										usersToBeRemoved={usersToBeRemoved}
										teamId={teamId}
									/>
								}
								onDelete={handleOnDeleteUsers}
								showButton={showButton}
								queryFields={['fullName', 'email']}
							/>
						)}
						<AddUserDialog
							open={openUserDialog}
							setOpen={setOpenUserDialog}
							teamId={teamId}
							organizationId={organizationId}
							onSave={loadTeamUsers}
						/>
					</CustomTabPanel>

					<CustomTabPanel index={2} value={currentTab}>
						{loadingTemplates ? (
							<Box sx={{ p: '0 24px' }}>
								<SkeletonTable rows={5} />
							</Box>
						) : (
							<DataTable
								data={templates || []}
								columns={templateColumns}
								name="templates(s)"
								onAdd={() => setOpenTemplateDialog(true)}
								showButton={showButton}
								queryFields={['name']}
								onDelete={handleOnDeleteTemplates}
								textEmptyResult="Add a template to this team to get started!"
								textNoSearchQueryResult="No Displayable Templates"
								children={
									<RemoveTemplateDialog
										setTemplatesToBeRemoved={setTemplatesToBeRemoved}
										templatesToBeRemoved={templatesToBeRemoved}
										teamId={teamId}
										organizationId={organizationId}
									/>
								}
							/>
						)}
					</CustomTabPanel>

					<CustomTabPanel index={3} value={currentTab}>
						<Box sx={{ padding: '0 24px' }}>
							<Reports teamId={teamId} organizationId={organizationId} />
						</Box>
					</CustomTabPanel>
					<RemoveTemplateDialog
						setTemplatesToBeRemoved={setTemplatesToBeRemoved}
						templatesToBeRemoved={templatesToBeRemoved}
						teamId={teamId}
						organizationId={organizationId}
					/>
					<AddTemplateDialog
						open={openTemplateDialog}
						setOpen={setOpenTemplateDialog}
						teamId={teamId}
						organizationId={organizationId}
					/>
				</MainContainer>
			</TeamPageContainer>
		</Layout>
	);
}

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			width="100%">
			{value === index && <Box sx={{ p: '24px 0' }}>{children}</Box>}
		</Box>
	);
};

const TabsWrapper = styled(Box)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	position: relative;

	${({ theme }) => theme.breakpoints.down('lg')} {
		flex-direction: column;
	}
`;

const BackButtonWrapper = styled(Box)`
	position: absolute;
	left: 0;

	${({ theme }) => theme.breakpoints.down('lg')} {
		position: relative;
		align-self: flex-start;
	}
`;
