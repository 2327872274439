import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Team } from '../../../../types';
import GroupIcon from '@mui/icons-material/Group';
import DomainIcon from '@mui/icons-material/Domain';
import {
	Column,
	CondensedInfo,
	CondensedOrgName,
	CondensedTeamCardContainer,
	CondensedTeamName,
	Group,
	StatsRow,
	StyledCheck,
	TeamCardContainer,
	TeamInfo,
	TeamName,
} from './TeamCard.styles';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';

export interface TeamCardProps {
	team: Team;
	userIsAdmin?: boolean;
	userIsOrgOwner?: boolean;
	inSelectionMode: boolean;
	isSelected: boolean;
	onSelect: () => void;
	userIsTeamMember: boolean;
	onUnauthorizedAccess: () => void;
	isCondensed?: boolean;
}

const TeamCard: React.FC<React.PropsWithChildren<TeamCardProps>> = ({
	team,
	userIsAdmin,
	userIsOrgOwner,
	inSelectionMode,
	isSelected,
	onSelect,
	userIsTeamMember,
	onUnauthorizedAccess,
	isCondensed,
}) => {
	const navigate = useNavigate();
	const { organizations } = useContext(OrganizationsContext);

	const handleClick = () => {
		if (inSelectionMode) {
			onSelect();
		} else if (userIsTeamMember || userIsAdmin || userIsOrgOwner) {
			navigate(`/teams/${team.organizationId}/${team.id}`);
		} else {
			onUnauthorizedAccess();
		}
	};

	return isCondensed ? (
		<CondensedTeamCardContainer onClick={handleClick}>
			<Column>
				<CondensedTeamName>{team.name}</CondensedTeamName>
				<CondensedOrgName>
					{
						organizations.find(organization => organization.id === team.organizationId)
							?.name
					}
				</CondensedOrgName>
			</Column>
			<Column>
				<StatsRow>
					<Group>
						<GroupIcon style={{ color: 'white', fontSize: '15px' }} />
						<CondensedInfo>{team.userIds?.length || 0}</CondensedInfo>
					</Group>
					<Group>
						<DomainIcon style={{ color: 'white', fontSize: '15px' }} />
						<CondensedInfo>{team.projectIds?.length || 0}</CondensedInfo>
					</Group>
				</StatsRow>
			</Column>
		</CondensedTeamCardContainer>
	) : (
		<TeamCardContainer
			onClick={handleClick}
			isSelected={isSelected}
			inSelectionMode={inSelectionMode}
			isCondensed={isCondensed}>
			{isSelected && inSelectionMode && <StyledCheck />}
			<TeamName>{team.name}</TeamName>
			<TeamName>
				<CondensedOrgName>
					{
						organizations.find(organization => organization.id === team.organizationId)
							?.name
					}
				</CondensedOrgName>
			</TeamName>
			<TeamInfo>
				<GroupIcon style={{ fontSize: '20px' }} />
				<span>{team.userIds?.length || 0}</span>
			</TeamInfo>
			<TeamInfo>
				<DomainIcon style={{ fontSize: '20px' }} />
				<span>{team.projectIds?.length || 0}</span>
			</TeamInfo>
		</TeamCardContainer>
	);
};

export default TeamCard;
