import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { ProjectData, UserWithData } from '../../../../types';
import { projectStatuses } from '../../../../utils';
import DoughnutGraph from '../DoughnutGraph';
import { exportUserDataToXLSX } from '../utils/exportUtils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
	Container,
	Email,
	ExportButton,
	GraphsContainer,
	Name,
	Person,
	StyledAvatar,
	UserContainer,
	UserDetails,
} from './StatsPage.styles';

export default function StatsPage({
	user,
	filteredProjects,
}: {
	user: UserWithData | null;
	filteredProjects: ProjectData[] | null;
}) {
	Chart.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend,
		ArcElement
	);

	const totalProjectStatusCounts = projectStatuses.map(
		status =>
			filteredProjects?.filter(project => project.projectStatus === status).length || 0
	);

	const handleExport = () => {
		if (user && filteredProjects) {
			exportUserDataToXLSX(filteredProjects, user.fullName);
		}
	};

	return (
		<Container>
			<UserContainer>
				<StyledAvatar>
					<Person />
				</StyledAvatar>
				<UserDetails>
					<Name>{user?.fullName}</Name>
					<Email>{user?.email}</Email>
					<ExportButton
						variant="contained"
						startIcon={<FileDownloadIcon />}
						onClick={handleExport}>
						Export User Data
					</ExportButton>
				</UserDetails>
			</UserContainer>
			<GraphsContainer>
				<DoughnutGraph
					projectStatuses={projectStatuses}
					totalProjectStatusCounts={totalProjectStatusCounts}
				/>
			</GraphsContainer>
		</Container>
	);
}
