import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { ProjectProvider } from './context/ProjectProvider';
import { LoadingProvider } from './context/LoadingProvider';
import { SnackProvider } from './context/SnackProvider';
import { V2FormsProvider } from './context/V2FormsProvider';

import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './styles/theme';
import { UploadProvider } from './components/screen-components/ProjectUtilityForm/UploadContext';
import AppSnackbar from './components/reusable-components/AppSnackbar';
import { OrganizationsProvider } from './context/OrganizationsProvider';
import { SettingsProvider } from './context/SettingsProvider';
import { UserHomeProvider } from './context/UserHomeProvider';
import { QuickbooksProvider } from './context/QuickbooksProvider';
import { TemplateProvider } from './context/TemplateProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<Router>
			<LoadingProvider>
				<SnackProvider>
					<AuthProvider>
						<QuickbooksProvider>
							<SettingsProvider>
								<UploadProvider>
									<V2FormsProvider>
										<ProjectProvider>
											<OrganizationsProvider>
												<UserHomeProvider>
													<TemplateProvider>
														<StyledEngineProvider injectFirst>
															<ThemeProvider theme={theme}>
																<CssBaseline />
																<App />
																<AppSnackbar />
															</ThemeProvider>
														</StyledEngineProvider>
													</TemplateProvider>
												</UserHomeProvider>
											</OrganizationsProvider>
										</ProjectProvider>
									</V2FormsProvider>
								</UploadProvider>
							</SettingsProvider>
						</QuickbooksProvider>
					</AuthProvider>
				</SnackProvider>
			</LoadingProvider>
		</Router>
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
