import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectData, Team, UserObj } from '../../../types';
import { CustomColumn } from '../../reusable-components/DataTable/CustomColumn';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PendingUserObj } from '../../../screens/TeamPage';
import { CellProps, Column } from 'react-table';
import {
	getStatusBackgroundColor,
	getStatusColor,
	getStatusGroup,
	StatusChip,
} from '../../reusable-components/GroupedStatusTable/components/columns';
import { AuthContext } from '../../../context/AuthProvider';
import { Box, Checkbox, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import { ContentCopy } from '@mui/icons-material';
import { SnackContext } from '../../../context/SnackProvider';
import { copyToClipboard, getAnonDownloadLink } from '../../../utils';

export const useTeamPageColumns = () => {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const usersColumns = useMemo(() => {
		const columns: Array<Column<UserObj | PendingUserObj>> = [
			rowCheckbox,
			{
				Header: 'Status',
				id: 'status',
				Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => {
					return (
						<StatusChip
							label={'name' in row.original ? 'Active' : 'Pending'}
							$bgColor={getStatusBackgroundColor(
								'name' in row.original ? 'Delivered' : 'To Be Scheduled'
							)}
							$borderColor={getStatusColor(
								'name' in row.original ? 'Delivered' : 'To Be Scheduled'
							)}
						/>
					);
				},
				accessor: (user: UserObj | PendingUserObj) => {
					return 'name' in user ? 'Active' : 'Pending';
				},
			},
			{
				Header: 'Name',
				id: 'name',
				Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => {
					const user = row.original;
					const name = 'name' in user ? user.name : '-';
					return <span>{name}</span>;
				},
				accessor: (user: UserObj | PendingUserObj) => ('name' in user ? user.name : '-'),
				width: 300,
			},
			{
				Header: 'Email',
				id: 'email',
				Cell: ({ row }: CellProps<UserObj | PendingUserObj>) => {
					const user = row.original;
					const email = 'email' in user ? user.email : '-';
					return <span>{email}</span>;
				},
				accessor: (user: UserObj | PendingUserObj) =>
					'email' in user ? user.email : '-',
				width: 300,
			},
		];

		return columns;
	}, []);

	const projectColumns = useMemo(() => {
		const columns: Array<CustomColumn<Team>> = [
			rowCheckbox,
			{
				Header: 'Status',
				id: 'status',
				Cell: ({ row: { original: project } }: any) => {
					return (
						<StatusChip
							label={
								user?.isAdmin
									? project.projectStatus
									: getStatusGroup(project.projectStatus)
							}
							$bgColor={getStatusBackgroundColor(project.projectStatus)}
							$borderColor={getStatusColor(project.projectStatus)}
						/>
					);
				},
				accessor: (row: any) => row.projects,
				width: 200,
			},
			{
				Header: 'Site Number',
				id: 'store',
				Cell: ({ row: { original: project } }: any) => <span>{project.storeNumber}</span>,
				accessor: (row: any) => row.projects,
				width: 200,
			},
			{
				Header: <p style={{ marginLeft: '34px' }}>Address</p>,
				id: 'address',
				Cell: ({ row: { original: project } }: any) => (
					<Box sx={{ display: 'flex', alignItems: 'center', ml: '-10px' }}>
						<ContentCopy
							onClick={e => {
								e.stopPropagation();
								copyToClipboard(getAnonDownloadLink(project));
								setSnackbarProps({
									open: true,
									message: `${project.name} public shareable link copied!`,
									severity: 'success',
								});
							}}
							className="CopyLinkButton CopyLinkButton-black"
							titleAccess="Copy public shareable link"
							tabIndex={0}
						/>
						<Typography variant="body2" sx={{ mt: '4px' }}>
							{project.address}
						</Typography>
					</Box>
				),
				accessor: (row: any) => row.projects,
				width: 350,
			},
		];
		return columns;
	}, [setSnackbarProps, user?.isAdmin]);

	const templateColumns = useMemo(() => {
		const columns: Array<CustomColumn<Team>> = [
			rowCheckbox,
			{
				Header: 'Template Names',
				id: 'templates',
				Cell: ({ row: { original: templateData } }: any) => {
					const template = templateData as FormTemplate;
					return (
						<ProjectLink to={`/templates/${template.id}`}>{template.name}</ProjectLink>
					);
				},
				accessor: (row: any) => row.templates,
				width: 350,
			},
			{
				Header: 'Created By',
				id: 'createdBy',
				Cell: ({ row: { original: templateData } }: any) => {
					const template = templateData as FormTemplate;
					return <span>{template.updatedBy.name}</span>;
				},
				accessor: (row: any) => row.projects,
				width: 250,
			},
		];
		return columns;
	}, []);

	return {
		usersColumns,
		projectColumns,
		templateColumns,
	};
};

const ProjectLink = styled(Link)`
	text-decoration: none;
	color: white;
	&:hover {
		color: #ffb310;
		text-decoration: underline;
	}
`;

export const ColumnCheckbox = styled(Checkbox)`
	color: ${theme.palette.newText.primary};
	&.Mui-checked {
		color: ${theme.palette.newText.primary};
	}
`;

const rowCheckbox = {
	id: 'checkbox',
	Cell: ({
		row: { original: project },
		addSelectedId,
		isSelecting,
		selectedIds,
		removeSelectedId,
	}: any) => {
		const [selected, setSelected] = useState(false);
		const projectData = project as ProjectData;

		useEffect(() => {
			if (selectedIds.includes(project.id)) setSelected(true);
			else setSelected(false);
		}, [project.id, selectedIds]);

		useEffect(() => {
			if (!isSelecting) setSelected(false);
		}, [isSelecting]);

		return (
			<span>
				<ColumnCheckbox
					color="primary"
					checked={selected}
					style={{ visibility: isSelecting ? 'visible' : 'hidden' }}
					onClick={e => e.stopPropagation()}
					onChange={e => {
						const checked = e.target.checked;
						setSelected(checked);
						if (checked) addSelectedId(projectData.id);
						else removeSelectedId(projectData.id);
					}}
				/>
			</span>
		);
	},
	accessor: (row: any) => row.projects,
	width: 60,
};
