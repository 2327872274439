import styled from 'styled-components';
import { GroupedStatusTableProvider } from './GroupedStatusTableProvider';
import { GroupedTable } from './components/GroupedTable';

interface GroupedStatusTableProps {
	projectsFoundNumber: (value: number) => void;
}

export default function GroupedStatusTable({
	projectsFoundNumber,
}: GroupedStatusTableProps) {
	return (
		<GroupedStatusTableProvider>
			<Container>
				<GroupedTable projectsFoundNumber={projectsFoundNumber} />
			</Container>
		</GroupedStatusTableProvider>
	);
}
const Container = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
`;
