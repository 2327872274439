import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FormContext } from '../FormContext';

import { UtilityFormLoadingContainer } from '../UtilityFormRenderer';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import Spinner from '../../../reusable-components/Spinner';

/**
 * Component for when the fetched form is processing. On mount, initializes a real-time
 * onSnapshot listener to the form document in Firestore to listen and respond to changes
 * in the form status.
 */
export default function ProcessingForm() {
	const { setFormStatus, setFormUrl } = useContext(FormContext);
	const { formId } = useParams() as { [key: string]: string };

	useEffect(() => {
		let unsub = () => {};
		if (formId) {
			const db = getFirestore();
			const docRef = doc(db, `utility_forms/${formId}`);
			unsub = onSnapshot(docRef, docSnapshot => {
				// const data = docSnapshot.data();
				// setFormStatus(prevFormStatus => {
				// 	const newStatus = data?.status as UtilityFormStatus;
				// 	if (prevFormStatus !== newStatus) {
				// 		switch (newStatus) {
				// 			case 'processing':
				// 				return newStatus;
				// 			case 'completed':
				// 				if (data?.downloadUrl) {
				// 					setFormUrl(data.downloadUrl);
				// 					return newStatus;
				// 				} else {
				// 					console.error(
				// 						`Form status is completed, but no downloadUrl is provided...`
				// 					);
				// 				}
				// 				break;
				// 			case 'processing failed':
				// 				return newStatus;
				// 			default:
				// 				console.error(`Unexpected form status: '${newStatus}'`);
				// 				break;
				// 		}
				// 	}
				// 	return prevFormStatus;
				// });
			});
		}

		// Unsubscribe from Firestore listener after component unmounts
		return () => {
			unsub();
		};
	}, [formId, setFormStatus, setFormUrl]);

	return (
		<UtilityFormLoadingContainer>
			<Spinner size={140} />
			<h3>Generating PDF from report data...</h3>
			<h4>This may take a long time depending on how many photos were uploaded.</h4>
			<h4>Feel free to leave the page and come back to check on this later.</h4>
		</UtilityFormLoadingContainer>
	);
}
